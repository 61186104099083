import RegionType from './regionType';

export interface LngLat {
  lng: number;
  lat: number;
}

export interface Circle {
  center: LngLat;
  radius: number;
}

export interface Rectangle {
  bounds: [LngLat, LngLat];
}

export interface CircleRequest {
  type: 'CIRCLE';
  lng: number;
  lat: number;
  radius: number;
}

export interface RectangleRequest {
  type: 'RECTANGLE';
  lng0: number;
  lat0: number;
  lng1: number;
  lat1: number;
}

export interface PolygonRequest {
  type: 'POLYGON';
  points: [number, number][][];
}

export interface Polygon {
  points: LngLat[][];
}

export interface GridRequest extends Omit<RectangleRequest, 'type'> {
  type: 'GRID';
  rows: number;
  cols: number;
}

export interface Grid {
  cells: {
    rows: number;
    cols: number;
  };
}

export interface MultiPolygonRequest {
  type: 'MULTIPOLYGON';
  points: [number, number][][][];
}

export interface MultiPolygon {
  points: LngLat[][][];
}

export interface SegmentListRequest {
  type: 'SEGMENT_LIST';
  ids: number[];
  points: [number, number][][];
}

export interface SegmentList {
  ids: number[];
  points: LngLat[][];
}

export type ShapeRequest =
  | CircleRequest
  | RectangleRequest
  | PolygonRequest
  | GridRequest
  | MultiPolygonRequest
  | SegmentListRequest;

export type Shape =
  | Circle
  | Polygon
  | Rectangle
  | Grid
  | MultiPolygon
  | SegmentList;

function convertRequestToCircle({ lat, lng, radius }: CircleRequest): Circle {
  return {
    center: { lat, lng },
    radius,
  };
}

function convertRequestToRectangle({
  lng0,
  lat0,
  lng1,
  lat1,
}: RectangleRequest): Rectangle {
  return {
    bounds: [
      { lng: lng0, lat: lat0 },
      { lng: lng1, lat: lat1 },
    ],
  };
}

function convertRequestToPolygon({ points }: PolygonRequest): Polygon {
  return {
    points: points.map((slice) =>
      slice.map((point) => ({
        lng: point[0],
        lat: point[1],
      })),
    ),
  };
}

function convertRequestToGrid({
  rows,
  cols,
  type: _type,
  ...rest
}: GridRequest): Grid {
  return {
    ...convertRequestToRectangle({ type: 'RECTANGLE', ...rest }),
    cells: { rows, cols },
  };
}

function convertRequestToMultiPolygon({ points }: MultiPolygonRequest) {
  return {
    points: points.map((polygon) =>
      polygon.map((slice) =>
        slice.map((point) => ({
          lng: point[0],
          lat: point[1],
        })),
      ),
    ),
  };
}

function convertRequestToSegmentList({
  points,
  ids,
}: SegmentListRequest): SegmentList {
  return {
    ids,
    points: points.map((line) =>
      line.map((point) => ({ lng: point[0], lat: point[1] })),
    ),
  };
}

const REQUEST_TO_REGION_CONVERTERS = Object.freeze({
  [RegionType.CIRCLE]: convertRequestToCircle,
  [RegionType.RECTANGLE]: convertRequestToRectangle,
  [RegionType.POLYGON]: convertRequestToPolygon,
  [RegionType.GRID]: convertRequestToGrid,
  [RegionType.MULTIPOLYGON]: convertRequestToMultiPolygon,
  [RegionType.SEGMENT_LIST]: convertRequestToSegmentList,
});

export interface ConvertFromDtoParams {
  flowMatrixRole?: string;
  name?: string;
  shape?: ShapeRequest;
}

export function convertFromDto({
  flowMatrixRole,
  name,
  shape,
}: ConvertFromDtoParams): {
  name: string;
  flowMatrixRole: string;
  type: keyof typeof RegionType;
  shape: Shape;
} {
  let result: Shape | null = null;

  switch (shape.type) {
    case 'CIRCLE':
      result = REQUEST_TO_REGION_CONVERTERS['CIRCLE'](shape);
      break;
    case 'RECTANGLE':
      result = REQUEST_TO_REGION_CONVERTERS['RECTANGLE'](shape);
      break;
    case 'POLYGON':
      result = REQUEST_TO_REGION_CONVERTERS['POLYGON'](shape);
      break;
    case 'MULTIPOLYGON':
      result = REQUEST_TO_REGION_CONVERTERS['MULTIPOLYGON'](shape);
      break;
    case 'GRID':
      result = REQUEST_TO_REGION_CONVERTERS['GRID'](shape);
      break;
    case 'SEGMENT_LIST':
      result = REQUEST_TO_REGION_CONVERTERS['SEGMENT_LIST'](shape);
      break;
  }

  return {
    name,
    flowMatrixRole,
    type: shape.type,
    shape: {
      ...result,
    },
  };
}
