import { DateRange } from 'components/AnalysisNew/DateRanges/DateRange';
import {
  ExclusionsLabel,
  useExclusionsLabels,
} from 'hooks/useExclusionsLabels';
import { Box, TooltipOnHover, Heading, tombac } from 'tombac';
import { InfoIcon } from 'tombac-icons';
import styled from 'styled-components';
import { DayOfWeek } from 'model/DayOfWeek';

export const Header = styled(Heading)`
  font-family: ${tombac.altFontFamily};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 8px;
`;

export const Chip = styled.div`
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4c4c;
  padding: 4px 6px;
  background-color: #f2f2f2;
`;

const renderTooltipContent = (excludedDays: ExclusionsLabel[]) => (
  <Box>
    <Header level={4}>Excluded days</Header>
    <Box
      $display="flex"
      $flexWrap="wrap"
      $gap="6px"
      $maxWidth="256px"
      $mt="8px"
    >
      {excludedDays.map((it) => (
        <Chip key={it.label}>{it.label}</Chip>
      ))}
    </Box>
  </Box>
);

interface Props {
  dateRange: DateRange;
  activeDays?: DayOfWeek[];
}

export const ExclusionsTooltip = ({ dateRange, activeDays }: Props) => {
  const exclusionsLabels = useExclusionsLabels(dateRange, activeDays);

  return (
    <TooltipOnHover
      content={renderTooltipContent(exclusionsLabels)}
      placement="top"
      usePortal
    >
      <InfoIcon color="#bebebe" />
    </TooltipOnHover>
  );
};
