import { useState } from 'react';

export const useSubmit = (
  submitFn: (a?: any) => Promise<void>,
  errors: string[] = [],
): [() => Promise<void>, boolean, boolean] => {
  const [submitting, setSubmitting] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const submit = async (a?: any) => {
    if (errors.length > 0) {
      setShowErrors(true);
      return;
    }
    setShowErrors(false);
    setSubmitting(true);
    await submitFn(a);
    setSubmitting(false);
  };

  return [submit, submitting, showErrors];
};
