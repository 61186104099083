import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/combinedReducer';
import FullscreenError from '../UI/FullscreenError/FullscreenError';
import { BoundaryErrorMessage } from 'reducers/errorReducer';
import { appInsights } from 'logic/tracking';

export const makeBoundaryError = (info: BoundaryErrorMessage) =>
  new Error(JSON.stringify(info));

interface Props {
  user: any;
  errorFromRedux: BoundaryErrorMessage;
  children: any;
}

interface State {
  errorInfo?: BoundaryErrorMessage;
}

/**
 * Handles errors from two sources:
 * - errors from renders in subtree
 * - errors from redux store
 */
class ErrorBoundary extends React.Component<Props, State> {
  state: State = { errorInfo: undefined };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    let parsed: BoundaryErrorMessage | undefined;
    try {
      parsed = JSON.parse(error.message);
    } catch (_) {
      parsed = {
        title: 'Something went wrong',
        description: error.message,
        stackTrace: error.stack,
        componentStack: info.componentStack,
      };
    }

    const hasMessage = parsed.title && parsed.description;
    if (parsed.componentStack === undefined) {
      parsed.componentStack = info.componentStack;
    }
    if (parsed.stackTrace === undefined) {
      parsed.stackTrace = error.stack;
    }

    this.setState({
      errorInfo: hasMessage
        ? parsed
        : {
            title: 'Something went wrong',
            description: 'Unexpected error occured.',
          },
    });
    this.sendLog(parsed);
  }

  componentDidUpdate(prevProps: Props) {
    // Sync with state if got new error from redux
    if (
      prevProps.errorFromRedux !== this.props.errorFromRedux &&
      this.props.errorFromRedux !== this.state.errorInfo
    ) {
      this.setState({ errorInfo: this.props.errorFromRedux });
    }
  }

  sendLog = (error: BoundaryErrorMessage) => {
    appInsights.trackException({ exception: new Error(JSON.stringify(error)) });
  };

  render() {
    const { errorInfo } = this.state;

    if (errorInfo) {
      return (
        <FullscreenError
          title={errorInfo.title}
          description={errorInfo.description}
        />
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = ({ user, error }: AppState) => ({
  user,
  errorFromRedux: error.errorInfo,
});

export default connect(mapStateToProps)(ErrorBoundary);
