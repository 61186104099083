import { Loading } from 'components/UI/Loading';
import { Chart } from 'react-google-charts';
import './SankeyDiagram.css';

const chartOptions = {
  sankey: {
    iterations: 64,
    node: {
      label: {
        fontSize: 16,
      },
      interactivity: true,
      nodePadding: 20,
      width: 20,
    },
    link: {
      colorMode: 'gradient',
    },
  },
  tooltip: {
    isHtml: true,
  },
};

interface Props {
  chartRows: any;
  sliceIndex: number;
  changeSlice: any;
  getChartLabel: any;
  loading: boolean;
}

function SankeyChart({
  chartRows,
  sliceIndex,
  changeSlice,
  getChartLabel,
  loading,
}: Props) {
  if (chartRows.length === 0 && !loading) {
    return (
      <div className="Sankey-no-data">
        <h2>No data to display</h2>
        <p>
          Try changing visualisation options. <br />
          Here are some things you can try:
        </p>
        <ul>
          <li>Change flow limit</li>
          <li>Add more regions</li>
          <li>Enable internal flows</li>
        </ul>
      </div>
    );
  }

  if (chartRows.length === 0) {
    return <Loading />;
  }

  const via =
    sliceIndex !== null
      ? `via ${getChartLabel(sliceIndex, undefined, true).split('.').pop()}`
      : undefined;

  const data = [['From', 'To', 'Flows'], ...chartRows];

  return (
    <div>
      <div className="sankey-title">
        <div>Origin</div>
        {via && (
          <div>
            {via}
            <span className="sankey-close" onClick={() => changeSlice(null)}>
              x
            </span>
          </div>
        )}
        <div>Destination</div>
      </div>
      <div>
        <Chart
          chartType="Sankey"
          width="100%"
          height={`${Math.max(2 * chartRows.length, 520)}px`}
          // data={chartRows}
          // columns={chartColumns}
          data={data}
          options={chartOptions}
          loader={<Loading size="small" />}
        />
      </div>
    </div>
  );
}

export default SankeyChart;
