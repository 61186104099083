import { useOnClickOutside } from 'hooks/useOnOutsideClick';
import { useRef, useState } from 'react';

export const useIsCalendarOpen = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  useOnClickOutside(containerRef, () => setIsCalendarOpen(false));

  return { state: [isCalendarOpen, setIsCalendarOpen] as const, containerRef };
};
