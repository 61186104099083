import { SVGAttributes } from 'react';

export function ArrowsIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg width={24} height={24} viewBox="2 0 22 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14 6.99L10 3 6 6.99h3V14h2V6.99h3zM16 21l4-3.99h-3V10h-2v7.01h-3L16 21z"
      />
    </svg>
  );
}
