import AnalysisApi from 'api/AnalysisApi';
import { TimesetColors } from 'components/AnalysisNew/TimeRange/TimesetColors';
import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AnalysisSummariesDto, AnalysisSummaryDto } from 'model/AnalysisDto';
import { uniq } from 'ramda';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

export interface ResultData extends AnalysisSummaryDto {
  color: string;
}

const prepareResults = (
  analysisSummaries: AnalysisSummariesDto,
): ResultData[] => {
  if (!analysisSummaries) return [];
  const results = analysisSummaries.summaries;
  const uniqueTimeRangeLength = uniq(
    results.map(
      ({ timeRange }) => `${timeRange.startTime} - ${timeRange.endTime}`,
    ),
  ).length;

  return results?.map((item, idx) => ({
    color: TimesetColors[idx % uniqueTimeRangeLength],
    ...item,
  }));
};

export const useAnalysisSummary = () => {
  const { analysis } = useAnalysisContext();
  const [isSummaryUnavailable, setIsSummaryUnavailable] = useState(false);

  const getSummary = useCallback(async () => {
    try {
      return prepareResults(await AnalysisApi.getSummary(analysis.info.id));
    } catch (error) {
      if (error.message === 'Summaries not available') {
        setIsSummaryUnavailable(true);
      }
    }
  }, [analysis.info.id]);

  const { data, isLoading } = useQuery([analysis.info.id], getSummary, {
    enabled: !isSummaryUnavailable,
  });

  return { summary: data, isSummaryUnavailable, isLoading };
};
