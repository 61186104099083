import * as React from 'react';
import './NavBar.css';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

class NavBarTitle extends React.Component<Props> {
  render() {
    return (
      <div className={'ViewNavBarTitle ' + this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

export default NavBarTitle;
