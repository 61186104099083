import AnalysisApi from 'api/AnalysisApi';
import RoadCoverageInfo from 'components/AnalysisNew/RoadCoverage/RoadCoverageInfo';
import { RegionDto } from 'model/RegionDto';
import {
  Box,
  Button,
  ModalActions,
  ModalContent,
  ModalHeader,
  ModalLayout,
} from 'tombac';
import { useAnalysisContext } from '../../AnalysisViewPage';
import { useToasts } from 'tombac';
import './AnalysisSummary.css';
import { DeleteIcon, SpinnerIcon } from 'tombac-icons';
import {
  formatDate,
  formatTime,
} from 'components/AnalysisNew/DateRanges/DateUtils';
import { useSettings } from 'user';
import { AnalysisTable } from './AnalysisTable';
import {
  CreatedAt,
  InfoCell,
  Label,
  SummaryInfoBox,
} from './AnalysisSummary.style';
import { useAnalysisSummary } from './useAnalysisSummary';
import { numberWithSpaces } from 'components/AnalysisViewPage/numberWithSpaces';
import { AnalysisType } from 'model/AnalysisDto';
import { TickCrossIcon } from 'components/UI/TickCrossIcon';

interface Props {
  regions: RegionDto[];
  onClose: () => void;
  showActions?: boolean;
}

function AnalysisSummary({ onClose, regions, showActions = false }: Props) {
  const { addToast } = useToasts();
  const { analysis, refresh } = useAnalysisContext();
  const settings = useSettings();
  const { summary, isSummaryUnavailable, isLoading } = useAnalysisSummary();

  const accept = async () => {
    try {
      await AnalysisApi.accept(analysis.info.id);
      refresh();
      onClose();
    } catch (e) {
      addToast(e.message, 'alert', {
        autoDismiss: false,
      });
    }
  };

  const reject = async () => {
    await AnalysisApi.reject(analysis.info.id);
    refresh();
    onClose();
  };

  return (
    <ModalLayout $width="550u">
      <ModalHeader>
        Analysis summary
        <CreatedAt>
          Created {formatDate(analysis.info.creationTime, settings.dateFormat)}{' '}
          {formatTime(analysis.info.creationTime, settings.clockFormat)}
        </CreatedAt>
      </ModalHeader>
      <SummaryInfoBox>
        <InfoCell>
          <Label> Pricing coverage: </Label>
          <RoadCoverageInfo
            type={analysis.info.type}
            regions={regions}
            analysisId={analysis.info.id}
          />{' '}
        </InfoCell>
        <InfoCell>
          <Label>Trips analysed:</Label>{' '}
          {numberWithSpaces(analysis.info.tripsCounted)}
        </InfoCell>
        {analysis.info.type === AnalysisType.FlowMatrix && (
          <InfoCell>
            <Box $display="flex" $alignContent="center">
              <Label>Data map matching:</Label>{' '}
              <Box $height="2sp" $alignContent="center">
                <TickCrossIcon value={analysis.info.isMapMatched} size={'sm'} />
              </Box>
            </Box>
          </InfoCell>
        )}
      </SummaryInfoBox>
      <Box $backgroundColor="#fbfbfb">
        {isLoading ? (
          <Box
            $display="flex"
            $justifyContent="center"
            $width="100%"
            $mt="16px"
          >
            <SpinnerIcon spin />
          </Box>
        ) : (
          !isSummaryUnavailable &&
          summary && (
            <ModalContent>
              <AnalysisTable data={summary} />
            </ModalContent>
          )
        )}
      </Box>
      <Box $backgroundColor="#fbfbfb">
        <ModalActions
          secondary={
            showActions ? (
              <Button
                variant="flat"
                onClick={reject}
                prepend={<DeleteIcon />}
                theme={{
                  color: '--primary',
                  hover: {
                    backgroundColor: '--neutral-300',
                  },
                  ripple: { circleFill: '--white' },
                }}
              >
                Reject report
              </Button>
            ) : null
          }
        >
          <Button variant="flat" onClick={onClose} $mr="16px">
            Cancel
          </Button>
          {showActions && (
            <Button
              onClick={accept}
              variant="primary"
              data-test-id="summary-accept"
            >
              Accept
            </Button>
          )}
        </ModalActions>
      </Box>
    </ModalLayout>
  );
}

export default AnalysisSummary;
