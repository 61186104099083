import { bearing, lineString } from '@turf/turf';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Box, HFlex, Text } from 'tombac';
import { LinkNode } from '../LinkNode';
import { SegmentName } from './SegmentName';
import { SelectedLinkUnit, View } from '../SelectedLinkPage';
import {
  NodeFilter,
  SegmentContainer,
  SelectedSegment,
} from '../SelectedLinkTree';
import { makeSegmentStyle } from '../useTreeRenderer';
import { BoldTextOverflow, GreyText } from 'components/UI/Text.style';
import { SelectedLinkInfoHeader } from './SelectedLinkInfoHeader';
import { SegmentInfo } from './SegmentInfo';
import { lineStringToReverseGeocodePoint } from 'components/Map/mapUtils';

const ListContainer = styled.ol`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export interface Path {
  node: LinkNode;
  name: string;
  index: number;
  style: any;
}

const pathsInfoByRegionEntranceNode = (
  paths: Path[],
  tree: LinkNode,
): Map<LinkNode, { paths: Path[]; entranceTotalTrips: number }> => {
  let pathsInfoByRegionEntranceNode = new Map<
    LinkNode,
    { paths: any[]; entranceTotalTrips: number }
  >();

  paths.forEach((path) => {
    tree.children.forEach((child) => {
      if (LinkNode.isSameBranch(child.path, path.node.path)) {
        if (pathsInfoByRegionEntranceNode.has(child)) {
          const pathsInfo = pathsInfoByRegionEntranceNode.get(child);
          pathsInfoByRegionEntranceNode.set(child, {
            paths: [...pathsInfo.paths, path],
            entranceTotalTrips: pathsInfo.entranceTotalTrips + path.node.trips,
          });
        } else {
          pathsInfoByRegionEntranceNode.set(child, {
            paths: [path],
            entranceTotalTrips: path.node.trips,
          });
        }
      }
    });
  });

  pathsInfoByRegionEntranceNode = new Map(
    [...pathsInfoByRegionEntranceNode.entries()].sort(
      (a, b) => b[1].paths.length - a[1].paths.length,
    ),
  );

  return pathsInfoByRegionEntranceNode;
};

export function LinkRegionInfo({
  tree,
  selectedSegments,
  unit,
  nodeFilter,
  setSelectedSegments,
  setNodeFilter,
  getColor,
  shouldShowNode,
  setHover,
  view,
}: {
  tree: LinkNode;
  selectedSegments: SelectedSegment[];
  unit: SelectedLinkUnit;
  nodeFilter: NodeFilter;
  setSelectedSegments: (it: SelectedSegment[]) => void;
  getColor: (v: number) => string;
  shouldShowNode: (n: LinkNode, ignoreHighligted: boolean) => boolean;
  setNodeFilter: (nodeFilter: NodeFilter) => void;
  setHover: (h: any) => void;
  view: View;
}) {
  const stats = useMemo(() => {
    return selectedSegments
      .map((segment, index) => {
        const nodes = tree.getByHash(segment.segmentHash);
        if (nodes.length === 0) return;

        const activeNodes = nodes.filter((it) => shouldShowNode(it, true));
        const frc = activeNodes[0]?.frc;
        const totalTrips = activeNodes?.reduce((acc, it) => acc + it.trips, 0);
        const coords = nodes[0]?.coordinates as [number, number][];

        const paths = activeNodes
          .map((it, i) => ({
            node: it,
            name: 'Path ' + i,
            index: i,
            style: makeSegmentStyle(it.trips, tree.trips, getColor, unit),
          }))
          .sort((a, b) => b.node.trips - a.node.trips);

        return {
          index: index,
          totalTrips,
          frc,
          pathsInfoByRegionEntranceNode: pathsInfoByRegionEntranceNode(
            paths,
            tree,
          ),
          coords,
          bearing: bearing(coords[0], coords[coords.length - 1]),
        };
      })
      .filter(Boolean)
      .sort((a, b) => b.totalTrips - a.totalTrips);
  }, [selectedSegments, shouldShowNode, getColor]);

  return (
    <>
      {stats
        .filter((segment) => segment.totalTrips !== 0)
        .map((segment) => (
          <SegmentContainer key={segment.coords.join('')}>
            <SelectedLinkInfoHeader
              treeType={tree.type}
              segment={segment}
              selectedSegments={selectedSegments}
              setSelectedSegments={setSelectedSegments}
              maxTrips={tree.trips}
              isSelectedLinkRegion={true}
            />
            <Box $p="1sp 2sp">
              <Text fontWeight={500}>Alternative paths:</Text>
              <ListContainer>
                <Box>
                  {Array.from(segment.pathsInfoByRegionEntranceNode.keys()).map(
                    (entranceNode, i) => (
                      <li key={i}>
                        <Box $flexDirection="column" $p="1sp 0">
                          <GreyText>
                            {i + 1}{' '}
                            {view === View.INCOMING ? 'Entrance' : 'Exit'} via:
                          </GreyText>
                          <HFlex $justifyContent="space-between">
                            <BoldTextOverflow $width="215px">
                              <SegmentName
                                coords={lineStringToReverseGeocodePoint(
                                  lineString(entranceNode.coordinates),
                                )}
                              />
                            </BoldTextOverflow>{' '}
                            <GreyText>
                              {
                                segment.pathsInfoByRegionEntranceNode.get(
                                  entranceNode,
                                ).entranceTotalTrips
                              }{' '}
                              trips{' '}
                              {(
                                (segment.pathsInfoByRegionEntranceNode.get(
                                  entranceNode,
                                ).entranceTotalTrips /
                                  tree.trips) *
                                100
                              ).toFixed(2)}
                              %
                            </GreyText>
                          </HFlex>
                        </Box>

                        {segment.pathsInfoByRegionEntranceNode
                          .get(entranceNode)
                          .paths.map((it, index) => (
                            <SegmentInfo
                              key={index}
                              nodeFilter={nodeFilter}
                              setNodeFilter={setNodeFilter}
                              setHover={setHover}
                              path={it}
                              index={index}
                            />
                          ))}
                      </li>
                    ),
                  )}
                </Box>
              </ListContainer>
            </Box>
          </SegmentContainer>
        ))}
    </>
  );
}
