export const ReverseGeocodeApi = {
  getRoadName: async ([lng, lat]: [number, number]) => {
    return fetch(
      `https://api.tomtom.com/search/2/reverseGeocode/${lat},${lng}.json?key=zUN7z9nAsJBo0pAsTEwpom3YUNyZsT9G`,
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return Promise.reject(new Error('Failed to reverse geocode point.'));
        }
      })
      .then((json) => {
        if (json.addresses && json.addresses.length > 0) {
          return (
            json.addresses[0].address?.streetName ??
            json.addresses[0].address.routeNumbers?.[0]
          );
        }
      });
  },
};
