import PropTypes from 'prop-types';
import './HeatMapLine.css';
import { colorScale } from '../../AnalysisViewPage/MapFlows/colorScale';

const generateGradient = () => {
  const colors = [];
  for (let i = 0; i <= 100; i += 20) {
    colors.push(colorScale(i));
  }

  return `linear-gradient(
    to right,
    ${colors.join(',\n')}
  )`;
};

const identity = (a) => a;
const HeatMapLine = ({ block, min, max, format = identity }) => (
  <div className="HeatMapLine__container">
    <div className="HeatMapLine__labels">
      <span>{format(min) === undefined ? 'Less' : format(min)}</span>
      <span>{format(max) === undefined ? 'More' : format(max)}</span>
    </div>
    <div
      className={`HeatMapLine ${block ? 'HeatMapLine__block' : ''}`}
      style={{ background: generateGradient() }}
    />
  </div>
);

HeatMapLine.propTypes = {
  block: PropTypes.bool,
  min: PropTypes.any,
  max: PropTypes.any,
  format: PropTypes.func,
};

export default HeatMapLine;
