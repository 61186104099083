import styled, { keyframes } from 'styled-components';
import { Text } from 'tombac';
import { SpinnerIcon } from 'tombac-icons';

const appear = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  animation: ${appear} 1s linear;
  animation-fill-mode: both;
  animation-delay: 0.6s;
`;

export function Loading({ size = 'medium' }: { size?: 'medium' | 'small' }) {
  return (
    <LoadingContainer
      style={{ height: { medium: '200px', small: '40px' }[size] }}
    >
      <SpinnerIcon size="lg" spin />
      {size === 'medium' && <Text $ml="1sp">Loading...</Text>}
    </LoadingContainer>
  );
}
