import Info from 'react-feather/dist/icons/info';
import './ShareNavBar.css';
import { Navbar, NavbarLogo } from 'tombac';

function ShareNavBar() {
  return (
    <Navbar breakpoint={820} $zIndex="10">
      <NavbarLogo title="O/D Analysis" />
      <div className="ShareNavBar__warning">
        <Info size={18} />
        You are viewing analysis that was shared with you. Anyone with the link
        can view this page, so be careful not to share it with anyone you don't
        intend to!
      </div>
    </Navbar>
  );
}

export default ShareNavBar;
