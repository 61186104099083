import * as React from 'react';
import ChevronUp from 'react-feather/dist/icons/chevron-up';
import cx from 'classnames';
import './MapAside.css';

interface MapAsideProps {
  width?: number | string;
  children?: React.ReactNode;
  gray?: boolean;
  [key: string]: any;
}

const MapAside = ({ width, children, gray, ...other }: MapAsideProps) => {
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen((open) => !open);

  const className = cx('MapAside', {
    MapAside__gray: gray,
    'MapAside--open': open,
  });
  return (
    <>
      <aside className={className} style={{ width }} {...other}>
        {children}
      </aside>
      <div className="MapAside__toggle" onClick={toggleOpen}>
        <div className="MapAside__toggle-icon">
          <ChevronUp size={24} />
        </div>
        Menu
      </div>
    </>
  );
};

export default MapAside;
