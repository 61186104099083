import { useEffect, useState } from 'react';

export function Delayed({
  timeoutMs,
  children,
}: {
  timeoutMs: number;
  children: any;
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let current = true;
    const id = setTimeout(() => {
      if (current) setShow(true);
    }, timeoutMs);
    return () => {
      current = false;
      clearTimeout(id);
    };
  });

  return show ? children : null;
}
