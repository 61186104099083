import { useEffect, useState } from 'react';
import * as React from 'react';
import { useDebouncedEffect } from './useDebouncedEffect';
import useThrottledEffect from './useThrottledEffect';

export function useFrequentValue<T>(
  value: T,
  onChange: (v: T) => void,
  {
    debounce,
    throttle,
    preprocess = (x) => x,
  }: {
    debounce?: number;
    throttle?: number;
    preprocess?: (v: T) => T;
  },
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  const useTimedEffect =
    debounce !== undefined ? useDebouncedEffect : useThrottledEffect;
  const time = debounce !== undefined ? debounce : throttle;

  useTimedEffect(
    () => {
      const preprocessedState = preprocess(state);
      if (JSON.stringify(preprocessedState) !== JSON.stringify(value)) {
        onChange(preprocessedState);
      }
    },
    time,
    [state],
  );

  return [state, setState];
}
