import cx from 'classnames';
import * as React from 'react';
import ChevronDown from 'react-feather/dist/icons/chevron-down';
import ChevronUp from 'react-feather/dist/icons/chevron-up';
import './MapAsideSection.css';

interface MapAsideSectionProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
  variant?: 'info' | 'error' | 'warning';
  flex?: boolean;
  contentClass?: string;
  noShrink?: boolean;
  iconName?: string;
  icon?: React.ReactNode;
  canHide?: boolean;
  defaultHide?: boolean;
}

interface State {
  hide?: boolean;
}

class MapAsideSection extends React.Component<MapAsideSectionProps, State> {
  state: State = {
    hide:
      this.props.defaultHide === undefined && !this.props.canHide
        ? true
        : this.props.defaultHide,
  };

  setHide = (hide: boolean) => this.setState({ hide });

  render() {
    const {
      title,
      children,
      flex,
      contentClass,
      icon,
      noShrink,
      variant,
      canHide = true,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      defaultHide,
      ...other
    } = this.props;
    const { hide } = this.state;

    const classNames = cx(
      'MapAsideSection',
      variant ? 'MapAsideSection--' + variant : undefined,
      {
        'MapAsideSection--flex': flex,
        'MapAsideSection--no-shrink': noShrink,
        'MapAsideSection--hidable': canHide,
      },
    );

    const contentClassNames = cx('MapAsideSection__content', contentClass);

    return (
      <section className={classNames} {...other}>
        {title && (
          <div
            className="MapAsideSection__title"
            onClick={() => {
              if (canHide) {
                this.setHide(!hide);
              }
            }}
          >
            {icon && <div className="MapAsideSection__title-icon">{icon}</div>}
            {title}
            {canHide && (
              <div className="MapAsideSection__toggle">
                {hide ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
              </div>
            )}
          </div>
        )}
        {(canHide ? !hide : true) && (
          <div className={contentClassNames}>{children}</div>
        )}
      </section>
    );
  }
}

export default MapAsideSection;
