import { Button } from 'tombac';
import { SaveIcon } from 'tombac-icons';

interface Props {
  disabled: boolean;
  onClick: VoidFunction;
}

export const TemplateButton = ({ disabled, onClick }: Props) => {
  return (
    <Button
      shape="circle"
      size="s"
      variant="flat"
      disabled={disabled}
      onClick={onClick}
    >
      <SaveIcon />
    </Button>
  );
};
