import { AnalysisType } from '../../model/AnalysisDto';

const analysisTypeName = (type: AnalysisType) =>
  ({
    [AnalysisType.FlowMatrix]: 'Origin/Destination',
    [AnalysisType.SelectedLink]: 'Selected Link',
    [AnalysisType.FlowLinks]: 'Flow Links',
  }[type]);

export default analysisTypeName;
