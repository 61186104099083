import { ReactNode } from 'react';
import cx from 'classnames';
import './ResultSpinner.css';
import { SpinnerIcon } from 'tombac-icons';

interface Props {
  opaque?: boolean;
  subtitle?: ReactNode;
  map?: boolean;
}

function ResultSpinner({ subtitle, map, opaque }: Props) {
  return (
    <div
      className={cx('ResultSpinner__wrapper', {
        'ResultSpinner__wrapper--opaque': opaque,
      })}
    >
      <div className={cx('ResultSpinner', { 'ResultSpinner--map': map })}>
        <SpinnerIcon spin />
        {subtitle && <div className="ResultSpinner__subtitle">{subtitle}</div>}
      </div>
    </div>
  );
}

export default ResultSpinner;
