// @ts-check
import PropTypes from 'prop-types';
import { Component } from 'react';
import Check from 'react-feather/dist/icons/check';
import Search from 'react-feather/dist/icons/search';
import { Button, Heading, Input, Modal } from 'tombac';
import { SearchIcon } from 'tombac-icons';
import './RegionSelector.css';

const itemClassName = (selected) => {
  const classNames = ['RegionSelector__list-item'];

  if (selected) {
    classNames.push('RegionSelector__list-item--selected');
  }

  return classNames.join(' ');
};

const icon = (
  <div className="RegionSelector__icon">
    <Check size={16} />
  </div>
);
const emptyIcon = <div className="RegionSelector__icon" />;

class RegionSelector extends Component {
  state = {
    isModalOpen: false,
    rowFilter: '',
    columnFilter: '',
  };

  static propTypes = {
    names: PropTypes.array,
    selectedCols: PropTypes.array,
    selectedRows: PropTypes.array,
    onChange: PropTypes.func,
  };

  colsItemClassName = (name) =>
    itemClassName(this.props.selectedCols.includes(name));
  rowsItemClassName = (name) =>
    itemClassName(this.props.selectedRows.includes(name));

  toggleItem = (list, name) => {
    const items = list;
    const newItems = items.includes(name)
      ? items.filter((itemName) => itemName !== name)
      : [...items, name];
    return newItems;
  };

  toggleColItem = (name) => {
    const { selectedCols, onChange, selectedRows } = this.props;
    const newCols = this.toggleItem(selectedCols, name);
    onChange({ selectedCols: newCols, selectedRows });
  };

  toggleRowItem = (name) => {
    const { selectedCols, onChange, selectedRows } = this.props;
    const newRows = this.toggleItem(selectedRows, name);
    onChange({ selectedCols, selectedRows: newRows });
  };

  selectAllCols = () => {
    const { names, onChange, selectedRows } = this.props;
    onChange({ selectedCols: names, selectedRows });
  };

  selectAllRows = () => {
    const { names, onChange, selectedCols } = this.props;
    onChange({ selectedCols, selectedRows: names });
  };

  deselectAllCols = () => {
    const { onChange, selectedRows } = this.props;
    onChange({ selectedCols: [], selectedRows });
  };

  deselectAllRows = () => {
    const { onChange, selectedCols } = this.props;
    onChange({ selectedCols, selectedRows: [] });
  };

  renderColIcon = (name) =>
    this.props.selectedCols.includes(name) ? icon : emptyIcon;
  renderRowIcon = (name) =>
    this.props.selectedRows.includes(name) ? icon : emptyIcon;

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    const { names } = this.props;

    const displaySearch = names.length > 10;

    const rowFilter = (name) =>
      this.state.rowFilter === ''
        ? true
        : name.toLowerCase().includes(this.state.rowFilter.toLowerCase());

    const columnFilter = (name) =>
      this.state.columnFilter === ''
        ? true
        : name.toLowerCase().includes(this.state.columnFilter.toLowerCase());

    const rows = names.filter(rowFilter);
    const columns = names.filter(columnFilter);
    return (
      <div>
        <div data-test-id="select-matrix-regions">
          <Button onClick={this.openModal}>Select regions</Button>
        </div>
        {this.state.isModalOpen && (
          <Modal
            isOpen
            onRequestClose={() => this.setState({ isModalOpen: false })}
          >
            <div className="RegionSelector__modal">
              <button
                className="RegionSelector__close"
                onClick={this.closeModal}
                data-test-id="region-selector-close"
              >
                ✕
              </button>
              <Heading level={2}>Select regions</Heading>
              <div className="RegionSelector__container">
                <div className="RegionSelector__column">
                  <div className="RegionSelector__column-header">
                    <h3>Rows</h3>
                    <div data-test-id="select-all-rows">
                      <Button
                        onClick={this.selectAllRows}
                        size="s"
                        variant="flat"
                      >
                        All
                      </Button>
                    </div>
                    <Button
                      onClick={this.deselectAllRows}
                      size="s"
                      variant="flat"
                    >
                      None
                    </Button>
                  </div>
                  {displaySearch && (
                    <Input
                      prepend={<SearchIcon />}
                      placeholder="Search"
                      value={this.state.rowFilter}
                      $width="100%"
                      onChange={(e) =>
                        this.setState({ rowFilter: e.target.value })
                      }
                    />
                  )}
                  <ul className="RegionSelector__list">
                    {rows.length > 0 ? (
                      rows.map((name, i) => (
                        <li
                          key={i}
                          className={this.rowsItemClassName(name)}
                          onClick={() => this.toggleRowItem(name)}
                        >
                          {this.renderRowIcon(name)}
                          {name}
                        </li>
                      ))
                    ) : (
                      <div className="RegionSelector__list-message">
                        No regions found
                      </div>
                    )}
                  </ul>
                </div>
                <div className="RegionSelector__column">
                  <div className="RegionSelector__column-header">
                    <h3>Columns</h3>
                    <div data-test-id="select-all-columns">
                      <Button
                        onClick={this.selectAllCols}
                        size="s"
                        variant="flat"
                      >
                        All
                      </Button>
                    </div>
                    <Button
                      onClick={this.deselectAllCols}
                      size="s"
                      variant="flat"
                    >
                      None
                    </Button>
                  </div>
                  {displaySearch && (
                    <Input
                      prepend={<Search size={15} />}
                      placeholder="Search"
                      $width="100%"
                      value={this.state.columnFilter}
                      onChange={(e) =>
                        this.setState({ columnFilter: e.target.value })
                      }
                    />
                  )}
                  <ul className="RegionSelector__list">
                    {columns.length > 0 ? (
                      columns.map((name, i) => (
                        <li
                          key={i}
                          className={this.colsItemClassName(name)}
                          onClick={() => this.toggleColItem(name)}
                        >
                          {this.renderColIcon(name)}
                          {name}
                        </li>
                      ))
                    ) : (
                      <div className="RegionSelector__list-message">
                        No regions found
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default RegionSelector;
