import { TooltipOnHover, TooltipOnHoverProps } from 'tombac';

export const SimpleTooltip = ({
  children,
  content,
  placement = 'top',
  variant = 'inverted',
  size = 's',
  usePortal = false,
}: { children: any } & TooltipOnHoverProps) => (
  <TooltipOnHover
    content={content}
    placement={placement}
    variant={variant}
    size={size}
    usePortal={usePortal}
  >
    {children}
  </TooltipOnHover>
);
