import { Feature, FeatureCollection } from '@turf/helpers';
import { GeoJSONSource, Layer } from 'mapbox-gl';
import { useEffect } from 'react';
import useMap from './useMap';

const empty: any = {
  type: 'FeatureCollection',
  features: [],
};

const parseData = (data: any): GeoJSON.FeatureCollection => {
  let parsed;
  if (data === undefined) {
    parsed = empty;
  } else if (data instanceof Array) {
    parsed = { type: 'FeatureCollection', features: data };
  } else {
    parsed = data;
  }
  return parsed;
};

const useLayers = (
  id: string,
  layers: Layer[],
  data: FeatureCollection | Feature | Feature[] | undefined,
  beforeLayer?: string,
  generateId = false,
) => {
  const map: mapboxgl.Map = useMap();

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: parseData(data),
      generateId,
    });
    const before =
      beforeLayer && map.getLayer(beforeLayer) ? beforeLayer : undefined;
    layers.forEach((l) => map.addLayer({ ...l, source: id } as any, before));

    return () => {
      layers.forEach((l) => {
        if (map.getLayer(l.id)) {
          map.removeLayer(l.id);
        }
      });
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [id, layers, beforeLayer]);

  useEffect(() => {
    const source = map.getSource(id) as GeoJSONSource;

    source.setData(parseData(data));
  }, [data]);
};

export default useLayers;
