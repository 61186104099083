import ArrowUp from 'react-feather/dist/icons/arrow-up';
import ArrowDown from 'react-feather/dist/icons/arrow-down';
import X from 'react-feather/dist/icons/x';
import { Strand } from './SpatialSankeyGl';
import { ReadableResult } from 'logic/ReadableResult';
import { RegionDto } from 'model/RegionDto';

export function StrandPopup({
  strand,
  result,
  regions,
  onClose,
}: {
  result: ReadableResult;
  strand: Strand;
  regions: RegionDto[];
  onClose: () => void;
}) {
  const o = strand.source;
  const originName = regions[o].properties.name;

  const d = strand.target;
  const destinationName = regions[d].properties.name;

  return (
    <div className="StrandPopup">
      <div className="StrandPopup__close" onClick={onClose}>
        <X size={16} />
      </div>
      <div className="StrandPopup__region-name">{originName}</div>
      <div className="StrandPopup__strand-stats">
        <div className="StrandPopup__stat">
          <ArrowDown size={12} />
          <div className="StrandPopup__stat-value">
            {result.get(o, d).trips}
          </div>
          trips
        </div>
        <div className="StrandPopup__stat">
          <ArrowUp size={12} />
          <div className="StrandPopup__stat-value">
            {result.get(d, o).trips}
          </div>
          trips
        </div>
        <div className="StrandPopup__stat">
          Total
          <div className="StrandPopup__stat-value">
            {result.get(d, o).trips + result.get(o, d).trips}
          </div>
          trips
        </div>
      </div>
      <div className="StrandPopup__region-name">{destinationName}</div>
    </div>
  );
}
