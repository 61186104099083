import {
  along,
  center,
  Feature,
  featureCollection,
  length,
  LineString,
} from '@turf/turf';
import { RegionDto } from 'model/RegionDto';
import { useMemo } from 'react';
import arrowTriangle from './assets/arrow-triangle.svg';
import { IconImage, Lines, Source, Symbols } from './MapboxComponents';

export function SelectedLinkFeature({
  links,
}: {
  links: Feature<LineString>[] | RegionDto[];
}) {
  const outlineWidth = 7;
  const baseWidth = 5;
  const defaultWidth = 15;

  const labelPos = useMemo(() => {
    if (!links || links.length === 0) return;
    const style = links[0].properties as any;
    const properties = { text: style.text, 'text-size': style['text-size'] };

    if (links.length === 1) {
      const link = links[0];
      const linkLength = length(link, { units: 'meters' });
      const middlePoint = along(link.geometry as LineString, linkLength / 2, {
        units: 'meters',
      });
      middlePoint.properties = properties;
      return middlePoint;
    }

    return center(featureCollection(links), {
      properties,
    });
  }, [links]);

  return (
    <>
      <Source data={links}>
        <Lines
          line-cap="round"
          line-color="rgb(192,226,253)"
          line-width={[
            'case',
            ['has', 'line-width'],
            ['+', ['get', 'line-width'], baseWidth + outlineWidth],
            defaultWidth + outlineWidth,
          ]}
        />
        <Lines
          line-cap="round"
          line-color="rgba(42,145,213,1)"
          line-width={[
            'case',
            ['has', 'line-width'],
            ['+', ['get', 'line-width'], baseWidth],
            defaultWidth,
          ]}
        />
        <IconImage id="arrow-triangle" src={arrowTriangle} />
        <Symbols
          icon-image="arrow-triangle"
          symbol-avoid-edges
          symbol-placement="line"
          symbol-spacing={30}
        />
      </Source>
      <Source data={labelPos}>
        <Symbols
          text-field="{text}"
          text-font={['Noto-Bold']}
          text-letter-spacing={0.05}
          text-size={['+', ['get', 'text-size'], 3]}
          text-color={'#FFF'}
          text-halo-color="rgba(42,145,213,1)"
          text-halo-width={1.5}
        />
      </Source>
    </>
  );
}
