import { useContext } from 'react';
import { MapContext } from 'components/Map/GlMap';
import mapboxgl from 'mapbox-gl';

const useMap = (): mapboxgl.Map => {
  const map = useContext(MapContext);

  if (!map) {
    throw new Error('MapContext is null');
  }

  return map as mapboxgl.Map;
};

export default useMap;
