import { Node } from './result';
import { Analysis, AnalysisType } from 'model/AnalysisDto';
import { View } from './SelectedLinkPage';
import { LinkNode } from './LinkNode';
import { MenuState } from 'reducers/menuReducer';

export const countRegionMaxTrips = (nodes: Node[]) => {
  let trips = 0;
  nodes?.forEach((it) => {
    if (it.parentId === undefined) {
      trips += it.trips;
    }
  });
  return trips;
};

export const findScaleMaxPercent = (tree: LinkNode) => {
  return Math.ceil(
    (Math.max(...tree.children.map((it) => it.trips)) / tree.trips) * 100,
  );
};

export const defaultSelectedLinkView = (analysis: Analysis) =>
  isSelectedLinkRegion(analysis) ? View.INCOMING : View.ALL;

export const isSelectedLinkRegion = (analysis: Analysis) =>
  analysis.info.type === AnalysisType.SelectedLink &&
  analysis.regions[0]?.geometry?.type === 'Polygon';

export const isSelectedLinkRegionSelected = (menu: MenuState) =>
  menu.type === AnalysisType.SelectedLink &&
  (!menu.links || menu.links.length === 0);
