import { kmToMeters } from 'logic/unit';
import { fetchApi, retriable } from './api';
import { RegionDto } from 'model/RegionDto';

export interface RoadCoverageLength {
  lengthInKm: number;
}

enum BufferType {
  DEFAULT,
  EXPANDED,
}

interface RoadCoverageSegment {
  points: number[];
  frc: number;
  type: BufferType;
}

const getLength = async (
  regions: RegionDto[],
  abortSignal: AbortSignal,
  mapType?: string,
  mapVersion?: string,
  bufferRadiusInKilometers?: number,
) => {
  const bufferSearchParam = bufferRadiusInKilometers
    ? '?bufferRadiusInMeters=' + kmToMeters(bufferRadiusInKilometers)
    : '';
  const roadCoverageLength = await retriable(20, () =>
    fetchApi<RoadCoverageLength>(
      `/rest/roadcoverage/${
        mapType && mapVersion ? `${mapType}/${mapVersion}/` : ''
      }length${bufferSearchParam}`,
      {
        method: 'POST',
        body: JSON.stringify({ regions }),
        headers: {
          'Content-Type': 'application/json',
        },
        signal: abortSignal,
      },
      'json',
    ),
  );

  return roadCoverageLength;
};

const getSegments = async (
  regions: RegionDto[],
  boundingBox: string,
  zoom: number,
  mapType?: string,
  mapVersion?: string,
  bufferRadiusInKilometers?: number,
) => {
  const bufferSearchParam = bufferRadiusInKilometers
    ? '&bufferRadiusInMeters=' + kmToMeters(bufferRadiusInKilometers)
    : '';
  return await retriable(20, () =>
    fetchApi<RoadCoverageSegment[]>(
      `/rest/roadcoverage/${
        mapType && mapVersion ? `${mapType}/${mapVersion}/` : ''
      }segments?zoomLevel=${zoom}&boundingBox=${boundingBox}${bufferSearchParam}`,
      {
        method: 'POST',
        body: JSON.stringify({ regions }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      'json',
    ),
  );
};

const RoadCoverageApi = { getLength, getSegments };

export default RoadCoverageApi;
