const DELEMONT_BASE_URL = 'https://delemont-app.ts.tomtom.com/nukus-debug';

export function buildFlowMatrixTripsDebugLink(
  id: number,
  origin: number,
  destination: number,
  via: number,
  timeRange: number,
  dateRange: number,
) {
  const tripsDebugParams = {
    origin: origin.toString(),
    destination: destination.toString(),
    via: via.toString(),
    timeRange: timeRange.toString(),
    dateRange: dateRange.toString(),
  };

  const searchParams = new URLSearchParams(tripsDebugParams);

  return `${DELEMONT_BASE_URL}/${id}?${searchParams}`;
}

export function buildSelectedLinkTripsDebugLink(
  id: number,
  timeRange: number,
  dateRange: number,
  apiKey: string,
) {
  const tripsDebugParams = {
    timeRange: timeRange.toString(),
    dateRange: dateRange.toString(),
    key: apiKey,
  };

  const searchParams = new URLSearchParams(tripsDebugParams);

  return `${DELEMONT_BASE_URL}/selected-link/${id}?${searchParams}`;
}
