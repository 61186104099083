import React, { useEffect, useRef, useState } from 'react';
import mapboxgl, { Control } from 'mapbox-gl';
import {
  getMapboxStyle,
  handleJapanTiles,
  updateStyle,
  waitForStyleLoaded,
} from 'legoland-shared';
import { MapTypes } from '../../reducers/menuReducer';
import { useMapStyleSettings } from '../../reducers/mapStyleSettingsReducer';
import empty from './styles/empty.json';
import 'mapbox-gl/dist/mapbox-gl.css';
import './GlMap.css';

const apiKey = '1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv';

// Add support for RTL text
if (mapboxgl.getRTLTextPluginStatus() === 'unavailable') {
  mapboxgl.setRTLTextPlugin(
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js',
    () => {},
  );
}

type ControlLocation =
  | 'top-right'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-left';

interface MapProps {
  independent?: boolean;
  mapboxRef?: (c: mapboxgl.Map) => void;
  mapTypeName?: MapTypes;
  children?: React.ReactNode;
  controlLocation?: ControlLocation;
  showControls?: boolean;
  disableScroll?: boolean;
}

export const MapContext = React.createContext<mapboxgl.Map | null>(null);

const createMapInstance = () =>
  new mapboxgl.Map({
    container: document.createElement('div'),
    style: empty as mapboxgl.Style,
    center: [-9.445531804936309, 35.4606814723237],
    zoom: 1.5,
    minZoom: 1,
    maxZoom: 20,
    preserveDrawingBuffer: true,
  });

interface MapInfo {
  instance: mapboxgl.Map;
  controlLocation?: ControlLocation;
  control?: Control;
}
const globalMap: MapInfo = {
  instance: createMapInstance(),
};

const currentYear = new Date().getFullYear();
const GlMap: React.FC<MapProps> = ({
  children,
  disableScroll = false,
  mapboxRef,
  mapTypeName = 'Genesis',
  independent = false,
  controlLocation = 'top-left',
  showControls = true,
}) => {
  const [
    mapStyleSettings,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setMapStyleSettings,
    { mapStyleSettingsReady },
  ] = useMapStyleSettings();
  const [map] = useState<MapInfo>(() =>
    independent
      ? { instance: createMapInstance(), currentStyle: undefined }
      : globalMap,
  );
  const [loaded, setLoaded] = useState(false);
  const containerRef = useRef<any>();

  if (mapboxRef) {
    mapboxRef(map.instance);
  }

  const updateControls = () => {
    if (map.control && map.instance.hasControl(map.control)) {
      map.instance.removeControl(map.control);
    }
    if (showControls) {
      map.control = new mapboxgl.NavigationControl();
      map.controlLocation = controlLocation;
      map.instance.addControl(map.control, controlLocation);
    }
  };

  useEffect(() => {
    if (!mapStyleSettingsReady) {
      return;
    }

    const create = async () => {
      setLoaded(false);
      // Mount map to the DOM element
      if (containerRef.current !== undefined) {
        containerRef.current.appendChild(map.instance.getContainer());
        window.dispatchEvent(new Event('resize'));
      }

      // Add controls
      if (controlLocation !== map.controlLocation) {
        updateControls();
      }

      // Set initial style
      const style = await getMapboxStyle(
        mapStyleSettings,
        mapTypeName,
        apiKey,
        { genesis: { unshiftOrbisSourcesAndLayersForJapan: true } },
      );
      map.instance.setStyle(style);
      await waitForStyleLoaded(map.instance);
      setLoaded(true);
    };

    create();
  }, [mapStyleSettingsReady]);

  useEffect(() => {
    updateControls();
  }, [showControls, controlLocation]);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    setLoaded(false);
    updateStyle(map.instance, mapStyleSettings, mapTypeName, apiKey, {
      genesis: { unshiftOrbisSourcesAndLayersForJapan: true },
    }).then(() => setLoaded(true));
  }, [
    // Listed separately as mapStyleSettings object reference might change on component (re) render which could trigger an unnecessary blink
    mapStyleSettings.basicPOI,
    mapStyleSettings.buildings3D,
    mapStyleSettings.languageGenesis,
    mapStyleSettings.languageOrbis,
    mapStyleSettings.latin,
    mapStyleSettings.style,
    mapTypeName,
  ]);

  // Init japan filtering after source was created but before map is loaded
  // to prevent blink after load
  // Only required for Genesis map, Orbis is detailed.
  useEffect(() => {
    const eventHandler = (e: mapboxgl.MapDataEvent & mapboxgl.EventData) =>
      handleJapanTiles(e, map.instance);

    if (mapTypeName === 'Genesis') {
      map.instance.on('data', eventHandler);

      return () => {
        map.instance.off('data', eventHandler);
      };
    }
  }, [mapTypeName]);

  useEffect(() => {
    if (disableScroll) {
      map.instance.scrollZoom.disable();
    } else {
      map.instance.scrollZoom.enable();
    }
  }, [disableScroll]);

  return (
    <div ref={containerRef} className="GlMap">
      {loaded ? (
        <MapContext.Provider value={map.instance}>
          {children}
        </MapContext.Provider>
      ) : null}
      <div className="GlMap__attribution">
        <a href="https://www.tomtom.com/en_gb/thirdpartyproductterms/ ">
          © TomTom 1992 – {currentYear}
        </a>
      </div>
    </div>
  );
};

export default GlMap;
