import styled from 'styled-components';
import { Caption, tombac } from 'tombac';

export const DefaultText = styled(Caption)`
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
`;

export const GreyText = styled(DefaultText)`
  font-family: Proxima Nova;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: ${tombac.color('neutral', 700)};
`;

export const BoldText = styled(DefaultText)`
  font-weight: 600;
`;

export const BoldTextOverflow = styled(BoldText)`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
