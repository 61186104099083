import { css } from 'styled-components';
import { Variant } from './AsideSection';

export const getVariantStyle = (variant: Variant) => {
  switch (variant) {
    case 'confirm': {
      return css`
        background-color: #fffdf2;
        border: solid 1px #ffdc7a;
      `;
    }
    case 'waiting': {
      return css`
        border: solid 1px #aad3ee;
        background-color: #f6fafe;
      `;
    }
    case 'alert': {
      return css`
        border: solid 1px #ffa8a6;
        background-color: rgba(223, 27, 18, 0.08);
      `;
    }
    default: {
      return css`
        background-color: white;
      `;
    }
  }
};
