import { CancelIcon, TickIcon } from 'tombac-icons';

interface Props {
  value: boolean;
  size?: 'sm' | 'lg' | 'xs' | 1 | 2 | 3 | 5 | 7 | 10;
}

export const TickCrossIcon = ({ value, size = 'lg' }: Props) => {
  return <>{value ? <TickIcon size={size} /> : <CancelIcon size={size} />}</>;
};
