import { ChangeEventHandler } from 'react';
import { getYearsBetweenDates } from 'tombac';
import { StyledYearSelect } from './DatePicker.style';

interface Props {
  minDate: Date;
  maxDate: Date;
  currentYear: number;
  onYearSelected: (year: number) => void;
}

export const YearSelect = ({
  minDate,
  maxDate,
  currentYear,
  onYearSelected,
}: Props) => {
  const availableYears = getYearsBetweenDates(minDate!, maxDate!);
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    onYearSelected(parseInt(event.target.value, 10));
  };
  return (
    <StyledYearSelect value={currentYear} onChange={handleChange}>
      {availableYears.map((year) => (
        <option key={year}>{year}</option>
      ))}
    </StyledYearSelect>
  );
};
