import { Spacing, spacingToPixels } from './spacing';
import { useStackContext } from './Stack';

export const Spacer = ({ spacing }: { spacing?: Spacing }) => {
  const { direction } = useStackContext();
  const style: any = {
    margin: 0,
  };
  if (spacing) {
    if (direction === 'column') {
      style.height = spacingToPixels(spacing) + 'px';
    } else {
      style.width = spacingToPixels(spacing) + 'px';
    }
  } else {
    style.flexGrow = 1;
  }
  return <div style={style} />;
};
export default Spacer;
