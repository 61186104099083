import { trackEvent } from 'logic/tracking';
import { Link } from 'react-router-dom';
import { Button } from 'tombac';

export const createVTypeButton = (url: string) => {
  const component = ({ path, name, icon, ...rest }: any) => (
    <Link
      to={`${url}/${path}`.replace(/([^:]\/)\/+/g, '$1') + location.search}
      onClick={() =>
        trackEvent({ action: 'report option', label: name.toLowerCase() })
      }
      {...rest}
    >
      <Button
        prepend={icon}
        $width="100%"
        $mt="1sp"
        $p="0 2sp"
        style={{ justifyContent: 'flex-start' }}
      >
        {name}
      </Button>
    </Link>
  );
  return component;
};
