import { ERROR_MESSAGE, DELETE_MESSAGE } from '../actions/errorActions';

export interface BoundaryErrorMessage {
  title: string;
  description: string;
  stackTrace?: string;
  componentStack?: string;
}

export interface ErrorState {
  errorInfo?: BoundaryErrorMessage;
}

const initialState: ErrorState = {
  errorInfo: undefined,
};

type action =
  | {
      type: 'ERROR_MESSAGE';
      errorInfo: BoundaryErrorMessage;
    }
  | {
      type: 'DELETE_MESSAGE';
    };

const errorReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case ERROR_MESSAGE:
      return { errorInfo: action.errorInfo };
    case DELETE_MESSAGE:
      return initialState;
    default:
      return state;
  }
};

export default errorReducer;
