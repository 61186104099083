import { DateRangeDto } from '../../model/TimeDto';
import { differenceInCalendarDays, endOfDay, startOfDay } from 'date-fns';
import { LimitsWithUsage } from 'api/LimitsApi';
import { DateRange } from 'components/AnalysisNew/DateRanges/DateRange';

export function validateTimeLimit(startTime: string, endTime: string) {
  const startTimeShifted = startTime.split(':').shift();
  const endTimeShifted = endTime.split(':').shift();

  if (!startTimeShifted || !endTimeShifted) {
    return false;
  }

  const startHours = parseInt(startTimeShifted, 10);
  const endHours = parseInt(endTimeShifted, 10);

  let diff = endHours - startHours;

  if (endHours === 0) {
    diff = (diff + 24) % 24;
  }

  if (diff < 0) {
    return false;
  }

  const TIME_MINIMUM = 1;
  return !(diff < TIME_MINIMUM && diff >= 0);
}

export function isDateRangeValid(
  dateRange: DateRangeDto,
  limits: LimitsWithUsage,
) {
  if (limits === undefined) {
    return true;
  }
  const date = {
    startDate: new Date(dateRange.startDate),
    endDate: new Date(dateRange.endDate),
  };

  const minimumDays = limits.limits.minDaysInDateRange - 1;
  const dateRangeLimit = limits.limits.dateRange;
  const fromCheck =
    dateRangeLimit?.from &&
    date.startDate.getTime() >= startOfDay(dateRangeLimit.from).getTime();
  const toCheck =
    dateRangeLimit?.to &&
    date.endDate.getTime() <= endOfDay(dateRangeLimit.to).getTime();

  return (
    differenceInCalendarDays(date.endDate, date.startDate) + 1 >= minimumDays &&
    fromCheck &&
    toCheck
  );
}

export function twoYearsDateRange(isAdmin: boolean): DateRange {
  const twoYearsInDays = 365 * 2 - 7;
  const start = startOfDay(new Date());
  start.setDate(start.getDate() - twoYearsInDays);
  const end = endOfDay(new Date());
  const offset = isAdmin ? 0 : 1;
  end.setDate(end.getDate() - offset);
  return { start, end, exclusions: [] };
}
