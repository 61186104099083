import { Component } from 'react';
import ChevronDown from 'react-feather/dist/icons/chevron-down';
import ChevronUp from 'react-feather/dist/icons/chevron-up';
import { MultiGrid, AutoSizer, GridCellProps } from 'react-virtualized';
import 'react-virtualized/styles.css';
import './Matrix.css';
import { heatMapColor } from '../MapFlows/colors';
import RotateButton from './RotateButton';
import ResultSpinner from 'components/UI/ResultSpinner/ResultSpinner';
import { MatrixValuesType } from './ColoredMatrix.types';

interface Props {
  matrix: any;
  loading?: boolean;
  onRotate: () => void;
  handleColumnClick: (index: number) => void;
  handleCellClick?: (origin: number, destination: number) => void;
  matrixValuesType: MatrixValuesType;
}

const log = (value: number): number => {
  if (value === 100) {
    return 100;
  }
  if (value === 0) {
    return 0;
  }
  const percent = (100 * Math.log(value)) / Math.log(100);

  return isNaN(percent) ? 0 : percent;
};

const calculateSum = (transformedMatrix: any) =>
  transformedMatrix.reduce(
    (acc: number, { array }: any) =>
      acc + array.reduce((acc: number, flows: number) => acc + flows, 0),
    0,
  );

const sortedIcon = (
  index: number,
  sortRegion: number,
  sortDirection: boolean,
) => {
  if (index === sortRegion) {
    return sortDirection ? <ChevronDown size={14} /> : <ChevronUp size={14} />;
  }
  return null;
};

export default class Matrix extends Component<Props> {
  minMax?: { min: number; max: number };

  calculateMinMax = () => {
    const matrix = this.props.matrix.transformedMatrix;
    let min = matrix[0].array[0];
    let max = matrix[0].array[0];
    matrix.forEach((row: any) =>
      row.array.forEach((cell: any) => {
        if (cell < min) {
          min = cell;
        } else if (cell > max) {
          max = cell;
        }
      }),
    );

    return { min, max };
  };

  cellRenderer = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
    const { matrix } = this.props;
    const { transformedMatrix, regionLabels, header } = matrix;

    const nameBackground = '#FFF';

    if (columnIndex === 0 && rowIndex === 0) {
      return (
        <div
          key={key}
          style={{
            ...style,
            background: nameBackground,
          }}
        />
      );
    }

    if (columnIndex === 0 && rowIndex > 0) {
      return (
        <div
          key={key}
          style={style}
          className="Matrix__item Matrix__item-left-header"
          title={regionLabels[transformedMatrix[rowIndex - 1].index]}
        >
          {regionLabels[transformedMatrix[rowIndex - 1].index]}
        </div>
      );
    } else if (rowIndex === 0 && columnIndex > 0) {
      // Header cells
      return (
        <div
          key={key}
          className="Matrix__item Matrix__item-top-header"
          onClick={() => this.props.handleColumnClick(columnIndex - 1)}
          style={style}
          title={header[columnIndex - 1].name}
        >
          <div className="Matrix__item-icon">
            {sortedIcon(
              columnIndex - 1,
              matrix.sortRegion,
              matrix.sortDirection,
            )}
          </div>
          <div className="Matrix__item-title">
            {header[columnIndex - 1].name}
          </div>
        </div>
      );
    } else {
      const flows = transformedMatrix[rowIndex - 1].array[columnIndex - 1];
      const { min, max } = this.minMax;
      const percent = log(((flows - min) / max) * 100);

      const originIndex = matrix.rotate
        ? columnIndex - 1
        : transformedMatrix[rowIndex - 1].index;
      const destinationIndex = matrix.rotate
        ? transformedMatrix[rowIndex - 1].index
        : columnIndex - 1;

      return (
        <div
          key={key}
          onClick={() =>
            this.props.handleCellClick?.(originIndex, destinationIndex)
          }
          className="Matrix__item Matrix__item-number"
          style={{
            ...style,
            color: percent > 45 ? '#fff' : undefined,
            backgroundColor: heatMapColor(percent).alpha(0.7).css(),
          }}
        >
          {this.props.matrixValuesType === 'RELATIVE' ? `${flows}%` : flows}
        </div>
      );
    }
  };

  render() {
    const { matrix } = this.props;

    this.minMax = this.calculateMinMax();

    const sum = calculateSum(matrix.transformedMatrix);

    const topTitle = matrix.rotate ? 'Origins' : 'Destinations';
    const leftTitle = matrix.rotate ? 'Destinations' : 'Origins';
    return (
      <div className="Matrix">
        {this.props.loading && (
          <ResultSpinner subtitle="Loading results..." map opaque />
        )}
        <RotateButton onClick={this.props.onRotate} />
        <div data-test="matrix-sum" style={{ display: 'none' }}>
          {sum}
        </div>
        <div className="Matrix__horizontal-title">{topTitle}</div>
        <div className="Matrix__vertical-title">{leftTitle}</div>
        <div className="Matrix__wrapper">
          <AutoSizer>
            {({ width, height }) => (
              <MultiGrid
                cellRenderer={this.cellRenderer}
                columnWidth={({ index }) => (index === 0 ? 130 : 80)}
                columnCount={matrix.header.length + 1}
                height={height - 30}
                rowHeight={({ index }) => (index === 0 ? 40 : 26)}
                rowCount={matrix.transformedMatrix.length + 1}
                width={width}
                fixedColumnCount={1}
                fixedRowCount={1}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}
