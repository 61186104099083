import { formatDate } from 'components/AnalysisNew/DateRanges/DateUtils';
import { useSettings } from 'user';

interface Props {
  value: Date;
}

export default function FormattedDate({ value }: Props): any {
  const settings = useSettings();
  return formatDate(value, settings?.dateFormat);
}
