import { calculateFlowRanges } from '../../../logic/colored-matrix/flowUtils';
import { ReadableResult } from 'logic/ReadableResult';
import { MatrixValuesType } from './ColoredMatrix.types';

type matrix2D<T> = T[][];
interface RowType {
  array: number[];
  index: number;
}

// Matrix transforming
const transpose = <T>(m: matrix2D<T>) =>
  m[0].map((x, i) => m.map((xn) => xn[i]));
const cutAndRotate = (rotate: boolean, slice: matrix2D<number>): RowType[] => {
  let cutSlice = slice;
  if (rotate) {
    cutSlice = transpose(cutSlice);
  }
  const cutSliceIndexed = cutSlice.map((array, index) => ({ array, index }));
  return cutSliceIndexed;
};

// Filtering
const filterRows = (selected: string[], regionLabels: string[]) => (
  row: RowType,
) => selected.includes(regionLabels[row.index]);
const filterColumns = (selected: string[], regionLabels: string[]) => (
  _: any,
  toIndex: number,
) => selected.includes(regionLabels[toIndex]);

// Sorting
const sortRows = (sortDesc: boolean, selectedRegion: number) => (
  rowA: RowType,
  rowB: RowType,
) => {
  if (sortDesc) {
    return rowB.array[selectedRegion] - rowA.array[selectedRegion];
  } else {
    return rowA.array[selectedRegion] - rowB.array[selectedRegion];
  }
};

interface GetResultParams {
  result: ReadableResult;
  o: number;
  d: number;
  viaRegion?: number;
}

const getAbsoluteValue = ({ result, o, d, viaRegion }: GetResultParams) =>
  result.get(o, d, viaRegion).trips;

const createMakeMatrix = (matrixValuesType: MatrixValuesType) => {
  return (result: ReadableResult, size: number, viaRegion?: number) => {
    let tripsSum = 0;

    const matrix: matrix2D<number> = [];
    for (let o = 0; o < size; o++) {
      matrix[o] = [];
      for (let d = 0; d < size; d++) {
        const value = getAbsoluteValue({ result, o, d, viaRegion });
        matrix[o][d] = value;
        tripsSum += value;
      }
    }
    if (matrixValuesType === 'RELATIVE') {
      return matrix.map((d) =>
        d.map((val) => Number(((val / tripsSum) * 100).toFixed(3))),
      );
    }
    return matrix;
  };
};
function transformMatrix(params: {
  result: ReadableResult;
  regionLabels: string[];
  viaRegion?: number;
  sortDirection?: boolean;
  sortRegion?: number;
  rotate: boolean;
  selectedCols: string[];
  selectedRows: string[];
  matrixValuesType: MatrixValuesType;
}) {
  const {
    result,
    regionLabels,
    viaRegion,
    sortDirection,
    sortRegion,
    rotate,
    selectedCols,
    selectedRows,
    matrixValuesType,
  } = params;

  const makeMatrix = createMakeMatrix(matrixValuesType);
  const slicedMatrix = makeMatrix(result, regionLabels.length, viaRegion);

  const rotatedMatrix = cutAndRotate(rotate, slicedMatrix);
  const sortedMatrix =
    sortRegion !== undefined
      ? rotatedMatrix.sort(sortRows(sortDirection, sortRegion))
      : rotatedMatrix;
  const rowFilteredMatrix = sortedMatrix.filter(
    filterRows(selectedRows, regionLabels),
  );

  const columnFilteredMatrix = rowFilteredMatrix.map(({ array, index }) => ({
    array: array.filter(filterColumns(selectedCols, regionLabels)),
    index,
  }));

  const fromLength = slicedMatrix.length;
  const toLength = slicedMatrix[0].length;
  const ranges = calculateFlowRanges(slicedMatrix, fromLength, toLength, true);
  const namesWithIndex = regionLabels.map((name, index) => ({
    name,
    index,
  }));
  const header = namesWithIndex.filter(({ name }) =>
    selectedCols.includes(name),
  );

  return {
    transformedMatrix: columnFilteredMatrix,
    ranges,
    regionLabels,
    fromLength,
    toLength,
    header,
    sortRegion,
    sortDirection,
    rotate,
    viaRegion,
  };
}

export { transformMatrix };
