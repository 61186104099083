import { apiKey } from 'api/api';
import { Scenario } from 'components/AnalysisViewPage/MapFlowsPro/logic/scenario';
import { buildSelectedLinkTripsDebugLink } from 'logic/debug/tripsDebugLink';
import { Analysis } from 'model/AnalysisDto';
import { Button } from 'tombac';
import { LimitsRouteIcon } from 'tombac-icons';

interface Props {
  analysis: Analysis;
  scenario: Scenario;
}

export const ShowDebugTracesButton = ({ analysis, scenario }: Props) => {
  const handleButtonClick = () => {
    const link = buildSelectedLinkTripsDebugLink(
      analysis.info.id,
      scenario.timeRange,
      scenario.dateRange,
      apiKey(),
    );
    window.open(link, '_blank');
  };

  return (
    <>
      <Button onClick={handleButtonClick} size="xs" variant="primary">
        <LimitsRouteIcon />
        Show debug traces
      </Button>
    </>
  );
};
