import styled from 'styled-components';
import { tombac } from 'tombac';

export const LinkInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tombac.space(1)};
  margin-top: ${tombac.space(2)};
`;

export const LinkAsideTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${tombac.space(1)};
`;
