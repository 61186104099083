/* eslint-disable react/jsx-key */
import { useMemo } from 'react';
import { useTable } from 'react-table';
import { ResultData } from './useAnalysisSummary';
import { createColumns } from './createColumns';
import {
  Table,
  TableHeader,
  HeaderCell,
  HeaderRow,
  Row,
  Cell,
} from './AnalysisSummary.style';
import { Box } from 'tombac';

type Props = { data: ResultData[] };

export const AnalysisTable = ({ data }: Props) => {
  const columns = useMemo(createColumns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    data,
    columns,
  });

  return (
    <Box $maxHeight="356px" $overflow="auto">
      <Table {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <HeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <HeaderCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </HeaderCell>
              ))}
            </HeaderRow>
          ))}
        </TableHeader>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Cell {...cell.getCellProps()}>{cell.render('Cell')}</Cell>
                  );
                })}
              </Row>
            );
          })}
        </tbody>
      </Table>
    </Box>
  );
};
