import { Spacer } from 'components/UI/Stack/Spacer';
import { HStack, VStack } from 'components/UI/Stack/Stack';
import { RegionDto } from 'model/RegionDto';
import { DateRangeDto } from 'model/TimeDto';
import { Button, Modal } from 'tombac';
import { DateRange } from '../AnalysisNew/DateRanges/DateRange';
import FormattedDate from '../UI/FormattedDate/FormattedDate';

interface Properties {
  analysisName: string;
  invalidDateRanges: DateRangeDto[];
  invalidRegions: RegionDto[];
  onClose: () => void;
  onUnchangedClone: () => void;
  onCloneWithoutInvalidData: () => void;
  dateRangeLimit: DateRange;
  minDaysInDateRange: number;
}

function AnalysisCloneModal(props: Properties) {
  return (
    <Modal isOpen onRequestClose={props.onClose}>
      <div className="AnalysisListItem__clone-modal">
        <VStack spacing="xl">
          <h1>Limits exceeded</h1>
          <p>
            Analysis <b> {props.analysisName} </b> contains parameters, which
            now are exceeding your limits.
          </p>
          {props.invalidDateRanges.length > 0 && (
            <>
              <p>
                Date ranges exceeding{' '}
                <b>
                  <FormattedDate value={props.dateRangeLimit.start} />
                  {' - '}
                  <FormattedDate value={props.dateRangeLimit.end} />
                </b>{' '}
                or minimum <b>{props.minDaysInDateRange}</b> days in date range
                limit:
              </p>
              <ul>
                {props.invalidDateRanges.map((dateRange, i) => (
                  <li key={i}>
                    <FormattedDate value={new Date(dateRange.startDate)} /> -{' '}
                    <FormattedDate value={new Date(dateRange.endDate)} />
                  </li>
                ))}
              </ul>
            </>
          )}
          {props.invalidRegions.length > 0 && (
            <>
              <p>Regions exceeding allowed area limit:</p>
              <ul>
                {props.invalidRegions.map((region, i) => (
                  <li key={i}> {region.properties.name} </li>
                ))}
              </ul>
            </>
          )}
          <Spacer spacing="xxl" />
          <HStack>
            <Button
              variant="secondary"
              onClick={props.onCloneWithoutInvalidData}
            >
              Clone only valid parameters
            </Button>
            <Button onClick={props.onUnchangedClone}>Clone unchanged</Button>
            <Spacer />
            <Button onClick={props.onClose}>Cancel</Button>
          </HStack>
        </VStack>
      </div>
    </Modal>
  );
}

export default AnalysisCloneModal;
