export enum DataType {
  All = 'ALL',
  Limited = 'LIMITED',
}

export enum VehicleTypes {
  All = 'ALL',
  Passenger = 'PASSENGER',
  Fleet = 'FLEET',
}
