import ChevronLeft from 'react-feather/dist/icons/chevron-left';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import ReactPaginate from 'react-paginate';
import './Pagination.css';

export function Pagination({
  pageCount,
  page,
  onChange,
  fromCount,
  toCount,
  totalCount,
}: {
  pageCount: number;
  page: number;
  fromCount: number;
  toCount: number;
  totalCount: number;
  onChange: (event: { selected: number }) => any;
}) {
  return (
    <div className="Pagination__container">
      <div className="Pagination__status">
        {fromCount} - {toCount} of <b>{totalCount} items</b>
      </div>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        forcePage={page}
        containerClassName="Pagination"
        pageClassName="Pagination__item"
        // pageLinkClassName="pager-link"
        activeClassName="Pagination__item--active"
        disabledClassName="Pagination__item--disabled"
        previousClassName="Pagination__item"
        nextClassName="Pagination__item"
        // previousLinkClassName="pager-link pager-link--prev"
        // nextLinkClassName="pager-link pager-link--next"
        // breakClassName="pager-break"
        previousLabel={<ChevronLeft size={16} />}
        nextLabel={<ChevronRight size={16} />}
        onPageChange={onChange}
      />
    </div>
  );
}
