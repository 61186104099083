import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { numberWithSpaces } from 'components/AnalysisViewPage/numberWithSpaces';
import { AsideSection } from 'components/UI/UI';
import { useRoadCoverage } from 'hooks/useRoadCoverage';
import { AnalysisStatus, AnalysisType } from 'model/AnalysisDto';
import { Box, Label } from 'tombac';
import { SpinnerIcon } from 'tombac-icons';
import { AnalysisMapModalActions } from '../analysisMap.types';
import { SectionData, Value } from '../AnalysisMapSidebar.style';
import { Kilometers } from 'components/UI/Kilometers/Kilometers';
import { metersToKm } from 'logic/unit';
import { DataSource } from 'model/AnalysisParameters';
import { TickCrossIcon } from 'components/UI/TickCrossIcon';

interface Props {
  modalActions: AnalysisMapModalActions;
}

export const Summary = ({ modalActions }: Props) => {
  const isShared = location.href.includes('/share/');

  const { analysis } = useAnalysisContext();
  const { loading, coverage, error } = useRoadCoverage({
    regions: analysis.regions,
    type: analysis.info.type,
    analysisId: analysis.info.id,
    enabled: !isShared,
    bufferRadiusInKilometers: metersToKm(analysis.info.bufferRadiusInMeters),
  });

  const canShowSummary =
    analysis.info.status === AnalysisStatus.ENDED ||
    analysis.info.status === AnalysisStatus.REJECTED ||
    analysis.info.status === AnalysisStatus.ACCEPTED;

  const selectedVehicleType = (): string => {
    switch (analysis.info.dataSources) {
      case DataSource.ALL_PASSENGER:
      case DataSource.LIMITED_PASSENGER:
        return 'Passenger';
      case DataSource.ALL_FLEET:
      case DataSource.LIMITED_FLEET:
        return 'Fleet';
      default:
        return 'All';
    }
  };

  return (
    <AsideSection title="Summary">
      <Box $mt="1sp" $display="flex" $gap="1sp" $flexDirection="column">
        <SectionData>
          Trips counted:{' '}
          <Value>{numberWithSpaces(analysis.info.tripsCounted)}</Value>
        </SectionData>
        {!isShared && (
          <SectionData>
            {loading ? (
              <SpinnerIcon spin />
            ) : error ? (
              error.message
            ) : (
              <>
                Pricing coverage:{' '}
                <Value>
                  <Kilometers squared={false} value={coverage} />
                </Value>
              </>
            )}
          </SectionData>
        )}
        <SectionData>
          Vehicle type: <Value>{selectedVehicleType()}</Value>
        </SectionData>
        {analysis.info.type === AnalysisType.FlowMatrix && (
          <SectionData $display="flex" $alignItems="center" $height="2sp">
            Data map matching:{' '}
            <Value>
              <TickCrossIcon value={analysis.info.isMapMatched} />
            </Value>
          </SectionData>
        )}
        {canShowSummary && !isShared && (
          <SectionData>
            <Label
              variant="interactive"
              onClick={modalActions.openSummaryModal}
            >
              View details
            </Label>
          </SectionData>
        )}
      </Box>
    </AsideSection>
  );
};
