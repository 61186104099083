import { AsideSection } from 'components/UI/UI';
import { Button } from 'tombac';
import { CancelIcon, MenuMeatballsIcon } from 'tombac-icons';
import { Box } from 'tombac/dist/components/Styling';
import { Circle, Header, Text } from './Statuses.style';

interface Props {
  showCancelModal: VoidFunction;
  isShared: boolean;
}

export const AnalysisWaiting = ({ showCancelModal, isShared }: Props) => {
  return (
    <AsideSection
      canHide={false}
      variant="waiting"
      title={
        <Box $display="flex" $alignItems="center" $gap="12px">
          <Circle color="#195780">
            <MenuMeatballsIcon color="white" width={16} height={16} />
          </Circle>
          <Header level={2}>Analysis is waiting</Header>
        </Box>
      }
    >
      <Box $mt="12px">
        <Text>Analysis is waiting to be processed</Text>
      </Box>
      {!isShared && (
        <Button
          prepend={<CancelIcon />}
          $mt="12px"
          $background="transparent"
          onClick={showCancelModal}
        >
          Cancel
        </Button>
      )}
    </AsideSection>
  );
};
