// @ts-check
import { Component } from 'react';
import PropTypes from 'prop-types';
import './SankeySearch.css';
import { Input } from 'tombac';

class SankeySearch extends Component {
  static propTypes = {
    onSearch: PropTypes.func,
    list: PropTypes.array,
  };

  state = {
    query: '',
    result: undefined,
    focused: false,
    typing: false,
  };

  handleListItemClick = (item) => {
    this.setState({
      query: '',
      focused: false,
    });
    const { onSearch } = this.props;
    if (onSearch) {
      onSearch(item);
    }
  };

  handleSubmit = (item) => {
    this.setState({
      query: '',
    });
    const { onSearch } = this.props;
    if (onSearch) {
      onSearch(item);
    }
  };

  render() {
    const { focused, query } = this.state;
    const result = this.props.list.filter((r) =>
      r.name.toLowerCase().includes(query.toLowerCase()),
    );

    return (
      <div className="SankeySearch">
        <Input
          ref={(input) => (this.input = input)}
          value={query}
          placeholder="Search"
          onFocus={() => this.setState({ focused: true })}
          onBlur={() =>
            setTimeout(() => this.setState({ focused: false }), 150)
          }
          onChange={(e) => this.setState({ query: e.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && result[0]) {
              this.handleSubmit(result[0]);
            }
          }}
          style={{
            position: 'relative',
            zIndex: 2,
          }}
          data-test-id="Search-Input"
          $width="100%"
        />
        {result && focused && (
          <ul className={`SankeySearch__list`}>
            {result.length === 0 && (
              <div className="SankeySearch__empty">Nothing found</div>
            )}
            {result.map((item, i) => (
              <li
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleListItemClick(item);
                }}
                className={`SankeySearch__list-item`}
              >
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default SankeySearch;
