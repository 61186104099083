import { AsideSection } from 'components/UI/UI';
import { Box } from 'tombac';
import { SubtractIcon } from 'tombac-icons';
import { Circle, Header, Text } from './Statuses.style';

export const AnalysisCancelled = () => {
  return (
    <AsideSection
      variant="waiting"
      canHide={false}
      title={
        <Box $display="flex" $alignItems="center" $gap="12px" $mb="12px">
          <Circle color="#7a7e80">
            <SubtractIcon color="white" width={16} height={16} size="lg" />
          </Circle>
          <Header level={2}>Cancelled</Header>
        </Box>
      }
    >
      <Box>
        <Text>Report was cancelled by user</Text>
      </Box>
    </AsideSection>
  );
};
