import { bearing } from '@turf/turf';
import { useMemo } from 'react';
import { Box, Text } from 'tombac';
import { LinkNode } from '../LinkNode';
import { SelectedLinkUnit } from '../SelectedLinkPage';
import {
  NodeFilter,
  SegmentContainer,
  SelectedSegment,
} from '../SelectedLinkTree';
import { makeSegmentStyle } from '../useTreeRenderer';
import { SelectedLinkInfoHeader } from './SelectedLinkInfoHeader';
import { SegmentInfo } from './SegmentInfo';

export function LinkInfo({
  tree,
  selectedSegments,
  unit,
  nodeFilter,
  setSelectedSegments,
  setNodeFilter,
  getColor,
  shouldShowNode,
  setHover,
}: {
  tree: LinkNode;
  selectedSegments: SelectedSegment[];
  unit: SelectedLinkUnit;
  nodeFilter: NodeFilter;
  setSelectedSegments: (it: SelectedSegment[]) => void;
  getColor: (v: number) => string;
  shouldShowNode: (n: LinkNode, ignoreHighligted: boolean) => boolean;
  setNodeFilter: (nodeFilter: NodeFilter) => void;
  setHover: (h: any) => void;
}) {
  const stats = useMemo(() => {
    return selectedSegments
      .map((segment, index) => {
        const nodes = tree.getByHash(segment.segmentHash);
        if (nodes.length === 0) return;

        const activeNodes = nodes.filter((it) => shouldShowNode(it, true));
        const frc = activeNodes[0]?.frc;
        const totalTrips = activeNodes?.reduce((acc, it) => acc + it.trips, 0);
        const coords = nodes[0]?.coordinates as [number, number][];
        const paths = activeNodes
          .map((it, i) => ({
            node: it,
            name: 'Path ' + i,
            index: i,
            style: makeSegmentStyle(it.trips, tree.trips, getColor, unit),
          }))
          .sort((a, b) => b.node.trips - a.node.trips);

        return {
          index: index,
          totalTrips,
          frc,
          paths,
          coords,
          bearing: bearing(coords[0], coords[coords.length - 1]),
        };
      })
      .filter(Boolean);
  }, [selectedSegments, shouldShowNode]);

  return (
    <>
      {stats
        .filter((segment) => segment.totalTrips !== 0)
        .map((segment) => (
          <SegmentContainer key={segment.coords.join('')}>
            <SelectedLinkInfoHeader
              treeType={tree.type}
              segment={segment}
              selectedSegments={selectedSegments}
              setSelectedSegments={setSelectedSegments}
              maxTrips={tree.trips}
              isSelectedLinkRegion={false}
            />
            <Box $p="1sp 2sp">
              <Text fontWeight={500}>Alternative paths:</Text>
              <Box>
                {segment.paths.map((it, index) => (
                  <SegmentInfo
                    key={index}
                    nodeFilter={nodeFilter}
                    setNodeFilter={setNodeFilter}
                    setHover={setHover}
                    path={it}
                    index={index}
                  />
                ))}
              </Box>
            </Box>
          </SegmentContainer>
        ))}
    </>
  );
}
