import classnames from 'classnames';
import * as React from 'react';
import './MenuItem.css';

export interface MenuItemProps {
  danger?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  [prop: string]: any;
}

class MenuItem extends React.Component<any, any> {
  render() {
    const { danger, disabled, ...rest } = this.props;
    const className = classnames('MenuItem', {
      'MenuItem--danger': danger,
      'MenuItem--disabled': !!disabled,
    });
    return (
      <div className={className} {...rest}>
        {this.props.children}
      </div>
    );
  }
}

export default MenuItem;
