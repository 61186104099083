import styled from 'styled-components';
import { Box, Caption, Heading, tombac } from 'tombac';

export const Header = styled(Heading)`
  font-family: ${tombac.altFontFamily};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

export const HeaderLabel = styled.label`
  all: initial;
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7a7e80;
`;

export const SectionData = styled(Box)`
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  color: #4a4c4c;
`;

export const Value = styled(Caption)`
  font-weight: 700;
`;

export const DateTimeContainer = styled.div`
  font-family: ${tombac.altFontFamily};
  font-size: 12px;
  font-weight: bold;
  color: #000;
`;

export const List = styled.ul`
  /* all: initial; */
  margin: 0;
  padding: 0;
  font-family: ${tombac.fontFamily};
  list-style-type: none;
  font-size: 12px;
  color: #4a4c4c;
  margin-top: 12px;
`;

export const PassMatrixLabel = styled.span`
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  font-weight: 500;
  color: #4a4c4c;
`;

export const InfoIconContainer = styled.div`
  display: inline;
  & > div {
    display: inline;
  }
`;
