import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { Input, TooltipOnHover } from 'tombac';
import { DateIcon, ErrorIcon } from 'tombac-icons';
import { Container } from './DatePicker.style';
import { DatePickerCalendar } from './DatePickerCalendar';
import { useIsCalendarOpen } from './useIsCalendarOpen';
import { formatDate } from 'components/AnalysisNew/DateRanges/DateUtils';
import { parse, isWithinInterval } from 'date-fns/esm';

const CalendarIcon = styled(DateIcon)`
  cursor: pointer;
`;

interface Props {
  disabled?: boolean;
  placeholder?: string;
  minDate: Date;
  maxDate: Date;
  value?: Date;
  onChange: (date: Date | undefined) => void;
  dateFormat?: string;
}

const createSupportedFormats = (isDayFirst: boolean) => {
  const separators = ['.', '-', '/'];
  return separators.flatMap((separator) => {
    const dayMonthString = isDayFirst ? `dd${separator}MM` : `MM${separator}dd`;
    return [
      `${dayMonthString}${separator}yyyy`,
      `yyyy${separator}MM${separator}dd`,
    ];
  });
};

export const DatePicker = ({
  disabled = false,
  placeholder,
  minDate,
  maxDate,
  value,
  onChange,
  dateFormat = 'DDMMYYYY',
}: Props) => {
  const {
    state: [isOpen, setIsOpen],
    containerRef,
  } = useIsCalendarOpen();
  const [inputValue, setInputValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  useEffect(() => {
    if (value) setInputValue(formatDate(value, dateFormat));
  }, [value, dateFormat]);

  const handleClick = () => setIsOpen(true);

  const handleDayClick = (date: Date) => {
    setIsOpen(false);

    //fix one day shift in timezones UTC-
    if (date) {
      if (date.getTimezoneOffset() > 0) {
        date.setDate(date.getDate() + 1);
      }
      setIsInvalid(false);
      onChange(date);
    }
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setInputValue(e.target.value);

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key == 'Enter') {
      const supportedFormats = createSupportedFormats(
        dateFormat.startsWith('DD'),
      );

      const date = supportedFormats
        .map((format) => parse(inputValue, format, new Date()))
        .filter((date) => !date.toString().includes('Invalid'))[0];

      const isBetween =
        date && minDate && maxDate
          ? isWithinInterval(date, { start: minDate, end: maxDate })
          : false;

      if (isBetween) {
        setIsInvalid(false);
        onChange(
          new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
          ),
        );
      } else {
        setIsInvalid(true);
        onChange(undefined);
      }
    }
  };

  return (
    <Container ref={containerRef}>
      <Input
        value={inputValue}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleInputChange}
        prepend={<CalendarIcon onClick={handleClick} />}
        onClick={handleClick}
        invalid={isInvalid}
        highlighted={isOpen}
        onKeyPress={handleKeyPress}
        autoComplete="off"
        append={
          isInvalid && (
            <TooltipOnHover
              variant="inverted"
              size="s"
              content="Invalid date"
              usePortal
              placement="top"
            >
              <ErrorIcon />
            </TooltipOnHover>
          )
        }
      />
      <DatePickerCalendar
        isOpen={isOpen}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onDayClick={handleDayClick}
      />
    </Container>
  );
};
