import { tombac } from 'tombac';
import styled from 'styled-components';
import classNames from 'classnames';

const NoWrap = styled.span`
  ${tombac.text()},
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  &.text-ellipsis-bold {
    font-weight: 500;
  }
`;

interface Props {
  text: string;
  bold?: boolean;
}

const TextEllipsis = ({ text, bold }: Props) => {
  return (
    <NoWrap title={text} className={classNames({ "text-ellipsis-bold": !!bold })}>
      {text}
    </NoWrap>
  );
};

export default TextEllipsis;
