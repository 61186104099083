import cx from 'classnames';
import './AnalysisStatusIndicator.css';
import styled from 'styled-components';
import { AnalysisStatus } from 'model/AnalysisDto';

const Circle = styled.div`
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 450;
  margin-top: -2px;
`;

export const AnalysisStatusIndicator = ({
  icon,
  variant,
  rotateIcon,
  children,
}: {
  icon: any;
  variant: AnalysisStatus;
  children: any;
  rotateIcon?: boolean;
}) => {
  const color = {
    [AnalysisStatus.ACCEPTED]: 'rgb(0, 141, 141)',
    [AnalysisStatus.ENDED]: 'rgb(240, 173, 78)',
    [AnalysisStatus.RUNNING]: 'rgb(25, 87, 128)',
    [AnalysisStatus.WAITING]: 'rgb(25, 87, 128)',
    [AnalysisStatus.FAILED]: 'rgb(233,27,18)',
    [AnalysisStatus.REJECTED]: 'rgb(122, 126, 128)',
    [AnalysisStatus.CANCELLED]: 'rgb(122, 126, 128)',
  }[variant];

  const className = cx('AnalysisStatusIndicator', {
    'AnalysisStatusIndicator--rotate': rotateIcon === true,
  });
  return (
    <div className={className}>
      <Circle color={color}>{icon}</Circle>
      {children}
    </div>
  );
};
