import styled from 'styled-components';
import { Caption, TooltipOnHover, tombac } from 'tombac';

const RoadClassContainer = styled.div`
  background-color: rgba(161, 161, 161, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  height: ${tombac.space(2)};
  width: ${tombac.space(2)};
  font-weight: 700;
  position: relative;
  text-align: center;
  font-size: 12px;
  cursor: default;
`;

interface Props {
  frc: number;
}

export const RoadClass = ({ frc }: Props) => {
  return (
    <TooltipOnHover
      placement="top"
      size="s"
      content={<Caption>Road class: {frc}</Caption>}
    >
      <RoadClassContainer>{frc}</RoadClassContainer>
    </TooltipOnHover>
  );
};
