import LimitsApi from 'api/LimitsApi';
import { useMenu } from 'reducers/menuReducer';
import styled from 'styled-components';
import { FormGroup, Text, tombac } from 'tombac';
import { WarningIcon } from 'tombac-icons';
import { DataType } from '../../fontModels/DataSource';
import { Flex } from '../UI/FormUI';
import { DataTooltip } from './DataTooltip';
import { RadioInput } from './RadioInput';

export const Bold = styled.b`
  color: #000;
  display: inline-block;
  font-weight: 500;
`;

const getWaringText = (selectedDataSource: DataType) => {
  if (selectedDataSource === DataType.Limited) {
    return (
      <>
        This option allows you to create a report with a date older than 2
        years. <br />
        Please note: Report will be based on a lower volume of data.
      </>
    );
  }
};

export function DataSourceSelect() {
  const [menu, setMenu] = useMenu();
  const limits = LimitsApi.use();
  const options = [
    {
      label: 'Full data support, within last 2 years',
      value: DataType.All,
    },
    {
      label: 'Limited data support, more than 2 years',
      value: DataType.Limited,
    },
  ];
  if (!limits?.limits?.limitedDataSourcesEnabled) return null;

  const warningText = getWaringText(menu?.dataType);

  return (
    <>
      <FormGroup
        label={
          <Text altFont fontSize={12} fontWeight="bold">
            Historical data <DataTooltip />
          </Text>
        }
        $mb="2sp"
      >
        <RadioInput
          options={options}
          value={menu.dataType}
          onChange={(dataType) => setMenu({ dataType })}
          $mt="1sp"
        />
      </FormGroup>
      {warningText && (
        <WarningTextContainer>
          <Flex direction="row">
            <WarningIcon
              color="#f8b022"
              width="20px"
              size="lg"
              style={{ flexShrink: 0, marginRight: '12px' }}
            />
            <div style={{ lineHeight: 1.5 }}>{warningText}</div>
          </Flex>
        </WarningTextContainer>
      )}
    </>
  );
}

const WarningTextContainer = styled.div`
  background: ${tombac.color('alert', 200)};
  border: ${tombac.parse('1u solid --alert-400')};
  margin-bottom: ${tombac.space(2)};
  padding: ${tombac.space(1.5)};

  ${tombac.text({
    color: tombac.color.black,
    fontSize: 12,
    lineHeight: 1.4,
  })}
`;
