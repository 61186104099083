import {
  AccessType,
  MeDetailsSafe,
  MeDto,
  queryClient,
  useLegoland,
  useMeDetailsQuery,
  UserRole,
  useUserProduct,
} from 'legoland-sdk/dist/experimental';
import {
  MouseEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Button, FormGroup, Heading } from 'tombac';
import { CancelIcon } from 'tombac-icons';
import { SimpleSelect } from 'tombac/dist/components/Form/SimpleSelect';

const DebugWindowContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 99999999999;
  display: flex;
  flex-direction: column;
  opacity: 0.4;

  :hover {
    opacity: 1;
  }
`;

const DebugWindowTitlebar = styled.div`
  margin: -10px;
  margin-bottom: 8px;
  height: 40px;
  background: #f2f2f2;
  display: flex;
  padding-left: 10px;
  align-items: center;
`;

function DebugWindow({ children, onClose }) {
  const ref = useRef<HTMLDivElement>();
  const status = useRef<{ x: number; y: number }>();

  useLayoutEffect(() => {
    // Init position in the middle of the screen
    const x = window.innerWidth / 2 - ref.current.offsetWidth / 2;
    const y = window.innerHeight / 2 - ref.current.offsetHeight / 2;
    ref.current.style.transform = `translate(${x}px, ${y}px)`;

    // Move the window to the mouse position
    const onMove = (e: MouseEvent) => {
      if (!ref.current || !status.current) return;
      e.preventDefault();

      ref.current.style.transform = `translate(${
        e.clientX - status.current.x
      }px, ${e.clientY - status.current.y}px)`;
    };
    const onUp = () => {
      status.current = undefined;
    };

    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onUp);

    return () => {
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onUp);
    };
  }, []);

  const onMouseDown: MouseEventHandler = (e) => {
    const { x, y } = ref.current.getBoundingClientRect();
    status.current = { x: e.clientX - x, y: e.clientY - y };
  };

  return (
    <DebugWindowContainer ref={ref}>
      <DebugWindowTitlebar onMouseDown={onMouseDown}>
        <Heading level={5}>Debug</Heading>
        <Button $ml="auto" onClick={onClose} shape="circle" size="s">
          <CancelIcon />
        </Button>
      </DebugWindowTitlebar>
      {children}
    </DebugWindowContainer>
  );
}

/**
 * Calls callback repeatedly and when dependencies change
 */
const useTimerFunction = (
  callback: any,
  intervalMs: number,
  dependencies: any[],
) => {
  const fnRef = useRef(callback);

  useEffect(() => {
    fnRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setInterval(() => {
      fnRef.current();
    }, intervalMs);
    return () => {
      clearInterval(id);
    };
  }, [intervalMs]);

  useEffect(() => {
    fnRef.current();
  }, dependencies);
};

const useKeyPress = (key: string, callback: () => void) => {
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === key) {
        callback();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [key]);

  return null;
};

export function DebugTools() {
  const isDev = process.env.NODE_ENV === 'development';
  const [show, setShow] = useState(false);
  useKeyPress('`', () => isDev && setShow((it) => !it));

  return show ? <Debug onClose={() => setShow(false)} /> : null;
}

function Debug({ onClose }) {
  const meDetails = useMeDetailsQuery()?.data?.meDetails;
  const settings = meDetails?.settings;
  const product = useUserProduct();
  const [role, setRole] = useState(meDetails?.role);
  const [accessType, setAccessType] = useState(
    (product?.access as any)?.accessType,
  );
  const [dateFormat, setDateFormat] = useState(settings?.dateFormat);
  const [units, setUnits] = useState(settings?.units);
  const { productId } = useLegoland();

  const authQueryId = ['auth', 'me'];
  const legolandQueryId = ['legoland', 'users', 'me', 'details'];
  useTimerFunction(
    () => {
      queryClient.setQueryData(authQueryId, (state: { me: MeDto }) => {
        if (!state?.me) return;

        state.me.role = role;

        return { ...state };
      });
      queryClient.setQueryData(
        legolandQueryId,
        (state: { meDetails: MeDetailsSafe }) => {
          if (!state?.meDetails) return;

          if (role) {
            state.meDetails.role = role;
          }
          if (accessType) {
            const product = state.meDetails.products.find(
              (p) => p.id === productId,
            );
            (product.access as any).accessType = accessType;
          }
          if (dateFormat) {
            state.meDetails.settings.dateFormat = dateFormat;
          }
          if (units) {
            state.meDetails.settings.units = units;
          }
          return { ...state };
        },
      );
    },
    2000,
    [role, accessType, dateFormat, units],
  );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(legolandQueryId);
      queryClient.invalidateQueries(authQueryId);
    };
  }, []);

  return (
    <DebugWindow onClose={onClose}>
      <FormGroup label="Role">
        <SimpleSelect
          options={[
            { label: 'Admin', value: 'ADMIN' },
            { label: 'User', value: 'USER' },
            { label: 'Support', value: 'SUPPORT' },
          ]}
          selectProps={{
            menuPlacement: 'top',
          }}
          value={role}
          onChange={(value: UserRole) => {
            setRole(value);
          }}
        />
      </FormGroup>
      <FormGroup label="Access Type" $mt="1sp">
        <SimpleSelect
          options={Object.entries(AccessType).map(([key, value]) => ({
            label: key,
            value,
          }))}
          value={accessType}
          selectProps={{
            menuPlacement: 'top',
          }}
          onChange={(v) => setAccessType(v)}
        />
      </FormGroup>
      <FormGroup label="Date Format" $mt="1sp">
        <SimpleSelect
          options={[
            { value: 'DDMMYYYY', label: 'DDMMYYYY' },
            { value: 'MMDDYYYY', label: 'MMDDYYYY' },
          ]}
          onChange={setDateFormat}
          value={dateFormat}
          selectProps={{
            menuPlacement: 'top',
          }}
        />
      </FormGroup>
      <FormGroup label="Units" $mt="1sp">
        <SimpleSelect
          options={[
            { value: 'KM', label: 'Km' },
            { value: 'MI', label: 'Miles' },
          ]}
          onChange={(u: 'KM' | 'MI') => setUnits(u)}
          value={units}
          selectProps={{
            menuPlacement: 'top',
          }}
        />
      </FormGroup>
    </DebugWindow>
  );
}
