import { DateRangeDto, TimeRange } from 'model/TimeDto';

const removeSeconds = (time: string) => {
  const parts = time.split(':');
  if (parts.length > 2) {
    return [parts[0], parts[1]].join(':');
  }
  return time;
};

export const formatDateRange = ({ startDate, endDate }: DateRangeDto) =>
  startDate + '-' + endDate;
export const formatTimeRange = ({ startTime, endTime }: TimeRange) =>
  removeSeconds(startTime) + '-' + removeSeconds(endTime);
