import { useMemo } from 'react';
import { PropsWithPropStyling, RadioGroup } from 'tombac';

export function SimpleRadioGroup<T extends string>({
  options,
  value,
  onChange,
  ...rest
}: PropsWithPropStyling<{
  value: T;
  options: T[];
  onChange: (v: T) => any;
}>) {
  const ops = useMemo(() => options.map((it) => ({ label: it, value: it })), [
    options,
  ]);
  const selectedValue = ops.find((it) => it.value === value);

  return (
    <RadioGroup
      value={selectedValue}
      options={ops}
      variant="horizontal"
      onChange={(it) => onChange(it.value)}
      {...rest}
    />
  );
}
