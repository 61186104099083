import { Text, Box, Heading, tombac } from 'tombac';
import addToRegion from 'svg/add-to-external-regions.svg';
import trimToRegions from 'svg/trim-to-defined-regions.svg';
import styled from 'styled-components';

const Header = styled(Heading)`
  font-family: ${tombac.altFontFamily};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 8px;
`;

const StyledText = styled(Text)`
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #7a7e80;
`;

export function PassMatrixTooltip({ passMatrix }: { passMatrix: boolean }) {
  const width = passMatrix ? '418px' : '364px';
  const img = passMatrix ? trimToRegions : addToRegion;
  const label = passMatrix
    ? 'Trim to defined regions'
    : 'Add to external region';
  const description = passMatrix
    ? 'Trips will be trimmed to defined regions. Origin of the trip will be defined as a region where trip was first seen and destination will be defined as a region where trip was last seen.'
    : `Trips that start or end outside of the defined regions are counted to the 'External' region.`;

  return (
    <Box $display="flex" $width={width} $height="100px">
      <img src={img} style={{ width: '115px', marginRight: '26px' }} />
      <div>
        <Header level={3}>{label}</Header>
        <StyledText>{description}</StyledText>
      </div>
    </Box>
  );
}
