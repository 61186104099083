/* eslint-disable react-hooks/rules-of-hooks */
import { useSvgImage } from 'hooks/useImage';
import { range } from 'lodash';

const arrowStraight = (color: string) =>
  `<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="-6 -6 36 36">
    <circle cx="12" cy="12" r="16" fill="${color}" stroke="#FFF"  stroke-width="2px" />
        <g fill="white">
            <path d="M11.881 4L20 12l-8.119 8-2.841-2.8 3.245-3.2H4v-4h8.287L9.04 6.8 11.88 4z" transform="translate(-533 -55) translate(485 26) translate(48 29) rotate(-90 12 12)"/>
        </g>
</svg>
`;

const arrowLeft = (color: string) => `
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="-6 -6 36 36" fill="none" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-up-right">
    <circle cx="12" cy="12" r="16" fill="${color}" stroke="white" stroke-width="2px" />
    <g transform="scale(0.8)" transform-origin="12 12">
    <polyline points="9 14 4 9 9 4"></polyline>
    <path d="M20 20v-7a4 4 0 0 0-4-4H4"></path>
    </g>
</svg>`;

const arrowRight = (color: string) => `
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="-6 -6 36 36" fill="none" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-up-right">
    <circle cx="12" cy="12" r="16" fill="${color}"stroke="white" stroke-width="2px" />
    <polyline points="15 14 20 9 15 4" ></polyline>
    <path d="M4 20v-7a4 4 0 0 1 4-4h12"></path>
</svg>
`;

const uTurn = (color: string) => `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 312 312" width="42" height="42">
<circle cx="156" cy="156" r="146" fill="${color}" stroke="white" stroke-width="20px" />
<g transform="scale(0.5)" transform-origin="156 156">
<path style="fill:#FFF;" d="M309.875,187.709l-14.143-14.143c-2.929-2.929-7.677-2.93-10.607,0l-52.677,52.677V138.16  c0-64.085-52.137-116.223-116.221-116.223C52.14,21.937,0,74.074,0,138.16v131.014c0,4.142,3.357,7.5,7.5,7.5h20  c4.143,0,7.5-3.358,7.5-7.5V138.16c0-44.786,36.438-81.223,81.228-81.223c44.785,0,81.221,36.437,81.221,81.223v88.083  l-52.676-52.676c-1.407-1.407-3.315-2.197-5.304-2.197c-1.989,0-3.896,0.79-5.304,2.197l-14.142,14.143  c-1.406,1.406-2.196,3.314-2.196,5.303c0,1.989,0.79,3.897,2.196,5.303l89.622,89.622c1.465,1.465,3.384,2.197,5.304,2.197  c1.919,0,3.839-0.732,5.304-2.197l89.623-89.622c1.406-1.406,2.196-3.314,2.196-5.303  C312.071,191.023,311.281,189.116,309.875,187.709z"/>
</g>
</svg>
`;

const intersection = (color: string) => `
<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="8" fill="${color}" />
    <circle cx="12" cy="12" r="4" fill="white" />
</svg>
`;

export const useColorfullArrows = (getColor: any) => {
  range(0, 11).forEach((index) => {
    const color = getColor(index * 10);
    useSvgImage(`arrow-icon-${index}`, arrowStraight(color));
    useSvgImage(`arrow-left-${index}`, arrowLeft(color));
    useSvgImage(`arrow-right-${index}`, arrowRight(color));
    useSvgImage(`arrow-u-turn-${index}`, uTurn(color));
    useSvgImage(`arrow-intersection-${index}`, intersection(color));
  });
};
