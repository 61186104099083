import styled from 'styled-components';

export const RegionNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  & > *:last-child {
    > span {
      display: none;
    }
  }

  :hover {
    & > *:last-child {
      > span {
        display: block;
      }
    }
  }
`;
