import { useSpring } from 'react-spring';
import { EmptyBar, Progress } from './ProgressBar.style';

interface Props {
  percentage: number;
  disableAnimation?: boolean;
}

export const ProgressBar = ({
  percentage,
  disableAnimation = false,
}: Props) => {
  const styles = useSpring({
    from: { width: '0%' },
    width: percentage ? `${percentage}%` : '0%',
  });

  return (
    <EmptyBar>
      <Progress
        style={disableAnimation ? { width: `${percentage}%` } : styles}
      />
    </EmptyBar>
  );
};
