import { forwardRef } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Box, tombac, Tooltip } from 'tombac';
import { Palette, PaletteScale } from './ColorPalette';
import { ColorSelector } from './GradientPicker';
import { useFrequentValue } from './useFrequentValue';

const ColorBoxContainer = styled.div`
  min-width: 30px;
  height: 20px;
  flex-grow: 1;
  position: relative;
  margin-right: -1px;
`;

const ColorDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const TransparentBackgroundGrid = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=);
  background-size: 6px;
  background-position: top left;
  border: 1px solid #bebebe;
`;

const ColorBox = forwardRef(
  ({ style: { background, ...styleRest }, ...rest }: any, ref: any) => {
    return (
      <ColorBoxContainer ref={ref} {...rest} style={styleRest}>
        <TransparentBackgroundGrid />
        <ColorDiv style={{ background }} />
      </ColorBoxContainer>
    );
  },
);
ColorBox.displayName = 'ColorBox';

const ColorBoxesContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const DiscretePickerContainer = styled.div`
  overflow-x: auto;
  flex-grow: 1;
`;

export function DiscretePreview({ palette }: { palette: Palette }) {
  const colors = useMemo(() => {
    return Palette.getDiscreteColors(palette, 0, 9, 1);
  }, [palette]);

  return (
    <ColorBoxesContainer>
      {colors.map((it, i) => (
        <ColorBox
          style={{
            background: it,
            height: '100%',
            width: 'auto',
            minWidth: 'unset',
          }}
          key={i}
        />
      ))}
    </ColorBoxesContainer>
  );
}

export function ColorBoxPicker({
  color,
  width,
  readOnly,
  onChange,
}: {
  color: string;
  width?: string;
  readOnly?: boolean;
  onChange: (c: string) => void;
}) {
  return (
    <Tooltip
      placement={'top'}
      content={<ColorSelector value={color} onChange={onChange} />}
    >
      {({ ref, toggle }) => (
        <ColorBox
          style={{
            background: color,
            width,
            minWidth: width !== undefined ? 'unset' : '',
          }}
          onClick={readOnly ? undefined : toggle}
          ref={ref}
        />
      )}
    </Tooltip>
  );
}

const ColorLabels = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-top: 6px;
  height: 20px;
`;

const ColorBoxLabel = styled.div`
  position: absolute;
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  transform: translateX(-50%);
  white-space: nowrap;
`;

export function DiscretePicker({
  scale,
  palette,
  unitLabel,
  onChange,
}: {
  scale: PaletteScale;
  palette: Palette;
  unitLabel?: string;
  onChange?: (p: Palette) => void;
}) {
  const [internalPalette, setInternalPalette] = useFrequentValue(
    palette,
    onChange,
    {
      debounce: 500,
    },
  );

  const colors = Palette.getDiscreteColors(
    internalPalette,
    scale.from,
    scale.to,
    scale.step,
  );

  let labels = useMemo(() => {
    const { from, to, step } = scale;

    let labels = [];
    for (let i = from; i < to + step; i += step) {
      labels.push(String(Math.floor(Math.min(i, to))));
    }
    if (unitLabel) {
      labels[labels.length - 1] = unitLabel;
    }
    return labels;
  }, [scale, unitLabel]);

  const onColorChange = (color: string, index: number) => {
    const exisitingIndex = internalPalette.customDiscreteColors?.findIndex(
      (it) => it.index === index,
    );
    let newCustomDiscreteColors = internalPalette.customDiscreteColors
      ? [...internalPalette.customDiscreteColors]
      : [];

    const customColor = { index, color };

    if (exisitingIndex > -1) {
      newCustomDiscreteColors[exisitingIndex] = customColor;
    } else {
      newCustomDiscreteColors.push(customColor);
    }

    setInternalPalette({
      ...internalPalette,
      customDiscreteColors: newCustomDiscreteColors,
    });
  };

  const isWide = labels.length > 15;

  return (
    <DiscretePickerContainer
      style={{
        overflowX: isWide ? 'scroll' : 'unset',
        paddingLeft: isWide ? '15px' : '',
      }}
    >
      <Box
        style={{
          width: isWide ? `${labels.length * 30}px` : '100%',
        }}
      >
        <ColorBoxesContainer>
          {colors.map((it, i) => (
            <ColorBoxPicker
              color={it}
              key={i}
              onChange={(color) => onColorChange(color, i)}
              readOnly={onChange === undefined}
            />
          ))}
        </ColorBoxesContainer>
        <ColorLabels>
          {labels.map((label, index) => (
            <ColorBoxLabel
              key={label}
              style={{ left: `${(index / (labels.length - 1)) * 100}%` }}
            >
              {label}
            </ColorBoxLabel>
          ))}
        </ColorLabels>
      </Box>
    </DiscretePickerContainer>
  );
}
