import chroma from 'chroma-js';
import { colorScale } from './colorScale';

const colorCache = [];
for (let i = 0; i <= 100; i++) {
  colorCache.push(colorScale(i));
}
/**
 * Accepts value between 0 and 100 and returns heatmap color
 *
 * @param {Number} value - Value in range {0, 100}
 */
export const heatMapColor = (value) =>
  colorCache[Math.max(0, Math.min(100, Math.floor(value)))];

export const contrastTextColor = (color) => {
  if (chroma.contrast('#444', color) >= 4.5) {
    return '#444';
  }
  return '#fff';
};
