import Title from 'components/Layout/Title';
import { analysisUrl } from 'logic/analysis/analysisUtils';
import { useHistory, useLocation } from 'react-router-dom';
import { useAnalysisContext } from '../AnalysisViewPage';
import NavBar from './NavBar';
import NavBarBack from './NavBarBack';
import NavBarItem from './NavBarItem';
import NavBarTitle from './NavBarTitle';

interface Props {
  visualisationName: string;
  pathname: string;
  search: string;
}

const ViewPageNavbar = ({ visualisationName }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { analysis } = useAnalysisContext();

  const backPathname = analysisUrl(
    analysis.info.type,
    analysis.info.id,
    location.pathname.includes('/share/') ? 'share' : 'view',
  );
  return (
    <NavBar>
      <Title>{visualisationName}</Title>
      <NavBarBack
        onClick={() =>
          history.push({ pathname: backPathname, search: location.search })
        }
      >
        <NavBarTitle>{analysis.info.name}</NavBarTitle>
      </NavBarBack>
      {visualisationName && <NavBarItem>{visualisationName}</NavBarItem>}
    </NavBar>
  );
};

export default ViewPageNavbar as any;
