import { AsideSection } from 'components/UI/UI';
import { Box } from 'tombac';
import { ErrorIcon } from 'tombac-icons';
import { Header } from '../AnalysisMapSidebar.style';
import { Text } from './Statuses.style';

export const AnalysisFailed = () => {
  return (
    <AsideSection
      variant="alert"
      canHide={false}
      title={
        <Box
          $display="flex"
          $alignItems="center"
          $gap="6px"
          $mb="6px"
          $position="relative"
          $left="-6px"
        >
          <ErrorIcon color="#df1b12" size="lg" />
          <Header level={2}>Error</Header>
        </Box>
      }
    >
      <Box>
        <Text>Report failed due to error</Text>
      </Box>
    </AsideSection>
  );
};
