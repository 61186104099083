import { AsideSection } from 'components/UI/UI';
import styled from 'styled-components';
import { Box, Button, tombac } from 'tombac';
import { LinkNode } from '../LinkNode';
import { useEffect, useState } from 'react';
import { BoldText, DefaultText, GreyText } from 'components/UI/Text.style';
import { StyledSegmentProperties, makeSegmentStyle } from '../useTreeRenderer';
import { SelectedLinkUnit, View } from '../SelectedLinkPage';
import { ZoomToIcon } from 'tombac-icons';
import { mapboxBbox } from 'components/AnalysisViewPage/utils';
import { lineString } from '@turf/turf';
import useMap from 'hooks/useMap';
import { SelectedSegment } from '../SelectedLinkTree';
import { ReverseGeocodeApi } from 'api/ReverseGeocodeApi';
import { lineStringToReverseGeocodePoint } from 'components/Map/mapUtils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${tombac.space(1.5)};
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  width: 360px;
`;

const SegmentBox = styled.div`
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  font-family: ${tombac.fontFamily};
  padding: 0 16px;
  padding-right: 0;

  :hover {
    background: #f2f2f2;
  }
  :hover #ZoomButtonContainer {
    opacity: 1;
  }
`;

const ZoomButtonContainer = styled(Box)`
  opacity: 0;
`;

const AddressText = styled(DefaultText)`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

interface Entrance {
  address: string;
  trips: number;
  style: StyledSegmentProperties;
  coordinates: number[][];
}

interface Props {
  tree: LinkNode;
  getColor: (v: number) => string;
  unit: SelectedLinkUnit;
  selectedSegments: SelectedSegment[];
  view: View;
}

export const EntrancesExistsInfo = ({
  tree,
  getColor,
  unit,
  selectedSegments,
  view,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [entranceAddresses, setEntranceAddresses] = useState<Entrance[]>([]);
  const [isOpen, setIsOpen] = useState(true);
  const map = useMap();

  useEffect(() => {
    const getAddresses = async () => {
      setIsLoading(true);
      let fetchedEntranceAddresses: Entrance[] = [];

      for (const child of [...tree.children]
        .sort((a, b) => b.trips - a.trips)
        .slice(0, 10)) {
        const coords = lineStringToReverseGeocodePoint(
          lineString(child.coordinates),
        );
        const address = await ReverseGeocodeApi.getRoadName(coords);
        fetchedEntranceAddresses.push({
          address,
          trips: child.trips,
          style: makeSegmentStyle(child.trips, tree.trips, getColor, unit),
          coordinates: child.coordinates,
        });
      }

      setEntranceAddresses(fetchedEntranceAddresses);
      setIsLoading(false);
    };
    getAddresses();
  }, [tree]);

  useEffect(() => {
    entranceAddresses.forEach((entrance) => {
      entrance.style = makeSegmentStyle(
        entrance.trips,
        tree.trips,
        getColor,
        unit,
      );
    });
  }, [entranceAddresses, getColor]);

  useEffect(() => {
    if (selectedSegments.length === 0) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [selectedSegments]);

  const zoomToEntrance = (coordinates: number[][]) => {
    if (map) {
      map.fitBounds(mapboxBbox(lineString(coordinates).geometry), {
        padding: 200,
      });
    }
  };

  return (
    <Container>
      <AsideSection
        title={`Top ${
          view === View.INCOMING ? 'entrances to' : 'exits from'
        } the region via:`}
        disableBorder
        isLoading={isLoading}
        defaultHide={isOpen}
      >
        <Box $mt="2sp" $mb="1sp">
          <BoldText $pl="2sp">
            {view === View.INCOMING ? 'Entrance' : 'Exit'} via
          </BoldText>
          <BoldText $pl="8sp">Number of trips</BoldText>
        </Box>

        {entranceAddresses.map((entrance, index) => (
          <SegmentBox key={index}>
            <AddressText $width="16sp">{entrance.address}</AddressText>
            <div
              style={{
                height: 4 + entrance.style['line-width'] + 'px',
                width: '26px',
                borderRadius: '10px',
                margin: '0 10px',
                border: '1px solid #fff',
                backgroundColor: entrance.style['line-color'],
              }}
            />
            <DefaultText $width="8sp">
              {entrance.trips.toLocaleString()}
            </DefaultText>
            <GreyText $width="5sp">
              {((entrance.trips / tree.trips) * 100).toFixed(2)}%
            </GreyText>
            <ZoomButtonContainer id="ZoomButtonContainer">
              <Button
                shape="circle"
                variant="flat"
                size="xs"
                $background="transparent"
                onClick={(e) => {
                  e.stopPropagation();
                  zoomToEntrance(entrance.coordinates);
                }}
              >
                <ZoomToIcon />
              </Button>
            </ZoomButtonContainer>
          </SegmentBox>
        ))}
      </AsideSection>
    </Container>
  );
};
