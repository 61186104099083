import { formatDistance } from 'date-fns';

export const estimatedDurationLabel = (
  estimatedDurationInSeconds: number,
  startAnalysisTime: string,
  currentProgress: number,
) => {
  const durationInSeconds =
    (Date.now() - new Date(startAnalysisTime).getTime()) / 1000;
  if (durationInSeconds > estimatedDurationInSeconds) {
    return estimationBasedOnCurrentProgress(durationInSeconds, currentProgress);
  } else {
    return valueWithUnit(estimatedDurationInSeconds);
  }
};

const estimationBasedOnCurrentProgress = (
  durationInSeconds: number,
  currentProgress: number,
) => {
  if (currentProgress === 0) {
    return valueWithUnit(durationInSeconds);
  }
  const estimatedInSeconds = (durationInSeconds * 100) / currentProgress;
  const displayedSeconds = Math.max(estimatedInSeconds, durationInSeconds);
  return valueWithUnit(displayedSeconds);
};

const valueWithUnit = (seconds: number) => {
  const currentDate = new Date();
  const currentDatePlusSeconds = new Date(
    currentDate.getTime() + seconds * 1000,
  );
  return formatDistance(currentDate, currentDatePlusSeconds);
};
