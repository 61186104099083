import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AsideSection } from 'components/UI/UI';
import { TimeRangeCondition } from 'model/TimeDto';
import { Box } from 'tombac';
import { TimeIcon } from 'tombac-icons';
import { DateTimeContainer, SectionData } from '../AnalysisMapSidebar.style';

const TimeConditions = {
  [TimeRangeCondition.STARTS]: <>Trips start in the selected time range</>,
  [TimeRangeCondition.WHOLE]: (
    <>Trips start and end in the selected time range</>
  ),
  [TimeRangeCondition.ANY_PART]: (
    <>Trips have any common parts with time range</>
  ),
};

const formatTime = (time: string) =>
  time.startsWith('0') ? time.replace('0', '') : time;

export const Times = () => {
  const { analysis } = useAnalysisContext();
  const {
    timeRanges,
    timeRangeCondition,
    zoneId,
  } = analysis.info.timeDefinition;

  return (
    <AsideSection
      title={
        <Box $display="flex" $gap="8px" $alignItems="center">
          <TimeIcon />
          Times ({timeRanges.length})
        </Box>
      }
    >
      <Box $display="flex" $flexDirection="column" $gap="6px" $mt="12px">
        <SectionData>{TimeConditions[timeRangeCondition]}</SectionData>
        <SectionData>Timezone: {zoneId}</SectionData>
        <Box $display="flex" $flexDirection="column" $gap="12px" $mt="6px">
          {timeRanges.map((range, index) => (
            <DateTimeContainer key={index}>
              {range.startTime === '00:00' && range.endTime === '00:00'
                ? 'All day'
                : `${formatTime(range.startTime)} - ${formatTime(
                    range.endTime,
                  )}`}
            </DateTimeContainer>
          ))}
        </Box>
      </Box>
    </AsideSection>
  );
};
