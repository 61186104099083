export const dateToDto = (d: Date) => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const pad = (n: number) => ('0' + n).substr(-2);

  return `${year}-${pad(month)}-${pad(day)}`;
};

export const dtoToDate = (s: string): Date => {
  const [year, month, day] = s
    .trim()
    .split('-')
    .map((s) => Number(s));
  return new Date(year, month - 1, day);
};
