import { formatDate } from 'components/AnalysisNew/DateRanges/DateUtils';
import AnalysisCloneButton from 'components/AnalysisTable/AnalysisCloneButton';
import { AnalsysisShareButton } from 'components/AnalysisTable/AnalysisShareButton';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AsideSection, PassMatrixTooltip } from 'components/UI/UI';
import { AnalysisStatus, AnalysisType } from 'model/AnalysisDto';
import { Link } from 'react-router-dom';
import { Box, Button, TooltipOnHover, useTombac } from 'tombac';
import { BackIcon, EditIcon, InfoIcon } from 'tombac-icons';
import { useUser } from 'user';
import {
  HeaderLabel,
  Header,
  PassMatrixLabel,
  InfoIconContainer,
} from '../AnalysisMapSidebar.style';

interface Props {
  isShared: boolean;
  showRenamingModal: VoidFunction;
}

export const SidebarHeader = ({ isShared, showRenamingModal }: Props) => {
  const { analysis } = useAnalysisContext();
  const user = useUser();
  const tombac = useTombac();

  return (
    <AsideSection
      disableBorder={analysis.info.status !== AnalysisStatus.ACCEPTED}
    >
      <Box $display="flex" $justifyContent="space-between">
        <Box>
          <Link to="/">
            <Button
              $ml="-1sp"
              prepend={<BackIcon />}
              prependedHoverEffect="anim-backward"
              size="xs"
              variant="flat"
            >
              Back
            </Button>
          </Link>
        </Box>
        {!isShared && (
          <Box $display="flex" $gap="4px">
            <SimpleTooltip content="Rename report" usePortal>
              <Button
                shape="circle"
                variant="flat"
                onClick={showRenamingModal}
                size="xs"
              >
                <EditIcon />
              </Button>
            </SimpleTooltip>
            <AnalsysisShareButton
              analysisId={analysis.info.id}
              size="small"
              type={analysis.info.type}
            />
            {user ? <AnalysisCloneButton
              size="small"
              analysisId={analysis.info.id}
              analysisType={analysis.info.type}
              userRole={user.role}
            /> : null}
          </Box>
        )}
      </Box>
      <Header level={2} $mt="2sp" $mb="1sp" data-test="analysis-title">
        {analysis.info.name}
      </Header>
      {analysis.info.type === AnalysisType.FlowMatrix && (
        <Box $mb="4px">
          <HeaderLabel>Trips outside regions: </HeaderLabel>
          <PassMatrixLabel>
            {analysis.info.passMatrix
              ? 'Trim to defined region'
              : 'Add to external region'}{' '}
            <InfoIconContainer>
              <TooltipOnHover
                placement="left"
                usePortal
                content={
                  <PassMatrixTooltip passMatrix={analysis.info.passMatrix ?? false} />
                }
              >
                <InfoIcon size={1} color={tombac.color('neutral', 600)} />
              </TooltipOnHover>
              </InfoIconContainer>
          </PassMatrixLabel>
        </Box>
      )}
      <HeaderLabel>
        Created{' '}
        {formatDate(
          analysis.info.creationTime,
          user?.settings?.dateFormat || 'DDMMYYYY',
        )}
        {analysis.info.map?.version ? (
          <>
            {' '}• Map version{' '}
            <PassMatrixLabel>{analysis.info.map.version}</PassMatrixLabel>
          </>
        ) : null}
      </HeaderLabel>
    </AsideSection>
  );
};
