import { AsideSection } from 'components/UI/UI';
import { Box, Button } from 'tombac';
import { UnmappedIcon } from 'tombac-icons';
import { Bold, Circle, Header, Text } from './Statuses.style';

interface Props {
  showSummary: () => void;
}

export const AnalysisEnded = ({ showSummary }: Props) => {
  return (
    <AsideSection variant="confirm">
      <Box $display="flex" $alignItems="center" $gap="12px">
        <Circle color="#f0ad4e">
          <UnmappedIcon color="white" width={16} height={16} />
        </Circle>
        <Header level={2}>Needs confirmation</Header>
      </Box>
      <Box $mt="12px">
        <Text>
          <Bold>We need your approval.</Bold> View output summary to decide if
          you are interested in accepting the report
        </Text>
      </Box>
      <Button
        variant="primary"
        onClick={showSummary}
        data-test-id="view-summary"
        $width="100%"
        $mt="16px"
      >
        View Summary
      </Button>
    </AsideSection>
  );
};
