import PropTypes from 'prop-types';
import { colorFillScale } from './colorScale';
import './ColorLegend.css';

const delog = (value) => Math.exp(Math.log(100) * (value / 100));
const descale = (value, min, max) => value / (100 / (max - min)) + min;

const splitInRanges = (min, max, rangeCount) => {
  return Array(rangeCount)
    .fill(null)
    .map((_, i) => ({
      rangeBegin:
        i === 0 ? min : descale(delog((100 / rangeCount) * i), min, max),
      rangeEnd: descale(delog((100 / rangeCount) * (i + 1)), min, max),
      color: colorFillScale((100 / rangeCount) * (i + 0.5)),
    }));
};

const Range = ({ rangeBegin, rangeEnd, color }) => (
  <div className="ColorLegend-Range">
    <div className="ColorLegend-Range-values">
      {rangeBegin.toFixed(2)}% – {rangeEnd.toFixed(2)}%
    </div>
    <div className="ColorLegend-Range-box" style={{ backgroundColor: color }} />
  </div>
);
Range.propTypes = {
  rangeBegin: PropTypes.number,
  rangeEnd: PropTypes.number,
  color: PropTypes.string,
};

const ColorLegend = ({ min, max, rangeCount }) => {
  const ranges = splitInRanges(min, max, rangeCount);

  return (
    <div className="ColorLegend">
      {ranges.map((range, i) => (
        <Range key={i} {...range} />
      ))}
    </div>
  );
};
ColorLegend.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  rangeCount: PropTypes.number,
};

export default ColorLegend;
