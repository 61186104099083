import { Palette } from './ColorPalette';

export const defaultColorPalettes = [
  Palette.make('Viridis', [
    '#fde725',
    '#5ec962',
    '#21918c',
    '#3b528b',
    '#440154',
  ]),
  Palette.make('Sunset', ['#fcde9c', '#e6596f', '#7c1d6f']),
  Palette.make('Sunset - Discrete', ['#fcde9c', '#e6596f', '#7c1d6f'], {
    isDiscrete: true,
  }),
  Palette.make('Purple', ['#f9ddda', '#ce78b3', '#573b88']),
  Palette.make('Magma', ['#fcfbbd', '#ed595f', '#832682', '#150e39']),
  Palette.make(
    'Rainbow',
    [
      '#7c0000',
      '#ff5000',
      '#feeb01',
      '#78ff7d',
      '#02d4ff',
      '#002cff',
      '#000084',
    ],
    { isDiscrete: true },
  ),
  Palette.make(
    'Red - Green',
    ['#f2110d', '#f2a60d', '#f2f20d', '#9ce40c', '#09b009'],
    { isDiscrete: true },
  ),
];
