import { Slider } from 'tombac';
import './Range.css';

const CLASSES = {
  component: 'Range',
  inputContainer: 'Range__selector',
  input: 'Range__selector-input',
  value: 'Range__selector-value',
  min: 'Range__selector-min',
  max: 'Range__selector-max',
  label: 'Range__label',
};

export function Range({
  min,
  max,
  step,
  label,
  id,
  value,
  onChange,
  onAfterChange,
}: {
  value: number;
  min: number;
  max: number;
  step: number;
  label?: any;
  id?: any;
  onChange: (value: number) => void;
  onAfterChange: (value: number) => void;
}) {
  const labelElement = label ? (
    <label className={CLASSES.label}>{label}</label>
  ) : null;

  const ratio = (value - min) / (max - min);
  const valueStyle = {
    left: `${ratio * 100}%`,
    transform: `translateX(-${ratio * 100}%)`,
  };

  let rangeProps: any = {
    min: min,
    max: max,
    step: step,
    value: value,
  };
  if (id !== undefined) {
    rangeProps.id = id;
  }

  return (
    <div className={CLASSES.component}>
      {labelElement}
      <div className={CLASSES.inputContainer}>
        <Slider
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={(v) => onChange(v)}
          onAfterChange={(v) => onAfterChange(v)}
          {...rangeProps}
        />
        <div className={CLASSES.value} style={valueStyle}>
          {value}
        </div>
        <div className={CLASSES.min}>{min}</div>
        <div className={CLASSES.max}>{max}</div>
      </div>
    </div>
  );
}
