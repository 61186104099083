import { DayOfWeek } from 'model/DayOfWeek';
import { useMenu } from 'reducers/menuReducer';
import styled from 'styled-components';
import { tombac, Label } from 'tombac';

export function sortDays(a: DayOfWeek, b: DayOfWeek) {
  return Object.keys(DayOfWeek).indexOf(a) - Object.keys(DayOfWeek).indexOf(b);
}

export const shortDayName = {
  [DayOfWeek.MONDAY]: 'Mon',
  [DayOfWeek.TUESDAY]: 'Tue',
  [DayOfWeek.WEDNESDAY]: 'Wed',
  [DayOfWeek.THURSDAY]: 'Thu',
  [DayOfWeek.FRIDAY]: 'Fri',
  [DayOfWeek.SATURDAY]: 'Sat',
  [DayOfWeek.SUNDAY]: 'Sun',
};

const weekdays = [
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
];

const weekend = [DayOfWeek.SATURDAY, DayOfWeek.SUNDAY];

const DayBox = styled.div<{ active?: boolean }>`
  margin-right: 4px;
  height: 32px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(p) =>
    p.active ? tombac.color('accent', 200) : 'transparent'};
  font-size: 14px;
  font-weight: 500;
  font-family: ${tombac.fontFamily};
  color: ${(p) => (p.active ? tombac.color('accent', 600) : '#000')};
  cursor: pointer;

  :hover {
    background-color: ${(p) =>
      p.active ? tombac.color('accent', 300) : tombac.color('neutral', 300)};
  }

  :last-child {
    margin-right: 0;
  }
`;

const DaysContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const PeriodsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PeriodButton = styled.div<{ active?: boolean }>`
  cursor: pointer;
  font-size: 12px;
  font-family: ${tombac.fontFamily};
  font-weight: ${({ active }) => (active ? 700 : 'normal')};
  color: ${({ active }) => (active ? tombac.color('accent', 600) : '#000')};
`;

export function Days() {
  const [menu, setMenu] = useMenu();

  const sameAsSelectedDays = (daysToCheck: DayOfWeek[]) =>
    daysToCheck.every((day) => menu.daysOfWeek.includes(day)) &&
    menu.daysOfWeek.length === daysToCheck.length;

  const setDaysOfWeek = (days: DayOfWeek[]) => setMenu({ daysOfWeek: days });

  const handleSelectDay = (day: DayOfWeek) => {
    const selectedDays = menu.daysOfWeek;
    if (!selectedDays.includes(day)) {
      setDaysOfWeek([...selectedDays, day].sort(sortDays));
    } else {
      setDaysOfWeek(selectedDays.filter((d: any) => d !== day).sort(sortDays));
    }
  };

  return (
    <>
      <Label $mb="1sp">Days of the week</Label>
      <DaysContainer>
        {Object.values(DayOfWeek).map((it, i) => (
          <DayBox
            key={i}
            onClick={() => handleSelectDay(it)}
            active={menu.daysOfWeek.includes(it)}
          >
            {shortDayName[it]}
          </DayBox>
        ))}
      </DaysContainer>

      <PeriodsContainer>
        <PeriodButton
          active={sameAsSelectedDays(Object.values(DayOfWeek))}
          onClick={() => setDaysOfWeek(Object.values(DayOfWeek))}
        >
          Everyday
        </PeriodButton>
        <PeriodButton
          active={sameAsSelectedDays(weekdays)}
          onClick={() => setDaysOfWeek(weekdays)}
        >
          Weekdays
        </PeriodButton>
        <PeriodButton
          active={sameAsSelectedDays(weekend)}
          onClick={() => setDaysOfWeek(weekend)}
        >
          Weekend
        </PeriodButton>
      </PeriodsContainer>
    </>
  );
}
