import { AsideSection } from 'components/UI/UI';
import { Box } from 'tombac';
import { CancelIcon } from 'tombac-icons';
import { Circle, Header, Text } from './Statuses.style';

export const AnalysisRejected = () => {
  return (
    <AsideSection
      variant="alert"
      canHide={false}
      title={
        <Box $display="flex" $alignItems="center" $gap="12px">
          <Circle color="#df1b12">
            <CancelIcon color="white" width={16} height={16} size="lg" />
          </Circle>
          <Header level={2}>Rejected</Header>
        </Box>
      }
    >
      <Box $mt="12px">
        <Text>Analysis was rejected by user</Text>
      </Box>
    </AsideSection>
  );
};
