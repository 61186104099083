import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  apiKey: string;
}

export const userSlice = createSlice({
  name: 'User',
  initialState: null as UserState | null,
  reducers: {
    set: (_state, action: PayloadAction<UserState>) => action.payload,
    reset: () => null,
  },
});
