import React from 'react';
import { MapControls as LegolandMapControls } from 'legoland-shared';
import { useMapStyleSettings } from 'reducers/mapStyleSettingsReducer';
import { MapTypes } from 'reducers/menuReducer';
import { ZoomInButton } from '../UI/UI';
import { centerOnRegions } from './mapUtils';
import { RegionDto } from '../../model/RegionDto';

interface Props {
  map?: React.MutableRefObject<mapboxgl.Map | undefined>;
  mapStyleMenuContent?: React.ReactNode;
  mapTypeName: MapTypes;
  zoomInButton?: {
    regions: RegionDto[];
    type: React.ComponentProps<typeof ZoomInButton>['type'];
  };
  children?: React.ReactNode;
}

/**
 * Container with control buttons which is placed underneath Zoom In / Zoom Out / Bearing icons in top right corner of the map.
 */
export const MapControls: React.FC<Props> = ({
  map,
  mapStyleMenuContent,
  mapTypeName,
  zoomInButton,
  children,
}) => {
  const [mapStyleSettings, setMapStyleSettings] = useMapStyleSettings();

  const handleZoomInButtonClick = React.useCallback(() => {
    if (!map?.current || !zoomInButton) {
      return;
    }
    centerOnRegions(map.current, zoomInButton.regions);
  }, [map, zoomInButton?.regions]);

  return (
    <LegolandMapControls
      mapModel={mapTypeName}
      mapStyleSettings={mapStyleSettings}
      onMapStyleSettingsChange={setMapStyleSettings}
      mapLayersMenuContent={mapStyleMenuContent}
      styleOptions={['Street light', 'Street dark', 'Mono light', 'Mono dark']}
      topOffset={143}
    >
      {zoomInButton && zoomInButton.regions.length > 0 ? (
        <ZoomInButton
          onClick={handleZoomInButtonClick}
          type={zoomInButton.type}
        />
      ) : null}
      {children}
    </LegolandMapControls>
  );
};
