export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const dayToShort = (day: DayOfWeek) => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const index = Object.values(DayOfWeek).indexOf(day);

  return days[index].toUpperCase();
};

export const shortStringDayToDayOfWeek = (day: string) => {
  return Object.values(DayOfWeek).find((it) =>
    it.startsWith(day.toUpperCase()),
  );
};

/**
 * Return all BUT given days
 */
export const invertDays = (days: DayOfWeek[]) => {
  const allDays = Object.values(DayOfWeek);
  const givenDays = new Set(days);

  return allDays.filter((it) => !givenDays.has(it));
};
