import { AnalysisStatus } from 'model/AnalysisDto';
import { useRef } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, FormGroup } from 'tombac';
import useOnClickOutside from 'use-onclickoutside';

const Frame = styled.div`
  width: 422px;
  height: auto;
  border: 1px solid black;
  background: #fff;
  position: absolute;
  top: 10px;
  border-color: #dddddd;
  padding: 24px;

  @media (max-width: 800px) {
    width: calc(100vw - 30px);
  }

  @media (max-width: 500px) {
    width: calc(100vw - 40px);
  }
`;

const Grid = styled.div`
  margin-top: 16px;
  display: grid;
  gap: 10px 20px;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 9px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 18px;

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ClearAllButton = styled(Button as any)`
  width: 60px;
  height: 30px;
  font-size: 12px;
  position: absolute;
  background: none;
  color: #2a90d5;
  right: 24px;
  bottom: 6px;
`;

interface Props {
  statusFilterSet: Set<AnalysisStatus>;
  handleStatus: (status: AnalysisStatus) => void;
  clearStatuses: () => void;
  children: JSX.Element[];
  setIsOpen: (open: boolean) => void;
}

const StatusFilterPopup = ({
  statusFilterSet,
  handleStatus,
  clearStatuses,
  children,
  setIsOpen,
}: Props) => {
  const hasFilter = (filter: AnalysisStatus) => statusFilterSet.has(filter);
  const ref = useRef(undefined);

  useOnClickOutside(ref, (e) => {
    const filtersButtonInEventPath = e
      .composedPath()
      .find(
        (it: any) =>
          it.className?.includes && it.className.includes('button-filters'),
      );

    if (!filtersButtonInEventPath) {
      setIsOpen(false);
    }
  });

  return (
    <div style={{ position: 'absolute', zIndex: 15 }} ref={ref}>
      <Frame>
        <FormGroup label={'Status'} style={{ width: '100%' }}>
          <Grid>
            {children.map((child, i) => (
              <Checkbox
                key={i}
                label={<div style={{ display: 'inline-flex' }}>{child}</div>}
                checked={hasFilter(child.props.variant)}
                onChange={() => handleStatus(child.props.variant)}
              />
            ))}
          </Grid>
        </FormGroup>
        <ClearAllButton onClick={clearStatuses}>Clear all</ClearAllButton>
      </Frame>
    </div>
  );
};

export default StatusFilterPopup;
