import styled from 'styled-components';
import { tombac } from 'tombac';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TableHeader = styled.thead``;
export const HeaderRow = styled.tr`
  border-bottom: 2px solid #e5e5e5;
`;

export const HeaderCell = styled.th`
  font-family: ${tombac.altFontFamily};
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #4a4c4c;
  text-align: left;
  padding: 12px 0;
`;

export const Row = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
`;

export const Cell = styled.td`
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  color: #333;
  padding: 12px 0;
`;

export const CreatedAt = styled.div`
  margin-top: 4px;
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7a7e80;
`;

export const SummaryInfoBox = styled.div`
  margin-top: 16px;
  padding: 18px 24px;
  display: flex;
  align-items: center;
  gap: 24px;
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
`;

export const Label = styled.span`
  font-family: ${tombac.altFontFamily};
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #4a4c4c;
`;

export const InfoCell = styled.div`
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  color: #333;
`;
