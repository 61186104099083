import { RequireAuth, useUserProduct } from 'legoland-sdk/dist/experimental';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userSlice } from 'reducers/userReducer';
import { useUser } from 'user';

export const LoggedInOnly = ({ children }: { children: any }) => {
  const dispatch = useDispatch();
  const user = useUser();
  const product = useUserProduct();

  useLayoutEffect(() => {
    if (product?.access.status === 'GRANTED') {
      dispatch(userSlice.actions.set({ apiKey: product.access.apiKey }));
    }
  }, [product]);

  return <RequireAuth autoRedirect>{user ? children : null}</RequireAuth>;
};
