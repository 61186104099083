import { Component } from 'react';
import { Select } from 'tombac/dist/components/Form/Select';

interface Option {
  value: number;
  label: string;
}

interface Props {
  regionLabels: string[];
  sliceIndex: number | undefined | null;
  changeSlice: (index: number | undefined | null) => void;
}

class ThroughRegionSelect extends Component<Props> {
  private handleChange = (option: Option) => {
    this.props.changeSlice(option.value);
  };

  render() {
    const { regionLabels, sliceIndex } = this.props;
    const value: Option | null =  typeof sliceIndex === 'number' ? { value: sliceIndex, label: regionLabels[sliceIndex] } : null;

    return (
      <div className="form-group">
        <Select
          onChange={this.handleChange}
          value={value}
          $width="100%"
          placeholder="Select region"
          options={regionLabels.map((it, i) => ({ label: it, value: i }))}
        />
      </div>
    );
  }
}

export default ThroughRegionSelect;
