import { TimeRange as TimeRangeDto } from 'model/TimeDto';
import { isAllDay } from './TimeUtils';

export function FormattedTimeRange(props: {
  timeRange: TimeRangeDto;
  trimSeconds?: boolean;
}): any {
  const { startTime, endTime } = props.timeRange;

  const formattedHour = (hour: string) =>
    props.trimSeconds ? `${hour.split(':')[0]}:${hour.split(':')[1]}` : hour;

  return isAllDay({ startTime, endTime })
    ? 'All day'
    : `${formattedHour(startTime)} — ${formattedHour(endTime)}`;
}
