import { Component } from 'react';
import './NavBar.css';

class NavBar extends Component {
  render() {
    return <div className="ViewNavBar">{this.props.children}</div>;
  }
}

export default NavBar;
