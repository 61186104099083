import PropTypes from 'prop-types';

import './FlatButton.css';

const FlatButton = (props) => {
  const {
    primary,
    secondary,
    danger,
    warning,
    className,
    type,
    disabled,
    onClick,
  } = props;

  const buttonClass = [
    'FlatButton',
    primary && 'FlatButton-primary',
    secondary && 'FlatButton-secondary',
    danger && 'FlatButton-danger',
    warning && 'FlatButton-warning',
    className !== undefined && className,
  ]
    .filter((i) => i !== false)
    .join(' ');

  return (
    <button
      type={type}
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
    >
      {props.children}
    </button>
  );
};

FlatButton.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  warning: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

FlatButton.defaultProps = {
  primary: false,
  secondary: false,
  danger: false,
  type: 'button',
  disabled: false,
};

export default FlatButton;
