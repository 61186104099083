import { useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import mergeRefs from 'react-merge-refs';
import { Button, tombac, Tooltip } from 'tombac';
import useMeasure, { RectReadOnly } from 'react-use-measure';
import { ChromePicker } from 'react-color';
import { DeleteIcon } from 'tombac-icons';
import styled from 'styled-components';
import { useFrequentValue } from './useFrequentValue';
import { Palette, ColorStop, PaletteScale } from './ColorPalette';

export function GradientPreview({ stops }: { stops: Palette['stops'] }) {
  const [id] = useState(() => '' + Math.random());

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <svg width="100%" height="100%">
        <defs>
          <linearGradient id={id} x1="0" y1="0.5" x2="1" y2="0.5">
            {[...stops]
              .sort((a, b) => a.offset - b.offset)
              .map(({ offset, color }: any, i) => (
                <stop key={i} offset={offset} style={{ stopColor: color }} />
              ))}
          </linearGradient>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill={`url(#${id})`} />
      </svg>
    </div>
  );
}

const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export function ColorSelector({
  value,
  onChange,
  onDelete,
}: {
  value: string;
  onChange: (color: string) => void;
  onDelete?: () => void;
}) {
  return (
    <div>
      <ChromePicker
        color={value}
        styles={{
          default: {
            picker: {
              boxShadow: 'none',
              margin: '-6px',
            },
          },
        }}
        onChange={({ rgb: { r, g, b, a } }) =>
          onChange(`rgba(${r},${g},${b},${a})`)
        }
      />
      {onDelete && (
        <Button
          size="xs"
          variant="flat"
          prepend={<DeleteIcon />}
          onClick={onDelete}
        >
          Delete
        </Button>
      )}
    </div>
  );
}

const GradientStopCircle = styled.div`
  position: absolute;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
`;

const GradientStopLabel = styled.div`
  position: absolute;
  bottom: -26px;
  left: 5px;
  transform: translateX(-50%);
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  white-space: nowrap;
`;

function GradientStop({
  stopSize,
  stop,
  bounds,
  readOnly,
  label,
  onChange,
  onDelete,
}: {
  stopSize: number;
  stop: ColorStop;
  bounds: RectReadOnly;
  readOnly?: boolean;
  label: (offset: number) => string;
  onChange: (s: ColorStop) => void;
  onDelete: () => void;
}) {
  const [hovered, setHovered] = useState(false);
  const isDragging = useRef(false);
  const stopSizePx = stopSize + 'px';
  const bind = useGesture(
    {
      onHover: ({ hovering }) => setHovered(hovering),
      onDragStart: () => {
        isDragging.current = true;
      },
      onDragEnd: () => {
        setImmediate(() => {
          isDragging.current = false;
        });
      },
      onDrag: (e) => {
        e.event.preventDefault();
        onChange({
          ...stop,
          offset: clamp((e.xy[0] - bounds.left) / bounds.width, 0, 1),
        });
      },
    },
    { drag: { axis: 'x' }, enabled: !readOnly },
  );

  return (
    <Tooltip
      placement={'top'}
      content={({ close }) => (
        <ColorSelector
          value={stop.color}
          onChange={(color) => onChange({ ...stop, color })}
          onDelete={() => {
            close();
            onDelete();
          }}
        />
      )}
    >
      {({ ref, toggle }) => (
        <GradientStopCircle
          {...bind()}
          ref={ref}
          onClick={(e) => {
            e.stopPropagation();
            if (readOnly) return;
            if (!isDragging.current) {
              toggle();
            }
          }}
          style={{
            width: stopSizePx,
            height: stopSizePx,
            background: stop.color,
            transform: `translateX(${
              stop.offset * bounds.width - stopSize / 2
            }px) scale(${hovered ? 1.1 : 1})`,
          }}
        >
          <GradientStopLabel>{label(stop.offset)}</GradientStopLabel>
        </GradientStopCircle>
      )}
    </Tooltip>
  );
}

const GradientStopsContainer = styled.div`
  position: absolute;
  width: 100%;
  margin-top: 3px;
`;

function GradientStops({
  stops,
  label,
  onChange,
}: {
  stops: ColorStop[];
  label: (offset: number) => string;
  onChange?: (stops: ColorStop[]) => void;
}) {
  const stopSize = 14;
  const stopSizePx = stopSize + 'px';
  const containerRef = useRef();
  const [ref, bounds] = useMeasure();

  const onStopChange = (newStop: ColorStop, index: number) => {
    const newStops = [...stops];
    newStops[index] = newStop;
    onChange(newStops);
  };

  const onStopDelete = (index: number) => {
    const newStops = [...stops];
    newStops[index] = undefined;
    onChange(newStops.filter(Boolean));
  };

  const onNewStop = (offset: number) => {
    onChange([...stops, { offset, color: 'red', id: +new Date() }]);
  };

  return (
    <GradientStopsContainer
      ref={mergeRefs([ref, containerRef])}
      style={{ height: stopSizePx }}
      onClick={(e) => {
        if (!onChange) return;
        if (e.target === containerRef.current) {
          const offset = (e.clientX - bounds.left) / bounds.width;
          onNewStop(offset);
        }
      }}
    >
      {stops.map((it, i) => (
        <GradientStop
          stop={it}
          stopSize={stopSize}
          key={it.id}
          bounds={bounds}
          label={label}
          onDelete={() => onStopDelete(i)}
          onChange={(stop) => onStopChange(stop, i)}
          readOnly={onChange === undefined}
        />
      ))}
    </GradientStopsContainer>
  );
}

const GradientPickerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 20px;
  margin-bottom: 30px;
  flex-grow: 1;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=)
    left center;
`;

export function GradientPicker({
  scale,
  palette,
  unitLabel,
  onChange,
}: {
  scale: PaletteScale;
  palette: Palette;
  unitLabel?: string;
  onChange?: (p: Palette) => void;
}) {
  const [internalPalette, setInternalPalette] = useFrequentValue(
    palette,
    onChange,
    {
      debounce: 200,
      preprocess: (palette) => {
        const newPalette = { ...palette };
        newPalette.stops = [...newPalette.stops].sort(
          (a, b) => a.offset - b.offset,
        );
        return newPalette;
      },
    },
  );

  const label = (offset: number) => {
    const { from, to } = scale;

    let value = String(Math.floor(from + offset * (to - from)));

    if (unitLabel && offset === palette.stops[palette.stops.length - 1].offset)
      return `${value} ${unitLabel}`;
    return value;
  };

  return (
    <GradientPickerContainer>
      <GradientPreview stops={internalPalette.stops} />
      <GradientStops
        stops={internalPalette.stops}
        onChange={
          onChange === undefined
            ? undefined
            : (stops) => setInternalPalette({ ...internalPalette, stops })
        }
        label={label}
      />
    </GradientPickerContainer>
  );
}
