import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AsideSection } from 'components/UI/UI';
import { useSpring } from 'react-spring';
import { Box, Button } from 'tombac';
import { CancelIcon, MenuMeatballsIcon } from 'tombac-icons';
import { Bold, Circle, Header } from './Statuses.style';
import { ProgressBar } from 'components/UI/UI';

interface Props {
  isShared: boolean;
  showCancelModal: VoidFunction;
}

export const AnalysisRunning = ({ isShared, showCancelModal }: Props) => {
  const { analysis } = useAnalysisContext();
  const { tripsCounted, currentProgress } = analysis.info;

  const { progress } = useSpring({
    from: {
      trips: 0,
      progress: 0,
    },
    to: {
      trips: tripsCounted ? Number(tripsCounted) : 0,
      progress: currentProgress ? Number(currentProgress) : 0,
    },
  });

  return (
    <AsideSection
      canHide={false}
      variant="waiting"
      title={
        <Box $display="flex" $alignItems="center" $gap="12px">
          <Circle color="#195780">
            <MenuMeatballsIcon color="white" width={16} height={16} />
          </Circle>
          <Header level={2}>In progress</Header>
        </Box>
      }
    >
      <Box $display="flex" $alignItems="center" $gap="6px" $mt="6px">
        <ProgressBar percentage={currentProgress} />
        <Bold>{progress.to((p) => `${Number(p).toFixed(0)}%`)}</Bold>
      </Box>
      {!isShared && (
        <Button
          prepend={<CancelIcon />}
          $mt="12px"
          $background="transparent"
          onClick={showCancelModal}
        >
          Cancel
        </Button>
      )}
    </AsideSection>
  );
};
