import { useState, useMemo } from 'react';
import cx from 'classnames';
import MapPin from 'react-feather/dist/icons/map-pin';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import ChevronLeft from 'react-feather/dist/icons/chevron-left';
import ChevronUp from 'react-feather/dist/icons/chevron-up';
import ChevronDown from 'react-feather/dist/icons/chevron-down';
import List from 'react-feather/dist/icons/list';
import PlusCircle from 'react-feather/dist/icons/plus-circle';
import X from 'react-feather/dist/icons/x';
import classnames from 'classnames';
import { RegionDto } from '../../../model/RegionDto';
import './MapFlowsDetails.css';
import { ReadableResult } from 'logic/ReadableResult';
import { Box, Button, Input } from 'tombac';
import { Geometry } from '@turf/turf';
import { has } from 'lodash';
import { RegionNameContainer } from './MapFlows.style';
import { ZoomToRegionButton } from '../ZoomToRegionButton';

enum PickingTypes {
  ORIGIN = 'ORIGIN',
  DESTINATION = 'DESTINATION',
  NONE = 'NONE',
}

interface Props {
  selectedFrom: number;
  selectedTo: number;
  result: ReadableResult;
  dtoRegions: RegionDto[];
  hovered: number;
  onHover: any;
  onOrigin: any;
  onDestination: any;
  hasExternals: boolean;
  hideable?: boolean;
  zoomToRegion: (geometry: Geometry) => void;
}

const isRegionDto = (r: any): r is RegionDto => has(r, 'geometry');

function MapFlowsDetails(props: Props) {
  const [selectedRegion, setSelectedRegion] = useState(-1);
  const [listIsExpanded, setListIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [pickingType, setPickingType] = useState(PickingTypes.NONE);
  const [isHidden, setIsHidden] = useState(false);

  const {
    selectedFrom,
    selectedTo,
    dtoRegions,
    hasExternals,
    hideable,
    onOrigin,
    onDestination,
    onHover,
    result,
  } = props;

  const className = classnames('MapFlowsDetails', {
    'MapFlowsDetails--hidden': hideable ? isHidden : false,
  });

  const regions = hasExternals
    ? [
        ...dtoRegions,
        { properties: { name: 'External', i: dtoRegions.length } as any },
      ]
    : dtoRegions;

  const regionFrom = regions[selectedFrom];
  const regionTo = regions[selectedTo];
  const isVia = selectedFrom > -1 && selectedTo > -1;
  const oneToMany = selectedFrom > -1 && selectedTo === -1;
  const manyToOne = selectedFrom === -1 && selectedTo > -1;

  const list = useMemo(() => {
    return regions
      .map((region, i) => {
        const id = i;
        let flows = 0;
        if (oneToMany) {
          flows = result.getOf([selectedFrom], [id]).trips;
        }
        if (manyToOne) {
          flows = result.getOf([id], [selectedTo]).trips;
        }
        if (isVia) {
          flows = result.getOf([selectedFrom], [selectedTo], [id]).trips;
        }
        return {
          flows,
          name: region.properties.name,
          i: id,
          geometry: isRegionDto(region) ? region.geometry : undefined,
        };
      })
      .filter((r) => r.flows > 0);
  }, [regions, result, selectedFrom, selectedTo]);

  const itemClick = (i: number) => () => {
    if (i === selectedRegion) {
      setSelectedRegion(-1);
    } else {
      setSelectedRegion(i);
    }
  };
  const itemHover = (i: number) => () => onHover(i);
  const itemOrigin = (i: number) => () => onOrigin(i);
  const itemDestination = (i: number) => () => onDestination(i);

  return (
    <div className={className}>
      {hideable && (
        <div
          className="MapFlowsDetails__toggle"
          onClick={() => setIsHidden((x) => !x)}
        >
          {isHidden ? <ChevronRight size={14} /> : <ChevronLeft size={14} />}
        </div>
      )}
      {pickingType !== PickingTypes.NONE && (
        <div>
          <div
            className={`MapFlowsDetails--picker-arrow ${
              pickingType === PickingTypes.ORIGIN
                ? 'MapFlowsDetails--picker-arrow__origin'
                : 'MapFlowsDetails--picker-arrow__destination'
            }
            ${selectedFrom > -1 ? 'MapFlowsDetails--picker-arrow__lower' : ''}
            `}
          />
          <div className="MapFlowsDetails--picker">
            <div className="MapFlowsDetails--picker-search">
              <Input
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div
                className="MapFlowsDetails--picker-remove"
                onClick={() => setPickingType(PickingTypes.NONE)}
              >
                <X size={18} />
              </div>
            </div>
            <div
              className="MapFlowsDetails--picker-list"
              onMouseLeave={() => onHover(-1)}
            >
              {regions
                .filter((r) =>
                  (r.properties.name || 'External')
                    .toString()
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()),
                )
                .map((r) => (
                  <div
                    key={r.properties.name}
                    className={cx(
                      'MapFlowsDetails--picker-list-item MapFlowsDetails__interactive',
                      {
                        'MapFlowsDetails__list-item--hovered':
                          props.hovered === r.properties.i,
                      },
                    )}
                    onMouseOver={itemHover(r.properties.i)}
                    onClick={() => {
                      setPickingType(PickingTypes.NONE);
                      pickingType === PickingTypes.ORIGIN
                        ? onOrigin(r.properties.i)
                        : onDestination(r.properties.i);
                    }}
                  >
                    <div> {r.properties.name} </div>
                    {isRegionDto(r) && (
                      <ZoomToRegionButton
                        zoomToRegion={() => props.zoomToRegion(r.geometry)}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      <div>
        {/* ORIGIN */}
        {oneToMany || isVia ? (
          <div className="MapFlowsDetails--region-container">
            <MapPin size={22} />
            <div>
              <div className="MapFlowsDetails--label">From</div>
              <div className="MapFlowsDetails--name">
                {regionFrom.properties.name}{' '}
                {isRegionDto(regionFrom) && (
                  <ZoomToRegionButton
                    zoomToRegion={() => props.zoomToRegion(regionFrom.geometry)}
                  />
                )}
              </div>
            </div>

            <div
              className="MapFlowsDetails--remove"
              onClick={() => onOrigin(-1)}
            >
              <X size={22} />
            </div>
          </div>
        ) : (
          <div
            className="MapFlowsDetails--region-container MapFlowsDetails__interactive"
            onClick={() => setPickingType(PickingTypes.ORIGIN)}
          >
            <PlusCircle size={22} />
            <div className="MapFlowsDetails--label">Add origin</div>
          </div>
        )}

        {/* Dest */}
        {manyToOne || isVia ? (
          <div className="MapFlowsDetails--region-container">
            <MapPin size={22} color="#bbb" />
            <div>
              <div className="MapFlowsDetails--label">To</div>
              <div className="MapFlowsDetails--name">
                {regionTo.properties.name}{' '}
                {isRegionDto(regionTo) && (
                  <ZoomToRegionButton
                    zoomToRegion={() => props.zoomToRegion(regionTo.geometry)}
                  />
                )}
              </div>
            </div>
            <div
              className="MapFlowsDetails--remove"
              onClick={() => onDestination(-1)}
            >
              <X size={22} />
            </div>
          </div>
        ) : (
          <div
            className="MapFlowsDetails--region-container MapFlowsDetails__interactive"
            onClick={() => setPickingType(PickingTypes.DESTINATION)}
          >
            <PlusCircle size={22} />
            <div>
              <div className="MapFlowsDetails--label">Add destination</div>
            </div>
          </div>
        )}

        {list.length > 0 && (
          <div
            className="MapFlowsDetails--region-container MapFlowsDetails__interactive"
            onClick={() => setListIsExpanded((x) => !x)}
          >
            <List size={22} />

            <div className="MapFlowsDetails--label">
              {oneToMany && 'To'}
              {manyToOne && 'From'}
              {isVia && 'Via'}
            </div>

            <div className="MapFlowsDetails--toggle">
              {listIsExpanded ? (
                <ChevronUp size={22} />
              ) : (
                <ChevronDown size={22} />
              )}
            </div>
          </div>
        )}
        {list.length > 0 && listIsExpanded && (
          <div className="MapFlowsDetails--list-header">
            <div>Name</div>
            <div>Flows</div>
          </div>
        )}
        {list.length > 0 && listIsExpanded && (
          <div className="MapFlowsDetails--list-container">
            <ul onMouseLeave={() => onHover(-1)}>
              {list
                .sort((a, b) => b.flows - a.flows)
                .filter((a) => a.flows !== 0)
                .filter((a) => (isVia ? a.name !== 'External' : true))
                .map(({ flows, name, i, geometry }) => (
                  <li
                    key={i}
                    onClick={itemClick(i)}
                    onMouseOver={itemHover(i)}
                    className={cx('MapFlowsDetails__list-item', {
                      'MapFlowsDetails__list-item--hovered':
                        props.hovered === i,
                    })}
                  >
                    <RegionNameContainer>
                      {name}{' '}
                      <Box $display="flex" $gap="4px" $alignItems="center">
                        <div>{flows}</div>
                        {geometry && (
                          <ZoomToRegionButton
                            zoomToRegion={() => props.zoomToRegion(geometry)}
                          />
                        )}
                      </Box>
                    </RegionNameContainer>
                    {i === selectedRegion && (
                      <div className="MapFlowsDetails--actions">
                        <Button onClick={itemOrigin(i)}>Set as origin</Button>
                        <Button onClick={itemDestination(i)}>
                          Set as destination
                        </Button>
                      </div>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default MapFlowsDetails;
