import { ReactNode } from 'react';
import {
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalLayout,
} from 'tombac';

interface ActionButtonProps {
  text: string;
  action: VoidFunction;
}

interface Props {
  isOpen: boolean;
  headerText: string;
  onClose: VoidFunction;
  cancel: ActionButtonProps;
  confirm: ActionButtonProps;
  content: ReactNode;
}

export const ConfirmActionModal = ({
  isOpen,
  onClose,
  headerText,
  cancel,
  confirm,
  content,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalLayout>
        <ModalHeader>{headerText}</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalContent>{content}</ModalContent>
        <ModalActions>
          <Button variant="flat" onClick={cancel.action}>
            {cancel.text}
          </Button>
          <Button variant="primary" onClick={confirm.action}>
            {confirm.text}
          </Button>
        </ModalActions>
      </ModalLayout>
    </Modal>
  );
};
