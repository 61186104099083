import Swap from 'react-feather/dist/icons/maximize-2';
import './RotateButton.css';

const RotateButton = ({ onClick }: { onClick: () => void }) => (
  <div className="RotateButton" onClick={onClick}>
    <Swap size={14} />
  </div>
);

export default RotateButton;
