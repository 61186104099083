import Helmet from 'react-helmet';

function Title(props: { children: string }) {
  const base = 'TomTom O/D Analysis';
  let title;
  if (props.children === undefined || props.children === '') {
    title = base;
  } else {
    title = base + ' | ' + props.children;
  }
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}

export default Title;
