import RegionType from './regionType';

const MIN_CELLS = 1;

function divideSegment(start: number, end: number, count: number) {
  const length = end - start;
  if (length === 0) {
    return [start, end];
  }

  const points = [];
  for (let i = 0; i < count; ++i) {
    const point = start + (length * i) / count;
    points.push(point);
  }
  points.push(end);

  return points;
}

export function convertToRectangles({ bounds, cells }: any, nameMaker: any) {
  const [{ lng: lng0, lat: lat0 }, { lng: lng1, lat: lat1 }] = bounds;

  const { rows, cols } = cells || { rows: MIN_CELLS, cols: MIN_CELLS };
  const rowStops = divideSegment(lat0, lat1, rows);
  const colStops = divideSegment(lng0, lng1, cols);

  const rectangles = [];
  for (let i = 0; i < rowStops.length - 1; ++i) {
    for (let j = 0; j < colStops.length - 1; ++j) {
      const start = { lat: rowStops[i], lng: colStops[j] };
      const end = { lat: rowStops[i + 1], lng: colStops[j + 1] };
      const rectangle = {
        type: RegionType.RECTANGLE,
        name: nameMaker(i, j),
        shape: {
          bounds: [start, end],
        },
      };
      rectangles.push(rectangle);
    }
  }

  return rectangles;
}
