import { toUTCDate } from 'components/AnalysisNew/Date/utils';
import { isWithinInterval } from 'date-fns';
import { useEffect, useState } from 'react';
import { Box, CalendarMonth, MonthYear } from 'tombac';
import { ChevronLeftIcon, ChevronRightIcon } from 'tombac-icons';
import { ChangeMonthButton } from './ChangeMonthButton';
import {
  CalendarContainer,
  MonthContainer,
  YearMonthLabel,
} from './DatePicker.style';
import clampMonthAndYear from './utils/clampMonthAndYear';
import getNextMonth from './utils/getNextMonth';
import getPrevMonth from './utils/getPrevMonth';
import { YearSelect } from './YearSelect';

interface Props {
  isOpen: boolean;
  minDate: Date;
  maxDate: Date;
  value?: Date;
  onDayClick: (date: Date) => void;
}

const monthLabel = (month: number) => {
  const someDayOfTheMonth = new Date(Date.UTC(2000, month - 1, 2));
  return new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
    someDayOfTheMonth,
  );
};

const getInitialState = ({
  minDate,
  maxDate,
}: Pick<Props, 'minDate' | 'maxDate'>): [number, number] => {
  const today = new Date();
  if (isWithinInterval(today, { start: minDate, end: maxDate })) {
    return [today.getMonth() + 1, today.getFullYear()];
  }
  return [maxDate.getMonth() + 1, maxDate.getFullYear()];
};

export const DatePickerCalendar = ({
  isOpen,
  minDate,
  maxDate,
  value,
  onDayClick,
}: Props) => {
  const [currentMonthYear, setCurrentMonthYear] = useState<MonthYear>(() =>
    getInitialState({ minDate, maxDate }),
  );

  useEffect(() => {
    if (value) {
      setCurrentMonthYear([value.getMonth() + 1, value.getFullYear()]);
    }
  }, [value]);

  const [currentMonth, currentYear] = currentMonthYear;

  const isPrevMonthAvailable = minDate
    ? currentYear > minDate!.getFullYear() ||
      (currentYear === minDate!.getFullYear() &&
        currentMonth > minDate!.getMonth() + 1)
    : true;

  const isNextMonthAvailable = maxDate
    ? currentYear < maxDate!.getFullYear() ||
      (currentYear === maxDate!.getFullYear() &&
        currentMonth < maxDate!.getMonth() + 1)
    : true;

  const nextMonth = () => {
    setCurrentMonthYear((prev) => getNextMonth(prev));
  };

  const prevMonth = () => {
    setCurrentMonthYear((prev) => getPrevMonth(prev));
  };

  const handleSelectYear = (year: number) => {
    const { month: clampedMonth } = clampMonthAndYear(
      currentMonth,
      year,
      minDate!,
      maxDate!,
    );

    setCurrentMonthYear([clampedMonth, year]);
  };

  return (
    <CalendarContainer isOpen={isOpen}>
      <Box $display="flex" $alignItems="center">
        <ChangeMonthButton
          onClick={prevMonth}
          isMonthAvailable={isPrevMonthAvailable}
          icon={<ChevronLeftIcon />}
        />
        <YearMonthLabel>
          {monthLabel(currentMonth)}{' '}
          <YearSelect
            currentYear={currentYear}
            maxDate={maxDate}
            minDate={minDate}
            onYearSelected={handleSelectYear}
          />
        </YearMonthLabel>
        <ChangeMonthButton
          onClick={nextMonth}
          isMonthAvailable={isNextMonthAvailable}
          icon={<ChevronRightIcon />}
        />
      </Box>
      <MonthContainer>
        <CalendarMonth
          key={`${currentMonthYear}-${currentMonthYear}`}
          year={currentYear}
          month={currentMonth}
          onDayClicked={onDayClick}
          minDate={toUTCDate(minDate)}
          maxDate={toUTCDate(maxDate)}
          hideMonthName
          startDate={toUTCDate(value)}
          endDate={toUTCDate(value)}
        />
      </MonthContainer>
    </CalendarContainer>
  );
};
