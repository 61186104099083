import * as React from 'react';
import { createPortal } from 'react-dom';

const TOOLTIP_WRAPPER_CLASS = 'tt-tip';

const getComponentName = (Component) =>
  Component.displayName || Component.name || 'Component';

export interface PortalProps {
  [prop: string]: any;
}

export interface PortalStats {
  isMounted: boolean;
}

function portal(WrappedComponent): any {
  class ComposedComponent extends React.PureComponent<
    PortalProps,
    PortalStats
  > {
    portalRoot;

    constructor(props) {
      super(props);

      this.state = {
        isMounted: false,
      };
    }

    componentDidMount() {
      this.portalRoot = window.document.querySelector(
        `.${TOOLTIP_WRAPPER_CLASS}`,
      );

      if (!this.portalRoot) {
        this.portalRoot = window.document.createElement('div');
        this.portalRoot.classList.add(TOOLTIP_WRAPPER_CLASS);
        window.document.body.appendChild(this.portalRoot);
      }

      this.setState({ isMounted: true });
    }

    render() {
      const content = <WrappedComponent {...this.props} />;
      return this.state.isMounted
        ? createPortal(content, this.portalRoot)
        : null;
    }
  }

  (ComposedComponent as any).displayName = `Portal(${getComponentName(
    WrappedComponent,
  )})`;

  return ComposedComponent;
}

export default portal;
