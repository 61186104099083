import Immutable from 'immutable';
import { useState } from 'react';

export interface Scenario {
  /** Unique scenario id */
  id?: number;
  /** Scenario name */
  name: string;
  /** Origin IDs */
  origins: Immutable.Set<number>;
  /** Destination IDs */
  destinations: Immutable.Set<number>;
  /** Via IDs */
  vias: Immutable.Set<number>;
  /** Index of date range */
  dateRange: number;
  /** Index of time range */
  timeRange: number;
  /** Index of date range to compare to */
  dateRangeCompare?: number;
  /** Index of time range to compare to */
  timeRangeCompare?: number;
}

export const useScenario = ({
  origins = [],
  destinations = [],
  vias = [],
}: {
  origins?: number[];
  destinations?: number[];
  vias?: number[];
} = {}) => {
  const [scenario, setScenario] = useState<Scenario>({
    name: 'default',
    dateRange: 0,
    timeRange: 0,
    origins: Immutable.Set(origins),
    destinations: Immutable.Set(destinations),
    vias: Immutable.Set(vias),
  });

  const setOrigins = (origins: number[]) => {
    setScenario((s) => ({ ...s, origins: Immutable.Set(origins) }));
  };
  const setDestinations = (destinations: number[]) => {
    setScenario((s) => ({ ...s, destinations: Immutable.Set(destinations) }));
  };
  const setVias = (vias: number[]) => {
    setScenario((s) => ({ ...s, vias: Immutable.Set(vias) }));
  };

  return {
    scenario,
    setScenario,
    scenarioActions: { setOrigins, setDestinations, setVias },
  };
};
