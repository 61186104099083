import { eachDayOfInterval } from 'date-fns';
import { dateToDto } from 'logic/time/dateFormat';
import { DayOfWeek, dayToShort, invertDays } from 'model/DayOfWeek';
import { count } from 'ramda';
import {
  createIsDayActive,
  isDateInActiveDays,
  isEveryDayActive,
  isWeekdaysActive,
  isWeekendActive,
} from '../dateRangeUtils';
import { DateRange } from './DateRange';

export const getActiveDays = (dateRange: DateRange) => {
  const isDayActive = createIsDayActive(dateRange);
  return Object.values(DayOfWeek).filter(isDayActive);
};

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getActiveDaysLabel = (
  dateRange: DateRange,
  accActiveDays: DayOfWeek[] = [],
) => {
  const invertedAccActiveDays =
    accActiveDays.length > 0 ? invertDays(accActiveDays) : [];
  const inactiveDays = Array.from(
    new Set([
      ...invertDays(getActiveDays(dateRange)),
      ...invertedAccActiveDays,
    ]),
  );
  const activeDays = invertDays(inactiveDays);

  if (isEveryDayActive(activeDays)) {
    return 'All week';
  } else if (isWeekendActive(activeDays)) {
    return 'Weekends';
  } else if (isWeekdaysActive(activeDays)) {
    return 'Weekdays';
  } else {
    return activeDays
      .map(dayToShort)
      .map((s) => capitalizeFirstLetter(s.toLowerCase()))
      .join(', ');
  }
};

export const countExcludedDays = (dateRange: DateRange) => {
  const isDayActive = createIsDayActive(dateRange);
  const activeDays = Object.values(DayOfWeek).filter(isDayActive);
  return count(
    (d: Date) =>
      isDateInActiveDays(d, activeDays) &&
      dateRange.exclusions.includes(dateToDto(d)),
  )(eachDayOfInterval(dateRange));
};
