import { NavbarMenuLink } from 'tombac';
import './NavBarLink.css';
import { Link } from 'react-router-dom';

const NavBarLink = (props: any) => {
  const { active, children, ...rest } = props;
  return (
    <NavbarMenuLink as={Link} className={active ? 'active' : ''} {...rest}>
      {children}
    </NavbarMenuLink>
  );
};

export default NavBarLink;
