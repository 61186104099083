export enum TYPES {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  WARNING = 'WARNING',
}

export enum PLACEMENTS {
  // TOP
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',

  // RIGHT
  RIGHT = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',

  // BOTTOM
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',

  // LEFT
  LEFT = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
}
