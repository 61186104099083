import { AnalysisStatus } from 'model/AnalysisDto';
import { useState } from 'react';
import Check from 'react-feather/dist/icons/check';
import Loader from 'react-feather/dist/icons/loader';
import Minus from 'react-feather/dist/icons/minus';
import MoreHorizontal from 'react-feather/dist/icons/more-horizontal';
import X from 'react-feather/dist/icons/x';
import styled from 'styled-components';
import { Button, tombac } from 'tombac';
import { FiltersIcon } from 'tombac-icons';
import { AnalysisStatusIndicator } from './AnalysisStatusIndicator';
import StatusFilterPopup from './StatusFilterPopup';

const Ribbon = styled.div`
  background: #df1b12;
  color: white;
  height: 18px;
  width: 18px;
  line-height: 18px;
  position: absolute;
  top: -9px;
  right: -9px;
  border-radius: 9px;
  font-family: ${tombac.altFontFamily};
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.1px;
  text-align: center;
  z-index: 10;
`;

interface Props {
  statusFilterSet: Set<AnalysisStatus>;
  setStatusFilterSet: (status: Set<AnalysisStatus>) => void;
}

const StatusFilter = ({ statusFilterSet, setStatusFilterSet }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleStatus = (filter: AnalysisStatus) => {
    const newSet = new Set<AnalysisStatus>(statusFilterSet);
    if (newSet.has(filter)) {
      newSet.delete(filter);
    } else {
      newSet.add(filter);
    }
    setStatusFilterSet(newSet);
  };

  return (
    <div style={{ display: 'block', position: 'relative' }}>
      {statusFilterSet.size !== 0 && <Ribbon>{statusFilterSet.size}</Ribbon>}
      <Button
        onClick={() => setIsOpen(!isOpen)}
        append={<FiltersIcon />}
        style={{
          borderLeft: 'none',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        className={'button-filters button-ternary'}
      >
        Filters
      </Button>
      {isOpen && (
        <StatusFilterPopup
          setIsOpen={setIsOpen}
          statusFilterSet={statusFilterSet}
          handleStatus={handleStatus}
          clearStatuses={() => setStatusFilterSet(new Set<AnalysisStatus>())}
        >
          <AnalysisStatusIndicator
            variant={AnalysisStatus.ACCEPTED}
            icon={<Check size={14} />}
          >
            Ready
          </AnalysisStatusIndicator>
          <AnalysisStatusIndicator
            variant={AnalysisStatus.CANCELLED}
            icon={<Minus size={14} />}
          >
            Cancelled
          </AnalysisStatusIndicator>
          <AnalysisStatusIndicator variant={AnalysisStatus.ENDED} icon={'?'}>
            Need confirmation
          </AnalysisStatusIndicator>
          <AnalysisStatusIndicator
            variant={AnalysisStatus.REJECTED}
            icon={<X size={14} />}
          >
            Rejected
          </AnalysisStatusIndicator>
          <AnalysisStatusIndicator
            variant={AnalysisStatus.WAITING}
            icon={<MoreHorizontal size={14} />}
          >
            Queued
          </AnalysisStatusIndicator>
          <AnalysisStatusIndicator variant={AnalysisStatus.FAILED} icon={'!'}>
            Error
          </AnalysisStatusIndicator>
          <AnalysisStatusIndicator
            variant={AnalysisStatus.RUNNING}
            icon={<Loader size={14} />}
          >
            In progress
          </AnalysisStatusIndicator>
        </StatusFilterPopup>
      )}
    </div>
  );
};

export default StatusFilter;
