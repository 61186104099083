import { TimeRange } from 'model/TimeDto';

export const isAllDay = (timeRange: TimeRange) => {
  return (
    (timeRange?.startTime === '00:00' || timeRange?.startTime === '00:00:00') &&
    (timeRange?.endTime === '00:00' || timeRange?.endTime === '00:00:00')
  );
};

export const beaufitulTime = (
  { startTime, endTime }: TimeRange,
  trimSeconds?: boolean,
) => {
  const formattedHour = (hour: string) =>
    trimSeconds ? `${hour.split(':')[0]}:${hour.split(':')[1]}` : hour;

  return isAllDay({ startTime, endTime })
    ? 'All day'
    : `${formattedHour(startTime)} — ${formattedHour(endTime)}`;
};
