export const TimesetColors = [
  '#89e1e0',
  '#ecb4b9',
  '#fbe698',
  '#baecb4',
  '#b4baec',
  '#e4d6ce',
  '#ecb4ec',
  '#dae8a0',
  '#eb7c8f',
  '#c0b1b5',
  '#d3bbdd',
  '#94c973',
  '#7abde5',
  '#af9c9c',
  '#e1d0d5',
  '#e1c686',
  '#8fdde7',
  '#f0e696',
  '#59d98a',
  '#e2b7c4',
  '#a5a5cb',
  '#8cc7c0',
  '#ece3f0',
  '#f4739b',
];
