import { animated } from 'react-spring';
import styled from 'styled-components';

export const EmptyBar = styled.div`
  background-color: #d5d5d5;
  border-radius: 3px;
  width: 100%;
  height: 6px;
`;

export const Progress = styled(animated.div)`
  height: 6px;
  background-color: #195780;
  border-radius: 3px;
`;
