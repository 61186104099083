import { featureCollection } from '@turf/helpers';
import { saveAs } from 'file-saver';
import { Button } from 'tombac';
import { DownloadIcon } from 'tombac-icons';
import { useAnalysisContext } from '../AnalysisViewPage';

function RegionDownload() {
  const { analysis } = useAnalysisContext();

  function getGeoJson() {
    const collection = featureCollection(analysis.regions);
    const blob = new Blob([JSON.stringify(collection, null, 2)], {
      type: 'application/json;charset=utf-8',
    });
    saveAs(blob, `${analysis.info.name}.json`);
  }

  return (
    <Button
      prepend={<DownloadIcon />}
      onClick={() => getGeoJson()}
      $width="100%"
    >
      Download as GeoJSON
    </Button>
  );
}

export default RegionDownload;
