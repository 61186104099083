import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AsideSection } from 'components/UI/UI';
import { Box } from 'tombac';
import { DateIcon } from 'tombac-icons';
import { PreviewDate } from '../PreviewDate/PreviewDate';

export const Dates = () => {
  const { analysis } = useAnalysisContext();
  const { dateRanges } = analysis.info.timeDefinition;
  return (
    <AsideSection
      title={
        <Box $display="flex" $gap="8px" $alignItems="center">
          <DateIcon />
          Dates ({dateRanges.length})
        </Box>
      }
    >
      <Box $display="flex" $flexDirection="column" $gap="16px" $mt="20px">
        {dateRanges.map((dateRange, index) => (
          <PreviewDate range={dateRange} key={index} />
        ))}
      </Box>
    </AsideSection>
  );
};
