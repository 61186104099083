import AnalysisTablePage from '../AnalysisTable/AnalysisTable';
import Title from '../Layout/Title';

const SupportPage = () => {
  return (
    <div className="SupportPage">
      <Title>Support</Title>
      <AnalysisTablePage title="Users' reports" path='/support' showDrafts={false} adminView={true} />
    </div>
  );
};

export default SupportPage;
