import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import { Button } from 'tombac';
import { ZoomToIcon } from 'tombac-icons';

interface Props {
  zoomToRegion: VoidFunction;
}

export const ZoomToRegionButton = ({ zoomToRegion }: Props) => (
  <SimpleTooltip content="Zoom to region" placement="top" usePortal>
    <Button
      className="MapFlowsDetails__zoom-to-region"
      shape="circle"
      variant="flat"
      size="xs"
      $background="transparent"
      onClick={(e) => {
        e.stopPropagation();
        zoomToRegion();
      }}
    >
      <ZoomToIcon />
    </Button>
  </SimpleTooltip>
);
