import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AsideSection } from 'components/UI/UI';
import { AreaIcon } from 'tombac-icons';
import RegionDownload from '../../RegionDownload';
import { Value } from '../AnalysisMapSidebar.style';
import { LinkAsideTitle, LinkInfoContainer } from './Sections.style';
import { metersToKm } from 'logic/unit';

export const SelectedRegion = () => {
  const { analysis } = useAnalysisContext();

  return (
    <AsideSection
      title={
        <LinkAsideTitle>
          <AreaIcon />
          Region
        </LinkAsideTitle>
      }
    >
      <LinkInfoContainer>
        {analysis.info.map?.version && (
          <Value>Map version: {analysis.info.map?.version}</Value>
        )}
        {analysis.info.bufferRadiusInMeters && (
          <Value>
            Radius: {metersToKm(analysis.info.bufferRadiusInMeters)} km
          </Value>
        )}
        <RegionDownload />
      </LinkInfoContainer>
    </AsideSection>
  );
};
