import chroma from 'chroma-js';

const defaultTheme = [
  'hsl(100, 50%, 70%)',
  'hsl(90.4, 60%, 68%)',
  'hsl(80.8, 70%, 66%)',
  'hsl(71.2, 80%, 64%)',
  'hsl(61.6, 90%, 62%)',
  'hsl(52, 90%, 60%)',
  'hsl(42.400000000000006, 90%, 58%)',
  'hsl(32.8, 90%, 56%)',
  'hsl(23.200000000000003, 90%, 54%)',
  'hsl(13.600000000000009, 90%, 52%)',
  'hsl(4, 90%, 50%)',
];

const scaleName = [
  'YlGnBu',
  'GnBu',
  defaultTheme,
  'YlOrRd',
  'PuBuGn',
  'BuGn',
][3];

export const colorScale = (value) => chroma.scale(scaleName)(value / 100);

export const rangeCount = 6;
const scaleTable = [];
const darkerScaleTable = [];

for (let i = 0; i < rangeCount; i++) {
  const color = chroma.scale(scaleName)(i / rangeCount || 0);
  scaleTable[i] = color.toString();
  darkerScaleTable[i] = color.darken(2.0).toString();
}

function valueToRange(value) {
  const limitedValue = value > 100 ? 100 : value < 0 ? 0 : value;
  const range = Math.min(
    Math.floor((limitedValue / 100) * rangeCount),
    rangeCount - 1,
  );
  return range;
}

function colorFillScale(value) {
  return scaleTable[valueToRange(value)];
}

function colorTextScale(value) {
  return darkerScaleTable[valueToRange(value)];
}

export { colorFillScale, colorTextScale };
