import circle from '@turf/circle';
import { multiPolygon, multiLineString } from '@turf/helpers';
import { convertToRectangles } from './gridSupport';

const rectangleBoundsToCoords = (bounds: any[]) => [
  [bounds[0].lng, bounds[0].lat],
  [bounds[0].lng, bounds[1].lat],
  [bounds[1].lng, bounds[1].lat],
  [bounds[1].lng, bounds[0].lat],
  [bounds[0].lng, bounds[0].lat],
];

function convertDtoToGeoJson(stub: any, saveOriginal: boolean): any {
  const properties: any = {
    name: stub.name,
    id: stub.id,
    flowMatrixRole: stub.flowMatrixRole,
  };

  if (stub.validationResult) {
    properties.validationResult.status = stub.validationResult.status;
  }
  if (stub.type === 'POLYGON') {
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'Polygon',
        coordinates: [
          stub.shape.points[0].map(({ lat, lng }: any) => [lng, lat]),
        ],
      },
    };
  }
  if (stub.type === 'GRID' && stub.shape.cells !== null) {
    const rects = convertToRectangles(
      stub.shape,
      (): any => undefined,
    ).map((rect) => [rectangleBoundsToCoords(rect.shape.bounds)]);
    return { ...multiPolygon(rects), properties };
  }
  if (stub.type === 'RECTANGLE' || stub.type === 'GRID') {
    const bounds = stub.shape.bounds;
    const points = rectangleBoundsToCoords(bounds);
    return {
      type: 'Feature',
      properties,
      geometry: {
        type: 'Polygon',
        coordinates: [points],
      },
    };
  }
  if (stub.type === 'CIRCLE') {
    const { center, radius } = stub.shape;
    const circleProperties = saveOriginal
      ? { ...properties, shape: stub.shape }
      : properties;
    return {
      ...circle([center.lng, center.lat], radius || 1, { units: 'meters' }),
      properties: circleProperties,
    };
  }
  if (stub.type === 'MULTIPOLYGON') {
    const crds = stub.shape.points.map((polygon: any) =>
      polygon.map((coordinates: any) =>
        coordinates.map(({ lng, lat }: any) => [lng, lat]),
      ),
    );
    return { ...multiPolygon(crds), properties };
  }
  if (stub.type === 'SEGMENT_LIST') {
    return multiLineString(
      stub.shape.points.map((line: any) =>
        line.map(({ lng, lat }: any) => [lng, lat]),
      ),
      properties,
    );
  }
}

export { convertDtoToGeoJson };
