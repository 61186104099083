/* eslint-disable @typescript-eslint/no-this-alias */
import bearing from '@turf/bearing';
import { Feature, lineString, point, Point } from '@turf/helpers';
import { length } from '@turf/turf';

function geometryHash(coords: number[][]) {
  let hash = '';

  coords.forEach(([lon, lat]) => {
    hash += `${lon}${lat}`;
  });

  return hash;
}

export enum TreeType {
  In,
  Out,
}
export const getTreeTypeId = (type: TreeType) =>
  ({
    [TreeType.In]: 'INCOMING',
    [TreeType.Out]: 'OUTGOING',
  }[type]);

interface LinkNodeParams {
  trips: number;
  frc: number;
  coords: number[][];
  type: TreeType;
}

export class LinkNode {
  type: TreeType;
  path: number[];
  children: LinkNode[] = [];

  trips: number;
  frc: number;
  coordinates: number[][];
  geometryHash: string;

  length: number;
  startPosition: number;

  tip: Feature<Point>;
  tipBearing: number;
  startBearing: number;

  // root only properties
  nodesMap: Map<string, LinkNode[]>;

  constructor({ trips, frc, coords, type }: LinkNodeParams) {
    this.children = [];
    this.type = type;
    this.trips = trips;
    this.frc = frc;
    this.coordinates = type === TreeType.Out ? coords : [...coords].reverse();
    this.geometryHash = geometryHash(coords);
    this.length = length(lineString(this.coordinates), { units: 'meters' });

    this.tipBearing = bearing(
      coords[coords.length - 2],
      coords[coords.length - 1],
    );
    this.startBearing = bearing(coords[0], coords[1]);
    this.tip = point(coords[coords.length - 1], {
      'icon-rotate': this.tipBearing,
    });
  }

  postConstruct() {
    this.filterHoles();
    this.initializeValues();
  }

  filterHoles() {
    this.forEach((node) => {
      node.children = node.children.filter(Boolean);
    });
  }

  initializeValues() {
    this.nodesMap = new Map();
    this.startPosition = 0;
    this.forEachIndexed((node, path) => {
      node.path = path;
      node.children.forEach(
        (it) => (it.startPosition = node.startPosition + node.length),
      );
      if (this.nodesMap.has(node.geometryHash)) {
        this.nodesMap.get(node.geometryHash).push(node);
      } else {
        this.nodesMap.set(node.geometryHash, [node]);
      }
    });
  }

  forEachIndexed(
    callback: (node: LinkNode, path: number[]) => void,
    path: number[] = [0],
  ) {
    callback(this, path);
    this.children.filter(Boolean).forEach((child, index) => {
      child?.forEachIndexed(callback, [...path, index]);
    });
  }

  forEach(callback: (node: LinkNode) => void) {
    callback(this);
    for (const child of this.children) {
      child.forEach(callback);
    }
  }

  forEachInPath(path: number[], callback: (node: LinkNode) => void) {
    const p = [...path];
    p.shift();

    let current: LinkNode = this;
    while (p.length) {
      current = current.children[p.shift()];
      callback(current);
    }
  }

  getByHash(hash: string) {
    return this.nodesMap.get(hash) ?? [];
  }

  static samePath(a: number[], b: number[]) {
    return a.length === b.length && a.every((v, i) => v === b[i]);
  }

  static isSameBranch(a: number[], b: number[]) {
    const maybeParent = a.length > b.length ? a : b;
    const maybeChild = a.length > b.length ? b : a;

    for (let i = 0; i < maybeChild.length; i++) {
      if (maybeChild[i] !== maybeParent[i]) return false;
    }

    return true;
  }

  static isChild(parent: number[], child: number[]) {
    if (parent.length > child.length) return false;

    for (let i = 0; i < parent.length; i++) {
      if (parent[i] !== child[i]) return false;
    }

    return true;
  }
}
