import cx from 'classnames';
import Popper from 'popper.js';
import * as React from 'react';
import { PLACEMENTS, TYPES } from './constants';
import portal from './portal';
import './Tooltip.css';

const defaultOffset = 5;
const defaultModifiers = {
  offset: { offset: `0, ${defaultOffset}` },
};

export interface TooltipProps {
  content: () => any;
  contentClass?: string;
  refElement: any;
  className?: string;
  type?: TYPES;
  placement: PLACEMENTS;
  /**
   * Popper.js modifiers
   * https://popper.js.org/popper-documentation.html#modifiers
   */
  modifiers?: any;
  offsetPoint?: any;
  hideArrow?: boolean;
  onClickOutside?: () => void;
}

export interface TooltipState {
  tooltipStyles: any;
  tooltipArrowStyles: any;
  tooltipComputedPlacement: any;
}

class TooltipComponent extends React.PureComponent<TooltipProps, TooltipState> {
  static defaultProps = {
    className: '',
    type: TYPES.LIGHT,
    placement: PLACEMENTS.TOP,
    modifiers: defaultModifiers,
  };

  popperInstance;
  tooltipEl;

  constructor(props) {
    super(props);

    this.state = {
      tooltipStyles: {},
      tooltipArrowStyles: {},
      tooltipComputedPlacement: {},
    };

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  onPopperPositionUpdated(data) {
    const tooltipStyles = data.styles || {};
    const tooltipArrowStyles = data.arrowStyles || {};
    const tooltipComputedPlacement = (data.placement || '').split('-')[0];

    this.setState({
      tooltipStyles,
      tooltipArrowStyles,
      tooltipComputedPlacement,
    });
  }

  getPopperConfiguration() {
    const { placement, modifiers } = this.props;

    const updateStateModifier = {
      enabled: true,
      order: 900,
      fn: (data) => {
        this.onPopperPositionUpdated(data);
        return data;
      },
    };

    return {
      placement,
      modifiers: {
        ...modifiers,
        arrow: { element: '.arrow' },
        applyStyle: { enabled: false },
        updateState: updateStateModifier,
      },
    };
  }

  componentDidMount() {
    const { refElement } = this.props;

    this.popperInstance = new Popper(
      refElement,
      this.tooltipEl,
      this.getPopperConfiguration(),
    );
    this.mountOutsideHandler();
  }

  componentWillUnmount() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
    this.unmountOutsideHandler();
  }

  mountOutsideHandler() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  unmountOutsideHandler() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleOutsideClick(e) {
    if (this.tooltipEl && !this.tooltipEl.contains(e.target)) {
      this.props.onClickOutside();
    }
  }

  render() {
    const { content, className, type, hideArrow, contentClass } = this.props;
    const {
      tooltipStyles,
      tooltipArrowStyles,
      tooltipComputedPlacement,
    } = this.state;

    return (
      <div
        ref={(node) => (this.tooltipEl = node)}
        style={tooltipStyles}
        className={cx('Tooltip', className, `Tooltip--${type.toLowerCase()}`, {
          [`Tooltip--${tooltipComputedPlacement}`]: !hideArrow,
        })}
      >
        {!hideArrow && (
          <div className="Tooltip__tip arrow" style={tooltipArrowStyles} />
        )}
        <div className={cx('Tooltip__content', contentClass)}>{content()}</div>
      </div>
    );
  }
}

const Tooltip = portal(TooltipComponent);
export { Tooltip };
export default Tooltip;
