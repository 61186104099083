import { Box, Button, Heading, Text } from 'tombac';
import styled from 'styled-components';

interface Properties {
  analysisName: string;
  onClose: () => void;
  onCancel: (id: number) => void;
  analysisId: number;
}

const Container = styled.div`
  min-width: 500px;
  max-width: 550px;
  background-color: white;
  padding: 16px 24px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.2);
  z-index: 5;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
`;

const Bold = styled.span`
  font-weight: bold;
  color: black;
`;

function AnalysisCancelationModal(props: Properties) {
  return (
    <Container>
      <Heading level={4} style={{ padding: '10px 0px' }}>
        Cancel analysis
      </Heading>
      <Text $color="#7a7e80" style={{ paddingBottom: '10px' }}>
        {' '}
        Are you sure you want to cancel analysis
        <Bold> {props.analysisName}</Bold>?
        <br />
        Any progress will be lost.
      </Text>

      <Box $width="100%" $display="flex">
        <Button onClick={props.onClose} $ml="auto">
          No
        </Button>
        <Button
          onClick={() => props.onCancel(props.analysisId)}
          variant="primary"
          $ml="1sp"
        >
          Yes
        </Button>
      </Box>
    </Container>
  );
}

export default AnalysisCancelationModal;
