import styled from 'styled-components';
import { Heading, tombac } from 'tombac';
import { animated } from 'react-spring';

export const Header = styled(Heading)`
  font-family: ${tombac.altFontFamily};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
`;

export const Bold = animated(styled.span`
  font-size: 12px;
  font-weight: 700;
`);

export const Text = styled.p`
  margin: 0;
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  line-height: 1.5;
  color: #000;
`;

export const Circle = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  object-fit: contain;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  box-sizing: border-box;
`;
