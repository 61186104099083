import Immutable from 'immutable';
import { DateRangeDto, TimeRange } from 'model/TimeDto';

interface ResultItem {
  trips: number;
}
const ResultItem = (trips: number): ResultItem => ({ trips });
ResultItem.empty = { trips: 0 };
ResultItem.merge = (a: ResultItem, b: ResultItem) => ({
  trips: a.trips + b.trips,
});

type Ids = number[] | Immutable.Set<number>;
const idsEmpty = (ids: Ids) =>
  ids === undefined || (ids as any).length === 0 || (ids as any).size === 0;

export interface ReadableResult {
  get(o: number, d: number, v?: number): ResultItem;
  getOf(origins: Ids, destinations: Ids, vias?: Ids): ResultItem;
  meta(): {
    dateRange: DateRangeDto;
    timeRange: TimeRange;
  };
  sumOfTrips(regionsNumber: number, v?: number): number;
}

/**
 * Generates functions useful for extracting the number of trips for given combinations of origin-via-destination feature id's.
 * @param cells Array of [originFeatureId, destinationFeatureId, viaFeatureId, tripsNumber]
 * @param dateRange Selected date range index. Used only for `meta` information.
 * @param timeRange Selected time range index. Used only for `meta` information.
 * @returns
 * - `get` - function which gets the trips count from a single origin-via(optional)-destination feature ID combination.
 * - `getOf` - function which gets the sum of trip counts from all given origin-via-destination feature ID combinations
 * - `meta` - Function returning date and time range indexes.
 * - `sumOfTrips` - Function which returns the total trip count calculated from the first `regionsNumber` of origins and destinations combinations (optionally through provided `via` region) found in the `cells` data.
 */
export function ListResult(
  cells: [number, number, number, number][] | null,
  dateRange: DateRangeDto,
  timeRange: TimeRange,
): ReadableResult {
  const map: Map<number, ResultItem> = new Map();

  // Generates unique number by placing each number in single
  // 15 bits per origin, via and destination number
  const createKey = (o: number, d: number, v: number): number => {
    let result = o;
    result = 32768 * result + v;
    result = 32768 * result + d;
    return result;
  };
  cells?.forEach(([o, d, v, trips]) => {
    map.set(createKey(o, d, v), {
      trips,
    });
  });
  cells = null;

  const sumOfTrips = (regionsNumber: number, v?: number): number => {
    let tripsSum = 0;
    for (let originIndex = 0; originIndex < regionsNumber; originIndex++) {
      for (
        let destinationIndex = 0;
        destinationIndex < regionsNumber;
        destinationIndex++
      ) {
        tripsSum += get(originIndex, destinationIndex, v).trips;
      }
    }
    return tripsSum;
  };

  const get = (o: number, d: number, v?: number): ResultItem => {
    if (v === null || v === undefined || v < 0) {
      v = d;
    }
    return map.get(createKey(o, d, v)) || { trips: 0 };
  };

  const getOf = (origins: Ids, destinations: Ids, vias?: Ids): ResultItem => {
    const viasIsEmpty = !vias || idsEmpty(vias);
    let result = ResultItem.empty;
    origins.forEach((o) => {
      destinations.forEach((d) => {
        if (viasIsEmpty) {
          result = ResultItem.merge(result, get(o as number, d as number));
        } else {
          vias.forEach(
            (v) =>
              (result = ResultItem.merge(
                result,
                get(o as number, d as number, v as number),
              )),
          );
        }
      });
    });
    return result;
  };

  const meta = () => ({ dateRange, timeRange });

  return {
    get,
    getOf,
    meta,
    sumOfTrips,
  };
}
