import { Box, TooltipOnHover, Text } from 'tombac';
import { HelpIcon } from 'tombac-icons';
import { Bold } from './DataSourceSelect';

export function DataTooltip() {
  return (
    <TooltipOnHover
      content={
        <Box $width="350u">
          <Text
            altFont
            fontWeight="bold"
            fontSize={12}
            style={{ textTransform: 'uppercase' }}
          >
            Historical data
          </Text>
          <>
            <Text fontSize={12} $color="#7a7e80" $mt="1sp">
              <Bold>Full data support within last 2 years</Bold> allows you to
              create the report based on full data from the last 2 years
            </Text>
            <Text fontSize={12} $color="#7a7e80" $mt="1sp">
              <Bold>Limited data support, more than 2 years</Bold> allows you to
              create a report with a date range older than 2 years.
              <br /> Please note: Report will be based on a lower volume of
              data.
            </Text>
          </>
        </Box>
      }
      placement="top"
    >
      <HelpIcon color="rgb(123,126,128)" />
    </TooltipOnHover>
  );
}
