import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapStyleSettingsState } from 'legoland-shared';

export type MapStyleMode = 'light' | 'dark' | 'satellite';

interface MapStyleSettingsOptions {
  mapStyleSettingsReady: boolean;
  mapStyleMode: MapStyleMode;
}

const defaultMapStyleSettings: MapStyleSettingsState = {
  style: 'Street light',
  languageGenesis: 'ngt',
  languageOrbis: 'ngt',
  latin: true,
  basicPOI: false,
  buildings3D: false,
  defaultMenuOpen: false,
};

export const mapStyleSettingsSlice = createSlice({
  name: 'MapStyleSettings',
  initialState: defaultMapStyleSettings,
  reducers: {
    set: (state, action: PayloadAction<Partial<MapStyleSettingsState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

const LOCAL_STORAGE_ID = 'tomtom-od-map-style';

export const useMapStyleSettings = (): [
  MapStyleSettingsState,
  (p: Partial<MapStyleSettingsState>) => any,
  MapStyleSettingsOptions,
] => {
  const dispatch = useDispatch();
  const [mapStyleSettingsReady, setMapStyleSettingsReady] = useState(false);
  const setMapStyleSettings = useCallback(
    (partial: Partial<MapStyleSettingsState>) => {
      dispatch(mapStyleSettingsSlice.actions.set(partial));
    },
    [dispatch],
  );

  const mapStyleSettings = useSelector(
    (state: any) => state.mapStyleSettings as MapStyleSettingsState,
  );

  useEffect(() => {
    if (mapStyleSettingsReady) {
      return;
    }
    const localState = window.localStorage.getItem(LOCAL_STORAGE_ID);
    if (!localState) {
      setMapStyleSettingsReady(true);
      return;
    }

    try {
      const localStateJson = JSON.parse(localState);
      for (const [key, value] of Object.entries(localStateJson)) {
        if (key in mapStyleSettings) {
          setMapStyleSettings({ [key]: value });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setMapStyleSettingsReady(true);
    }
  }, []);

  useEffect(() => {
    if (!mapStyleSettingsReady) {
      return;
    }
    window.localStorage.setItem(
      LOCAL_STORAGE_ID,
      JSON.stringify(mapStyleSettings),
    );
  }, [mapStyleSettings]);

  const mapStyleMode = useMemo((): MapStyleMode => {
    switch (mapStyleSettings.style) {
      case 'Satellite':
        return 'satellite';
      case 'Mono dark':
      case 'Street dark':
        return 'dark';
      default:
        return 'light';
    }
  }, [mapStyleSettings.style]);

  return [
    mapStyleSettings,
    setMapStyleSettings,
    {
      mapStyleSettingsReady,
      mapStyleMode,
    },
  ];
};
