import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { appConfig } from './appConfig';

export const browserHistory = createBrowserHistory();

export type TrackedEvent =
  | { action: 'search'; label: string }
  | { action: 'top navigation'; label: string }
  | { action: 'report option'; label: string }
  | { action: 'tutorial start' }
  | { action: 'regions upload'; label: string }
  | { action: 'report clone' }
  | { action: 'results interaction'; label: string }
  | { action: 'report results'; label: number }
  | { action: 'report creation' };

declare const utag: any;

export const trackEvent = ({ action, ...other }: TrackedEvent) => {
  if ('utag' in window) {
    utag.link({
      page_url: location.href,
      event_category: 'od interaction',
      event_action: action,
      event_label: (other as any).label,
    });
  }

  appInsights.trackEvent({ name: action }, other);
};

const reactPlugin = new ReactPlugin();

export const appInsights = new ApplicationInsights({
  config: {
    disableTelemetry: appConfig.stage !== 'prod',
    disableFetchTracking: false,
    maxBatchInterval: 5000,
    instrumentationKey: '7fe0d509-3400-460f-ad42-ec3d10afe8b5',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

export const initTracking = () => {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
  appInsights.addTelemetryInitializer((item) => {
    const ignoreItems = ['authenticate/refresh-token', 'vector-maps-e45'];
    if (
      item.baseData?.target &&
      ignoreItems.findIndex((target) =>
        item.baseData?.target.includes(target),
      ) > -1
    ) {
      return false;
    }
  });
};
