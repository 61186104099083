import { dateRangeFromDto } from 'components/AnalysisNew/DateRanges/DateRange';
import {
  countExcludedDays,
  getActiveDaysLabel,
} from 'components/AnalysisNew/DateRanges/utils';
import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import FormattedDate from 'components/UI/FormattedDate/FormattedDate';

import { dtoToDate } from 'logic/time/dateFormat';
import { DateRangeDto } from 'model/TimeDto';
import { DateTimeContainer, SectionData } from '../AnalysisMapSidebar.style';
import { Container } from './PreviewDate.style';
import { ExclusionsTooltip } from '../../ExclusionsTooltip';

interface Props {
  range: DateRangeDto;
}

export const PreviewDate = ({ range }: Props) => {
  const { analysis } = useAnalysisContext();
  const dateRange = dateRangeFromDto(range);
  const activeDaysLabel = getActiveDaysLabel(
    dateRange,
    analysis.info.timeDefinition.daysOfWeek,
  );

  const numberOfExcludedDays = countExcludedDays(dateRange);
  const formattedDates = (
    <>
      <FormattedDate value={dtoToDate(range.startDate as string)} /> —{' '}
      <FormattedDate value={dtoToDate(range.endDate as string)} />
    </>
  );

  return (
    <Container>
      <DateTimeContainer>{range.name ?? formattedDates}</DateTimeContainer>
      {range.name && <SectionData>{formattedDates}</SectionData>}
      <SectionData>{activeDaysLabel}</SectionData>
      {numberOfExcludedDays > 0 && (
        <SectionData $display="flex" $alignItems="center" $gap="4px">
          Excluded {numberOfExcludedDays} day{numberOfExcludedDays >= 2 && 's'}
          <ExclusionsTooltip dateRange={dateRange} />
        </SectionData>
      )}
    </Container>
  );
};
