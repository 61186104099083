import { Component } from 'react';
import PropTypes from 'prop-types';
import './IconTooltip.css';

class IconTooltip extends Component {
  static propTypes = {
    icon: PropTypes.any,
    tooltip: PropTypes.any,
    position: PropTypes.string,
  };

  render() {
    const { icon, tooltip, position } = this.props;
    return (
      <span className="IconTooltip">
        {icon}
        <div
          className={`IconTooltip__tooltip ${
            position ? `IconTooltip__tooltip--${position}` : ''
          }`}
        >
          {tooltip}
        </div>
      </span>
    );
  }
}

export default IconTooltip;
