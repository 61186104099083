import { bearing, lineString } from '@turf/turf';
import useMap from 'hooks/useMap';
import { Box, Button, HFlex } from 'tombac';
import { CancelIcon, ForwardIcon } from 'tombac-icons';
import { TreeType } from '../LinkNode';
import { SegmentName } from './SegmentName';
import { SelectedSegment } from '../SelectedLinkTree';
import {
  BoldText,
  BoldTextOverflow,
  DefaultText,
  GreyText,
} from 'components/UI/Text.style';
import { RoadClass } from './RoadClass';
import { useMemo } from 'react';
import { lineStringToReverseGeocodePoint } from 'components/Map/mapUtils';

function Bearing({ coords }: { coords: [number, number][] }) {
  return (
    <Box $display="flex" $alignItems="center" $ml="auto">
      <SegmentShape coords={coords} />
      <GreyText>Direction</GreyText>
    </Box>
  );
}

function SegmentShape({ coords }: { coords: number[][] }) {
  const deg2rad = (deg: number) => (deg * Math.PI) / 180;
  const endBearing = deg2rad(
    bearing(coords[coords.length - 2], coords[coords.length - 1]),
  );
  const map = useMap();
  const points = coords
    .map((it) => map.project(it as [number, number]))
    .map((it) => [it.x, it.y]);

  // Calculate scale for the segment
  const minX = Math.min(...points.map((c) => c[0]));
  const maxX = Math.max(...points.map((c) => c[0]));
  const minY = Math.min(...points.map((c) => c[1]));
  const maxY = Math.max(...points.map((c) => c[1]));
  const scale = Math.max(maxX - minX, maxY - minY);

  // Dimensions
  const width = (maxX - minX) / scale;
  const height = (maxY - minY) / scale;
  const offsetX = (1 - width) / 2;
  const offsetY = (1 - height) / 2;

  // Scaled
  const scaledPoints = points
    // Scale to fit [0, 1]
    .map((c) => [(c[0] - minX) / scale, (c[1] - minY) / scale])
    // Flip Y axis
    .map((it) => [it[0], it[1]])
    // Offset to center
    .map((it) => [it[0] + offsetX, it[1] + offsetY]);

  const [start, ...rest] = scaledPoints;

  const end = rest[rest.length - 1];

  // Calculate lines to form an arrow at the end
  const arrowRadius = 0.2;
  const arrowDelta = Math.PI + Math.PI / 4;
  const endTip1 = [
    end[0] + Math.sin(endBearing + arrowDelta) * arrowRadius,
    end[1] - Math.cos(endBearing + arrowDelta) * arrowRadius,
  ];
  const endTip2 = [
    end[0] + Math.sin(endBearing - arrowDelta) * arrowRadius,
    end[1] - Math.cos(endBearing - arrowDelta) * arrowRadius,
  ];

  const moveTo = (coord) => `M${coord[0]},${coord[1]}`;
  const lineTo = (coord) => `L${coord[0]},${coord[1]}`;

  return (
    <svg
      viewBox="-0.2 -0.2 1.4 1.4"
      width="32px"
      height="32px"
      style={{ flexShrink: 0 }}
    >
      <path
        d={[
          // Segment
          moveTo(start),
          ...rest.map((it) => lineTo(it)),
          // Arrow 1
          moveTo(end),
          lineTo(endTip1),
          // Arrow 2
          moveTo(end),
          lineTo(endTip2),
        ].join(' ')}
        stroke="#666"
        fill="none"
        strokeWidth={0.12}
        strokeLinecap="round"
      />
    </svg>
  );
}

interface Props {
  treeType: TreeType;
  maxTrips: number;
  segment: any;
  setSelectedSegments: (segments: SelectedSegment[]) => void;
  selectedSegments: SelectedSegment[];
  isSelectedLinkRegion: boolean;
}

export const SelectedLinkInfoHeader = ({
  treeType,
  segment,
  maxTrips,
  setSelectedSegments,
  selectedSegments,
  isSelectedLinkRegion,
}: Props) => {
  const removeSegment = (index: number) => {
    setSelectedSegments(selectedSegments.filter((_, i) => index !== i));
  };

  const linkName = isSelectedLinkRegion ? 'Region' : 'Link';

  const segmentCordsCenter = useMemo(() => {
    return lineStringToReverseGeocodePoint(lineString(segment.coords));
  }, [segment]);

  return (
    <Box
      $display="flex"
      $alignItems="center"
      $position="relative"
      $borderBottom="solid 1px #e5e5e5"
      $p="1sp 2sp"
    >
      <Box $width="100%">
        <HFlex $alignItems="center">
          {treeType === TreeType.In ? (
            <>
              <BoldTextOverflow $maxWidth="225px">
                <SegmentName coords={segmentCordsCenter} />
              </BoldTextOverflow>
              <ForwardIcon />
              <BoldText $color="rgba(0, 170, 255, 1)">{linkName}</BoldText>
            </>
          ) : (
            <>
              <BoldText $color="rgba(0, 170, 255, 1)">{linkName}</BoldText>
              <ForwardIcon />
              <BoldTextOverflow $maxWidth="225px">
                <SegmentName coords={segmentCordsCenter} />
              </BoldTextOverflow>
            </>
          )}
          <Box $ml="0.5sp">
            <RoadClass frc={segment.frc} />
          </Box>
        </HFlex>

        <HFlex $justifyContent="space-between">
          <HFlex $alignItems="center" $gap="2px">
            <DefaultText>Total trips:</DefaultText>
            <BoldText>{segment.totalTrips.toLocaleString()}</BoldText>
            <GreyText $ml="1sp">
              {((segment.totalTrips / maxTrips) * 100).toFixed(2)}%
            </GreyText>
          </HFlex>
          <Bearing coords={segment.coords} />
        </HFlex>
      </Box>

      <Box $position="absolute" $top="0" $right="0">
        <Button
          onClick={() => removeSegment(segment.index)}
          shape="circle"
          size="s"
          variant="flat"
        >
          <CancelIcon />
        </Button>
      </Box>
    </Box>
  );
};
