import { ReactNode, useRef } from 'react';
import * as React from 'react';
import { Box, PropsWithPropStyling, tombac } from 'tombac';
import styled from 'styled-components';

const RadioCircle = styled.div<{ checked: boolean }>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: relative;
  margin-right: 8px;
  margin-top: -1px;
  border: 1px solid
    ${(p) =>
      p.checked ? tombac.color('accent', 500) : tombac.color('neutral', 400)};

  ::before {
    display: ${(p) => (p.checked ? 'block' : 'none')};
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${tombac.color('accent', 500)};
    position: absolute;
    top: 3px;
    left: 3px;
  }
`;

const RadioItem = styled.div`
  display: flex;
  align-items: center;
  font-family: ${tombac.fontFamily};
  font-size: 12px;

  :not(:last-child) {
    margin-bottom: 12px;
  }
`;

export function RadioInput<T extends string | number>({
  options,
  value,
  onChange,
  ...rest
}: PropsWithPropStyling<{
  value: T;
  onChange: (value: T) => any;
  options: { value: T; label: ReactNode }[];
}>) {
  const groupRef = useRef<HTMLDivElement>();
  const onKeyDown = (e: React.KeyboardEvent<any>) => {
    let direction = 0;
    if (e.key === 'ArrowUp') direction = -1;
    if (e.key === 'ArrowDown') direction = 1;
    if (!direction) return;

    const newIndex =
      (options.findIndex((it) => it.value === value) +
        direction +
        options.length) %
      options.length;

    onChange(options[newIndex].value);
    (groupRef.current.childNodes[newIndex] as HTMLDivElement).focus();

    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Box role="radiogroup" onKeyDown={onKeyDown} ref={groupRef} {...rest}>
      {options.map((it) => {
        const checked = value === it.value;
        return (
          <RadioItem
            key={it.value}
            role="radio"
            tabIndex={checked ? 0 : -1}
            aria-checked={checked ? 'true' : 'false'}
            onClick={() => onChange(it.value)}
          >
            <RadioCircle checked={checked} />
            {it.label}
          </RadioItem>
        );
      })}
    </Box>
  );
}
