export const clamp = (min: number, max: number, v: number) => {
  if (v > max) {
    return max;
  }
  if (v < min) {
    return min;
  }
  return v;
};

export const log = (value: number): number => {
  if (value === 100) {
    return 100;
  }
  if (value === 0) {
    return 0;
  }
  const percent = (100 * Math.log(value)) / Math.log(100);

  return clamp(0, 100, percent);
};

export const safeScale = (value: number, min: number, max: number): number => {
  if (min === max) {
    return min;
  }
  return (value - min) * (100 / (max - min));
};

export const minMax = (array?: number[]) => {
  if (array === undefined || array.length === 0) {
    return { min: 0, max: 0 };
  }
  let min = array[0];
  let max = array[0];
  let i;
  const length = array.length;
  for (i = 0; i < length; i++) {
    const element = array[i];
    if (element < min) {
      min = element;
    }
    if (element > max) {
      max = element;
    }
  }

  return { min, max };
};

export const normalize = (array: number[]) => {
  const { min, max } = minMax(array);

  return array.map((n) => safeScale(n, min, max)).map(log);
};

export const sum = (array: number[]) => array.reduce((a, b) => a + b, 0);
