import {
  eachDayOfInterval,
  getDaysInMonth,
  isAfter,
  isBefore,
  isMatch,
  isSameDay,
} from 'date-fns';
import { dateToDto } from 'logic/time/dateFormat';
import { DayOfWeek } from 'model/DayOfWeek';
import { DateRange } from '../DateRanges/DateRange';
import { isDateInActiveDays } from '../dateRangeUtils';

type MonthYear = {
  month: number;
  year: number;
};

export const getDatesForMonthAndYear = (
  { month, year }: MonthYear,
  limits: DateRange,
  currRange: DateRange,
) => {
  const numberOfDays = getDaysInMonth(new Date(year, month - 1));
  const start = isAfter(new Date(year, month - 1), currRange.start)
    ? new Date(year, month - 1)
    : currRange.start;
  const end = isBefore(new Date(year, month - 1, numberOfDays), currRange.end)
    ? new Date(year, month - 1, numberOfDays)
    : currRange.end;

  return isSameDay(start, end)
    ? [start]
    : eachDayOfInterval({
        start: isAfter(start, limits.start) ? start : limits.start,
        end: isBefore(end, limits.end) ? end : limits.end,
      });
};

export const eachWeekdayFromDates = (weekday: number, dates: Date[]) =>
  dates.filter((date) => date.getDay() === (weekday + 1) % 7);

export const applyExclusions = (
  accExclusions: string[],
  exclusions: string[],
): string[] => {
  const shouldAddExclusions = exclusions.some(
    (ex) => !accExclusions.includes(ex),
  );

  if (shouldAddExclusions) {
    return Array.from(new Set([...accExclusions, ...exclusions]));
  }

  return accExclusions.filter((ex) => !exclusions.includes(ex));
};

export const getExcludedDaysForMonths = (
  months: MonthYear[],
  range: DateRange,
) =>
  months
    .flatMap(({ month, year }) => {
      const start = isAfter(new Date(year, month - 1), range.start)
        ? new Date(year, month - 1)
        : range.start;

      const end = isBefore(new Date(year, month, 0), range.end)
        ? new Date(year, month, 0)
        : range.end;

      return eachDayOfInterval({ start, end });
    })
    .map(dateToDto);

export const eachDayFromExcludedDays = (
  range: DateRange,
  dates: DayOfWeek[],
) => {
  const eachDay = eachDayOfInterval(range);
  return eachDay.filter((date) => !isDateInActiveDays(date, dates));
};

export const toUTCDate = (date: Date): Date => {
  return date
    ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    : date;
};

//parse string in format YYYY-MM-DD to string in format YYYY/MM/DD
//fix timezone one day shift
export const toCorrectFormat = (date: string | Date): string | Date => {
  return typeof date === 'string' && isMatch(date, 'yyyy-mm-dd')
    ? date.replace(/-/g, '/')
    : date;
};

export const isRangeExists = (range: DateRange) => !!range.start && !!range.end;
