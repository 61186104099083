import { Text, TooltipOnHover, useTombac } from 'tombac';
import { AcceptIcon, ErrorIcon } from 'tombac-icons';

export function ValidationIcon({
  children,
  isValid,
}: {
  children?: any;
  isValid: boolean;
}) {
  const tombac = useTombac();
  return isValid ? (
    <AcceptIcon color={tombac.color('success')} />
  ) : (
    <TooltipOnHover
      content={
        <Text as="div" fontSize={14}>
          {children}
        </Text>
      }
      placement="top"
      variant="inverted"
    >
      <ErrorIcon color={tombac.color('danger')} />
    </TooltipOnHover>
  );
}
