import cx from 'classnames';
import * as React from 'react';
import { Spacing, spacingToPixels } from './spacing';
import './Stack.css';

export type Alignment = 'start' | 'center' | 'end' | 'stretch';

interface Props {
  direction?: 'row' | 'column';
  alignment?: Alignment;
  padding?: Spacing;
  spacing?: Spacing;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const StackContext = React.createContext({ direction: 'column' });
export const useStackContext = () => React.useContext(StackContext);
export function Stack({
  direction = 'column',
  alignment = 'start',
  spacing = 'l',
  children,
  padding = 'none',
  style = {},
  className,
}: Props) {
  const alignItems = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
  }[alignment];

  return (
    <StackContext.Provider value={{ direction }}>
      <div
        className={cx(
          'lluiStack',
          {
            ['lluiStack--row-' + spacing]: direction === 'row',
            ['lluiStack--column-' + spacing]: direction === 'column',
          },
          className,
        )}
        style={{
          flexDirection: direction,
          alignItems,
          height: direction === 'column' ? '100%' : undefined,
          width: direction === 'row' ? '100%' : undefined,
          padding: spacingToPixels(padding) + 'px',
          ...style,
        }}
      >
        {children}
      </div>
    </StackContext.Provider>
  );
}

export const HStack = (props: Props) => (
  <Stack direction="row" alignment="center" {...props} />
);
export const VStack = (props: Props) => <Stack direction="column" {...props} />;
