import { InlineText } from 'tombac';
import { NumberInput } from './NumberInput';
import { Range } from './Range';
import './SliderInput.css';

interface Props {
  value: number;
  onChange: (it: number) => any;
  onAfterChange?: (it: number) => any;
  min?: number;
  max: number;
  step?: number;
  width?: string;
  unit?: string;
}
export function SliderInput({
  value,
  onChange,
  onAfterChange,
  min = 0,
  max,
  step = 1,
  width,
  unit,
}: Props) {
  return (
    <div className="SliderInput" style={{ width }}>
      <div className="SliderInput__content">
        <Range
          min={min}
          max={max}
          onChange={onChange}
          step={step}
          value={value}
          onAfterChange={(e) => onAfterChange(e)}
        />
        <NumberInput
          min={min}
          max={max}
          value={value}
          $width="80px"
          onChange={(e) => {
            onChange(e);
            onAfterChange(e);
          }}
          isInvalid={() => false}
        />{' '}
        <InlineText $ml="1sp">{unit}</InlineText>
      </div>
    </div>
  );
}
