import debounce from 'lodash/debounce';
import { RegionDto } from '../../model/RegionDto';

const STORE_KEY = 'regions';

const save = debounce((regions: RegionDto[]) => {
  const regionsString = JSON.stringify(regions);
  localStorage.setItem(STORE_KEY, regionsString);
}, 2000);

/**
 * Remove all regions from storage
 */
const clear = () => {
  localStorage.removeItem(STORE_KEY);
};

/**
 * Returns all regions in storage
 */
const get = (): RegionDto[] | undefined => {
  const regionsString = localStorage.getItem(STORE_KEY);
  if (regionsString) {
    return JSON.parse(regionsString);
  }
  return;
};

const RegionStorage = {
  save,
  get,
  clear,
};

export { RegionStorage };
