import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AsideSection } from 'components/UI/UI';
import { Box } from 'tombac';
import { useIsAdmin } from 'user';

export const Organizations = () => {
  const { analysis } = useAnalysisContext();
  const isAdmin = useIsAdmin();
  return (
    <>
      {isAdmin && !!analysis.info.organizations && (
        <AsideSection title="Organizations" defaultHide disableBorder>
          <Box $mt="16px">{analysis.info.organizations.join(', ')}</Box>
        </AsideSection>
      )}
    </>
  );
};
