import { useIsTrial } from 'user';
import LimitsApi from 'api/LimitsApi';
import { TrialBox, TrialBoxContactButton } from 'legoland-sdk';
import { useLegoland } from 'legoland-sdk/dist/experimental';

function ReportsTrialBox() {
  const { legolandUiUrl } = useLegoland();
  const trial = useIsTrial();
  const limits = LimitsApi.use();

  if (!trial) {
    return null;
  }

  return (
    <TrialBox
      $marginTop="3sp"
      button={<TrialBoxContactButton moveUrl={legolandUiUrl} />}
      primaryText={`You are currently using a trial version with a limited number ${
        limits && limits.limits.agreementJobs
          ? ` (${limits.limits.agreementJobs})`
          : ''
      } of reports available.`}
    />
  );
}
export default ReportsTrialBox;
