import * as React from 'react';
import ChevronLeft from 'react-feather/dist/icons/chevron-left';
import './NavBar.css';

interface Props {
  onClick: React.MouseEventHandler;
}

class NavBarBack extends React.Component<Props> {
  render() {
    return (
      <div className="ViewNavBarBack" onClick={this.props.onClick}>
        <ChevronLeft size={24} />
        {this.props.children}
      </div>
    );
  }
}

export default NavBarBack;
