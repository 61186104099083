import { DateRangeDto, TimeRange } from 'model/TimeDto';
import { ReadableResult } from '../ReadableResult';
import { CreateCache } from './Cache';

interface Key {
  analysisId: number;
  dateRange: DateRangeDto;
  timeRange: TimeRange;
}

const hasher = ({ analysisId, dateRange, timeRange }: Key) =>
  '' + analysisId + JSON.stringify(dateRange) + JSON.stringify(timeRange);

const create = () => CreateCache<Key, ReadableResult>(hasher, 2);

const ResultCache = {
  create,
};

export { ResultCache };
export default ResultCache;
