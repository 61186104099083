import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { Input } from 'tombac';
import clamp from 'lodash/clamp';

const parseNumber = (s: string): number | undefined => {
  const number = Number(s);
  if (s === '' || isNaN(number)) return;
  return number;
};

export function NumberInput({
  value,
  min,
  max,
  isInvalid,
  onChange,
  ...inputProps
}: React.ComponentProps<typeof Input> & {
  min: number;
  max: number;
  value?: number;
  isInvalid: (n: number) => boolean;
  onChange: (n: number) => any;
}) {
  const inputRef = useRef<HTMLInputElement>();
  const [invalid, setInvalid] = useState(false);

  const handleChange = (e: any) => {
    const number = parseNumber(e.target.value);
    if (number !== undefined) {
      onChange(clamp(number, min, max));
    }
    setInvalid(number === undefined || isInvalid(number));
  };

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.value = String(value);
    setInvalid(false);
  }, [value]);

  const onBlur = (e) => {
    if (!invalid) e.target.value = String(value);
  };

  return (
    <Input
      {...inputProps}
      ref={inputRef}
      onChange={handleChange}
      onBlur={onBlur}
      invalid={invalid}
    />
  );
}
