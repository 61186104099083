export type Spacing = 'none' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export const spacingToPixels = (s: Spacing): number =>
  ({
    none: 0,
    xxs: 1,
    xs: 2,
    s: 3,
    m: 5,
    l: 8,
    xl: 12,
    xxl: 24,
  }[s]);
