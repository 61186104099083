import AnalysisApi from 'api/AnalysisApi';
import { useSubmit } from 'hooks/useSubmit';
import { useValidation } from 'hooks/useValidation';
import { useState } from 'react';
import { Button, FormGroup, Input, useToasts } from 'tombac';
import { useAnalysisContext } from '../AnalysisViewPage';

export const ANALYSIS_NAME_MAX_LENGTH = 100;

interface Props {
  name: string;
  analysisId: number;
  onClose: () => void;
}

const nameRequirements = [
  { label: 'Name must not be empty', check: (s: string) => s.length === 0 },
  { label: `Name cannot be longer than ${ANALYSIS_NAME_MAX_LENGTH} characters`, check: (s: string) => s.length > ANALYSIS_NAME_MAX_LENGTH },
];

function AnalysisRename(props: Props) {
  const { addToast } = useToasts();
  const analysisControl = useAnalysisContext();
  const [nameInput, setNameInput] = useState(props.name);

  const validation = useValidation(nameRequirements, nameInput, setNameInput);

  const [submit, submitting] = useSubmit(async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!validation.canSubmit()) {
      return;
    }
    try {
      const newName = await AnalysisApi.rename(props.analysisId, nameInput);
      if (newName === nameInput) {
        addToast('Renamed successfully', 'success');
      } else {
        throw new Error();
      }
      props.onClose();
    } catch (e) {
      addToast('Failed to rename', 'danger', { autoDismissDelay: 3000 });
    }
    analysisControl.refresh();
  });

  return (
    <form onSubmit={submit} style={{ padding: '30px' }}>
      <h2 style={{ marginTop: 0 }}>Rename analysis</h2>
      <div style={{ margin: '2rem 0' }}>
        <FormGroup
          label="Analysis name"
          danger={validation.messagesPlain?.join(', ')}
        >
          <Input
            autoFocus
            $width="350px"
            value={nameInput}
            onChange={(e) => setNameInput(e.target.value)}
            invalid={validation.messagesPlain !== undefined}
          />
        </FormGroup>
      </div>
      <Button busy={submitting} onClick={submit} variant="primary" $mr="1sp">
        Rename
      </Button>
      <Button variant="secondary" onClick={props.onClose}>
        Cancel
      </Button>
    </form>
  );
}

export default AnalysisRename;
