import { ReverseGeocodeApi } from 'api/ReverseGeocodeApi';
import { useEffect, useState } from 'react';
import { Caption, TooltipOnHover } from 'tombac';

export function SegmentName({ coords }: { coords: [number, number] }): any {
  const [name, setName] = useState();

  useEffect(() => {
    const request = async () => {
      const name = await ReverseGeocodeApi.getRoadName(coords);

      setName(name ?? 'Segment');
    };

    request();
  }, [coords[0], coords[1]]);

  return (
    <TooltipOnHover
      placement="top"
      size="s"
      content={<Caption>{name}</Caption>}
    >
      {name}
    </TooltipOnHover>
  );
}
