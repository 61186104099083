import { Feature } from '@turf/helpers';
import area from '@turf/area';
import { length } from '@turf/turf';

const LINE_STRING_REGION_WIDTH_IN_METERS = 5;

export const calculateArea = (region: Feature) => {
  const meters2toKm2 = (value: number) => value / 1000000;
  if (region.geometry.type === 'LineString') {
    return meters2toKm2(
      length(region, { units: 'meters' }) * LINE_STRING_REGION_WIDTH_IN_METERS,
    );
  }
  return meters2toKm2(area(region));
};
