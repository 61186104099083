import styled from 'styled-components';
import { Box, tombac } from 'tombac';
import { InfoIcon } from 'tombac-icons';

const WarningBox = styled(Box)`
  display: flex;
  align-items: center;
  background: ${tombac.color('alert', 200)};
  border-bottom: 1px solid ${tombac.color('alert', 400)};
  border-top: 1px solid ${tombac.color('alert', 400)};
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  padding: 14px 18px;
`;

export function WarningMessage({ children }: { children: any }) {
  return (
    <WarningBox>
      <InfoIcon color="#f9b023" />
      <Box $ml="1sp">{children}</Box>
    </WarningBox>
  );
}
