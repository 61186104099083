import { NewReportModal } from 'components/AnalysisNew/NewReportModal';
import {
  LegolandNavbar,
  useMeDetailsQuery,
  UserRole,
} from 'legoland-sdk/dist/experimental';
import { appInsights, trackEvent } from 'logic/tracking';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useMenu } from 'reducers/menuReducer';
import { Button, NavbarMenu, NavbarMenuItem } from 'tombac';
import { AddIcon } from 'tombac-icons';
import { NavBarLink } from '../../UI/UI';
import './NavBar.css';

function NavBar() {
  const me = useMeDetailsQuery().data?.meDetails;
  const [, , { resetMenu }] = useMenu();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!me.email) {
      return;
    }
    appInsights.setAuthenticatedUserContext(me.email);
  }, [me.email]);

  return (
    <>
      <LegolandNavbar
        $zIndex="11"
        manualUrl={'http://support.move.tomtom.com/products/o-d-analysis/'}
        trackingCategory="od interaction"
        breakpoint={1050}
        meMenuBreakpoint={1200}
      >
        <NavbarMenu breakpoint={1050}>
          <NavbarMenuItem>
            <NavBarLink
              as={NavLink}
              to="/dashboard"
              data-test-id="NavBar-MyReports"
              onClick={() =>
                trackEvent({ action: 'top navigation', label: 'my reports' })
              }
            >
              My reports
            </NavBarLink>
          </NavbarMenuItem>

          {(me?.role === UserRole.Support || me?.role === UserRole.Admin) && (
            <NavbarMenuItem>
              <NavBarLink as={NavLink} to="/support">
                Users' reports
              </NavBarLink>
            </NavbarMenuItem>
          )}
          <NavbarMenuItem $ml="3sp">
            <Button
              variant="primary"
              prepend={<AddIcon />}
              onClick={() => {
                resetMenu();
                setModalOpen(true);
              }}
              disabled={window.location.pathname.includes('/new')}
            >
              New report
            </Button>
          </NavbarMenuItem>
        </NavbarMenu>
      </LegolandNavbar>
      {modalOpen && (
        <NewReportModal
          onClose={() => setModalOpen(false)}
          action="new"
          isOpen={true}
        />
      )}
    </>
  );
}

export default NavBar;
