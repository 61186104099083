import AnalysisApi from 'api/AnalysisApi';
import { ListResult, ReadableResult } from 'logic/ReadableResult';
import { Analysis } from 'model/AnalysisDto';
import { useEffect, useState } from 'react';
import { Scenario } from './scenario';

const usePartialResult = (
  analysis: Analysis,
  {
    origins,
    destinations,
    vias,
    dateRange,
    timeRange,
    dateRangeCompare,
    timeRangeCompare,
  }: Scenario,
) => {
  const { dateRanges, timeRanges } = analysis.info.timeDefinition;
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingCompare, setLoadingCompare] = useState<boolean>(true);
  const [result, setResult] = useState<ReadableResult>(() =>
    ListResult([], dateRanges[dateRange], timeRanges[timeRange]),
  );
  const [compareResult, setCompareResult] = useState<
    ReadableResult | undefined
  >(undefined);
  const external = analysis.regions.length;

  useEffect(() => {
    let isCurrent = true;
    const request = async () => {
      setLoading(true);
      if (origins.isEmpty() || destinations.isEmpty()) {
        setLoading(false);
        return;
      }
      const result = await AnalysisApi.getResult(
        analysis,
        analysis.info.timeDefinition.dateRanges[dateRange],
        analysis.info.timeDefinition.timeRanges[timeRange],
        {
          origins: origins.toArray(),
          destinations: destinations.toArray(),
          vias: vias.toArray(),
        },
      );
      if (isCurrent) {
        setResult(result);
        setLoading(false);
      }
    };

    request();

    return () => {
      isCurrent = false;
      setLoading(false);
    };
  }, [
    analysis.info.id,
    origins.hashCode(),
    destinations.hashCode(),
    vias.hashCode(),
    dateRange,
    timeRange,
  ]);

  useEffect(() => {
    setLoadingCompare(true);
    if (dateRangeCompare === undefined) {
      setCompareResult(undefined);
      setLoadingCompare(false);
      return;
    }
    if (origins.isEmpty() || destinations.isEmpty()) {
      setLoadingCompare(false);
      return;
    }
    let isCurrent = true;
    const request = async () => {
      const result = await AnalysisApi.getResult(
        analysis,
        analysis.info.timeDefinition.dateRanges[dateRangeCompare],
        analysis.info.timeDefinition.timeRanges[timeRangeCompare],
        {
          origins: origins.toArray(),
          destinations: destinations.toArray(),
          vias: vias.isEmpty() ? [external] : vias.toArray(),
        },
      );
      if (isCurrent) {
        setCompareResult(result);
        setLoadingCompare(false);
      }
    };

    request();

    return () => {
      isCurrent = false;
      setLoading(false);
    };
  }, [
    analysis,
    origins,
    destinations,
    vias,
    dateRangeCompare,
    timeRangeCompare,
  ]);

  const somethingIsLoading = loading || loadingCompare;

  return { result, compareResult, loading: somethingIsLoading };
};

export const PartialResult = {
  use: usePartialResult,
};
