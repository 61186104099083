import './FullscreenError.css';
import ErrorImage from './ErrorImage';
import { useLegoland } from 'legoland-sdk/dist/experimental';
import { Button } from 'tombac';

interface Props {
  title: string;
  description: string;
}

const FullscreenError = ({ title, description }: Props) => {
  const { legolandUiUrl } = useLegoland();
  return (
    <>
      <div className="FullscreenError__wrapper">
        <div className="FullscreenError">
          <ErrorImage />
          <h1>{title}</h1>
          <p>{description}</p>
          <div className="FullscreenError__actions">
            <Button as="a" href="/dashboard" variant="primary">
              Back to dashboard
            </Button>
            <Button as="a" href={legolandUiUrl}>
              Go to Move portal
            </Button>
          </div>
          <div className="FullscreenError__contacts">
            Contact support at{' '}
            <a href="mailto:movesupport@tomtom.com">movesupport@tomtom.com</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FullscreenError;
