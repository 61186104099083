import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AsideSection } from 'components/UI/UI';
import { Button, tombac } from 'tombac';
import { AreaIcon, EditIcon } from 'tombac-icons';
import RegionDownload from '../../RegionDownload';
import { Value } from '../AnalysisMapSidebar.style';
import styled from 'styled-components';

const RegionsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tombac.space(1)};
  margin-top: ${tombac.space(2)};
`;

const RegionsAsideTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${tombac.space(1)};
`;

interface Props {
  openRenamingRegionsModal: VoidFunction;
  isShared: boolean;
}

export const Regions = ({ openRenamingRegionsModal, isShared }: Props) => {
  const { analysis } = useAnalysisContext();
  return (
    <AsideSection
      defaultHide
      title={
        <RegionsAsideTitle>
          <AreaIcon /> Regions ({analysis.regions.length})
        </RegionsAsideTitle>
      }
    >
      <RegionsInfoContainer>
        {analysis.info.map?.version && (
          <Value>Map version: {analysis.info.map?.version}</Value>
        )}
        {!isShared && (
          <Button
            prepend={<EditIcon />}
            $width="100%"
            onClick={openRenamingRegionsModal}
          >
            Rename
          </Button>
        )}
        <RegionDownload />
      </RegionsInfoContainer>
    </AsideSection>
  );
};
