const RegionType = Object.freeze({
  CIRCLE: 'CIRCLE',
  RECTANGLE: 'RECTANGLE',
  POLYGON: 'POLYGON',
  GRID: 'GRID',
  MULTIPOLYGON: 'MULTIPOLYGON',
  SEGMENT_LIST: 'SEGMENT_LIST',
});

export default RegionType;
