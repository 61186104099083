import { Component } from 'react';
import PropTypes from 'prop-types';
import './LinkButton.css';

class LinkButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any,
  };
  render() {
    const { onClick, children } = this.props;
    return (
      <button className="LinkButton" onClick={onClick}>
        {children}
      </button>
    );
  }
}

export default LinkButton;
