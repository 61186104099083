import { UserRole } from 'legoland-sdk/dist/experimental';
import { Redirect } from 'react-router-dom';
import { useUser } from 'user';

interface Props {
  children: any;
}

export function SupportOnly(props: Props) {
  const user = useUser();
  if (user?.role !== UserRole.Support && user?.role !== UserRole.Admin) {
    return <Redirect to="/" />;
  }

  return props.children;
}
